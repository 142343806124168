import Fetch from "../common/Fetch";

const localVar = "AUTH_TOKEN";
class auth {
  constructor() {
    this.email = "";
  }

  /**
   *
   * @param {Object} info
   * @returns
   *
   * This function gets called when user signs up.
   */
  registerUser = (info) => {
    return Fetch(
      "auth/register",
      { ...info },
      { isToken: false, inFormData: false }
    ).then((res) => {
      return res;
    });
  };

  loginUser = (info) => {
    return Fetch("auth/login", info).then((res) => {
      return res;
    });
  };

  checkUser = (callback) => {
    const isLogin = localStorage.getItem(localVar);
    // if(callback){
    //   callback()
    // }
    if (isLogin) return true;
    return false;
  };

  logoutUser = () => {
    Fetch("auth/logout", {}, { sendToken: true }).then((res) => {
      localStorage.removeItem("AUTH_TOKEN");

      if (res && res?.status === 200 && res?.success) {
        window.handleToastMsg(true, res?.message);
        setTimeout(() => {
          window.handleToastMsg(false, "");
          window.location.reload();
        }, 2000);
        return;
      }
      
      localStorage.removeItem("temp");
      window.location.reload();
    });
  };

  refreshToken = () => {
    let loginToken = localStorage.getItem("AUTH_TOKEN");
    if (loginToken) {
      return Fetch("auth/refresh", null, {
        method: "get",
        sendToken: true,
        token: loginToken,
      }).then((res) => {
        if (res && res.success) {
          localStorage.setItem("AUTH_TOKEN", res.data.token);
          return res.data.token;
        }
        return {
          success: false,
          message: "refresh token can't be generated",
          token: null,
        };
      });
    }
  };

  handleLocalStorage = (key, setValue, action = "set") => {
    let localItem = JSON.parse(localStorage.getItem(key));
    if (action === "get") {
      return localItem;
    } else {
      let finalValue = JSON.stringify(setValue);
      localStorage.setItem(key, finalValue);
    }
  };
}

const Auth = new auth();

export default Auth;
