import React, { Component, createContext } from "react";
import Auth from "../common/Auth";
import Fetch from "../common/Fetch";

const MainContext = createContext();

export default class Context extends Component {
  state = {
    isLoading: false,
    AUTH_TOKEN: "",
    isUserLogin: false,
    colorMode: 1,
    cart: [],
    totalCartItems: 0,

    prevPageInfo: { route: "", pageName: "" },
    currentPage: "",

    pageInfo: {},
    footerData: {},

    pagesData: {},
    rightCartProps: { show: false, count: 0 },

    couponCodeInfo: {
      discountCodeID: null,
      validCouponCode: "",
      discountAmount: 0,
      couponValueType: "",
    }

    // homePageMiscellaneous
  };

  componentDidMount() {
    let url = window.location.href;
    let locationObj = { ...window.location };
    
    document.body.addEventListener(
      "click",
      () => {
        requestAnimationFrame(() => {
          if (url !== window.location.href) {
            this.handleRouteChangeEvent(locationObj, window.location);
            locationObj = { ...window.location };
            url = window.location.href;
          }
        });
      },
      true
    );

    this.getAllSettings();
    this.getAllPagesData();
    const temp = Auth.handleLocalStorage("temp", null, "get");
    if (!temp) {
      Auth.handleLocalStorage("temp", [], "set");
    }
    const isLogin = Auth.checkUser();
    this.setState({ isUserLogin: isLogin });

    this.getAllInCart(isLogin, false, { toggleCart: false });
  }

  getAllSettings = () => {
    Fetch("page/allsettings", null, { method: "get" }).then((res) => {
      this.setState({ footerData: res.data });
    });
  };

  handleRouteChangeEvent = (prevUrl, nextUrl) => {
    const { pageInfo } = this.state;
    let copyInfo = { ...pageInfo };
    const { pathname, search } = prevUrl;
    const prevPathArr = pathname?.includes("/") && pathname?.split("/");
    const length = prevPathArr?.length;
    const pageName = prevPathArr[length - 1] ? prevPathArr[length - 1] : "home";
    const route = search ? pathname + search : pathname;

    const newUrl = window.location;
    const newPathArr =
      newUrl?.pathname?.includes("/") && newUrl?.pathname?.split("/");
    const npName = newPathArr[newPathArr?.length - 1]
      ? newPathArr[newPathArr?.length - 1]
      : "home";

    if (!(npName in copyInfo)) {
      copyInfo[npName] = { name: pageName, route };
    }
    this.setState({ pageInfo: copyInfo, currentPage: npName });
  };

  getAllInCart = (
    loginStatus = false,
    token = false,
    options = { toggleCart: true }
  ) => {
    if (loginStatus) {
      Fetch("prod/cart", null, {
        method: "get",
        sendToken: true,
        token: token ? token : false,
      })
        .then((res) => {
          if (res?.success) {
            let totalItemsInBakset = res.data.reduce((acc, item) => {
              return acc + item.count;
            }, 0);

            this.setState({
              cart: res.data,
              totalCartItems: totalItemsInBakset || 0,
            });

            if (options?.toggleCart) {
              this.handleRightCart("toggle_cart", true);
            }
          } else {
            new Error("Something went wrong..");
          }
        })
        .catch((err) => console.error(err));
      return;
    }

    if (options?.update) {
      Auth.handleLocalStorage("temp", options?.items);
      let totalItemsInBakset = options?.items.reduce((acc, item) => {
        return acc + item.count;
      }, 0);
      this.setState({
        cart: options?.items,
        totalCartItems: totalItemsInBakset || 0,
      });

      if (options?.toggleCart) {
        this.handleRightCart("toggle_cart", true);
      }
      return;
    }

    const data = Auth.handleLocalStorage("temp", null, "get") || [];

    let totalItemsInBakset = data.reduce((acc, item) => {
      return acc + item.count;
    }, 0);

    this.setState({
      cart: data,
      totalCartItems: totalItemsInBakset || 0,
    });

    if (options?.toggleCart) {
      this.handleRightCart("toggle_cart", true);
    }
    return;
  };

  updateLoginInfo = (isLogin) => {
    this.setState({ isUserLogin: isLogin });
  };

  onUserLogout = async () => {
    const checkLogin = await Auth.logoutUser();
    localStorage.removeItem("temp");
    this.updateLoginInfo(checkLogin, "");
    this.getAllInCart(false, false, { toggleCart: false });
  };

  getPrevScrName = ({ name, path }) => {
    this.setState({ prevScrInfo: { name, path } });
  };

  handlePaymentPageRedirection = (bool = false, cartID = null) => {
    this.setState({
      isRedirectedFromCheckout: true,
      cartID_paymentPage: cartID,
    });
  };

  getAllPagesData = () => {
    Fetch("page/allpages", null, { method: "get" }).then((res) => {
      if (res.success) {
        let obj = {};
        res.data.forEach((d) => {
          obj[d.slug] = d;
        });
        this.setState({ pagesData: obj });
      }
    });
  };

  handleRightCart = (_case = "toggle_cart", value) => {
    if (_case === "toggle_cart") {
      this.setState({
        rightCartProps: { ...this.state.rightCartProps, show: value },
      });
      return;
    }
    this.setState({
      rightCartProps: { ...this.state.rightCartProps, count: value },
    });
  };

  updateDiscountID = (id, code, discAmount, couponType) => {
    this.setState({
      couponCodeInfo: {
        ...this.state.couponCodeInfo,
        discountCodeID: id,
        validCouponCode: code,
        discountAmount: discAmount,
        couponValueType: couponType,
      },
    });
    // this.setState({discountCodeID: id, validCouponCode: code, discountAmount: discAmount})
  };

  render() {
    return (
      <MainContext.Provider
        value={{
          ...this.state,
          updateLoginInfo: this.updateLoginInfo,
          logoutUser: this.onUserLogout,
          getAllInCart: this.getAllInCart,
          getPrevScrName: this.getPrevScrName,
          handlePaymentPageRedirection: this.handlePaymentPageRedirection,
          handleRightCart: this.handleRightCart,
          updateDiscountID: this.updateDiscountID,
        }}
      >
        {this.props.children}
      </MainContext.Provider>
    );
  }
}

export const withContext = (Components) => (props) => {
  return (
    <MainContext.Consumer>
      {(value) => <Components context={value} {...props} />}
    </MainContext.Consumer>
  );
};
