import React, { Component, useState, useEffect } from "react";
import MainLayout from "../layouts/MainLayout";
import Auth from "../common/Auth";
import { withRouter } from "react-router-dom";
import Cart from "../components/CartComp";
import Fetch from "../common/Fetch";
import { withContext } from "../context/Context";
import { isEmail, isDate, isNumeric } from "validator";
import PaymentForm from "../components/stripe-payment/PaymentForm";
import RingLoader from "../components/RingLoader";
import Popup from "../components/popups/LoginErrorWarning";

const paymentMethods = [
  {
    id: 1,
    name: "Paypal",
    label: "paypal",
  },

  {
    id: 2,
    name: "Cards",
    label: "cards",
  },
  // {
  //   id: 3,
  //   name: "Crypto",
  //   label: "crypto",
  // },
];

class Checkout extends Component {
  state = {
    isLoading: true,
    mainBtnLoader: false,
    avatar: "users/default.png",

    formInfo: {
      bemail: null,
      bfirst_name: null,
      blast_name: null,
      bphone: null,
      bcity: null,

      bcountry: "United Kingdom",

      bpostcode: null,
      bstate: null,
      bstreet_1: null,
      bstreet_2: null,
      city: null,

      country: "United Kingdom",

      created_at: "",
      dob: "",
      email: "",
      email_verified_at: "",
      first_name: "",
      is_active: 0,
      last_name: "",
      name: "",
      phone: "",
      postcode: null,
      role_id: "",
      settings: [],
      state: null,
      street_1: null,
      street_2: null,
      updated_at: "",
      username: "",
    },

    country_code: "GB",
    bcountry_code: "GB",

    amountToPay: 0,
    currency: "GBP",

    isSameBillingAddress: false,
    showBillingAddressField: false,
    showBillingAddressCheckbox: false,

    view: "address",
    cart_id: null,
    payment_method: null,

    newsLetter: false,
    showPaymentBtns: false,
    shippingCharges: 0,
    errors: {},
    fav: [],
    editMode: false,
    errMsg: "",
    successMsg: "",
    cardFieldsShow: false,
    cart: [],
    order_data: [],
    store_id: null,
    savedFormInfo: {},
    locationDetails: { location_id: null, country_id: null, state_id: null },
    onlyGiftCard: false,
    popupData: { show: false, type: "", text: "", btnText: "" },

    CryptoCheckoutID: "",
    showBitcoinBtn: false,
  };

  static getDerivedStateFromProps(props, state) {
    const { cart } = props.context;
    if (state.cart !== cart) {
      let amount = 0;
      let cart_id = null;
      cart &&
        cart?.length &&
        cart?.forEach((d) => {
          amount += parseFloat(d.price);
          if (cart_id === null) {
            cart_id = d.cart_id;
          }
        });
      return { cart, amountToPay: amount, cart_id };
    }
    return null; // No change to state
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    const { cart, isUserLogin } = this.props.context;
    const isLogin = Auth.checkUser();

    let amount = 0;
    let cart_id = null;

    cart &&
      cart?.length &&
      cart?.forEach((d) => {
        amount += parseFloat(d.price);
        if (cart_id === null) {
          cart_id = d.cart_id;
        }
      });

    this.setState({ amountToPay: amount, cart_id });

    if (isLogin) {
      Fetch("auth/my-profile", {}, { sendToken: true }).then((d) => {
        if (
          d?.first_name &&
          d?.first_name.trim().length &&
          d?.last_name &&
          d?.last_name.trim().length &&
          d?.email &&
          d?.email.trim().length &&
          d?.phone &&
          d?.phone.trim().length &&
          d?.postcode &&
          d?.postcode.trim().length &&
          d?.street_1 &&
          d?.street_1.trim().length &&
          d?.street_2 &&
          d?.street_2.trim().length &&
          d?.state &&
          d?.state.trim().length &&
          d?.city &&
          d?.city.trim().length &&
          d?.country &&
          d?.country.trim().length
        ) {
          this.setState({ showBillingAddressField: true });
        }

        let country = "",
          bcountry = "";

        if (d?.country === null || d?.bcountry === null) {
          this.setState({ country_code: "GB", bcountrycode: "GB" });
        }

        this.setState({
          isLoading: false,
          formInfo: { ...d, country, bcountry },
          savedFormInfo: { ...d },
        });
      });
      return;
    }
    this.setState({ isLoading: false });
  };

  onSubmit = (formInfo, callback, options) => {
    const { isUserLogin } = this.props.context;
    const giftCardOnly = options?.onlyGiftCard ?? false;
    this.setState({ giftCardOnly });
    if (!isUserLogin) {
      this.onSignupProcess(formInfo, callback);
      return;
    }

    this.updateUserProfileInfo(false, formInfo, callback);
    return;
  };

  updateUserProfileInfo = (token = false, formInfo, callback) => {
    const opts = { sendToken: true, token };
    if (!token) {
      delete opts["token"];
    }

    Fetch("auth/update-profile", { ...formInfo }, opts).then((d) => {
      callback();

      if (d.success) {
        this.setState({
          editMode: false,
          showPaymentBtns: true,
          btnLoader: false,
          mainBtnLoader: false,
        });

        this.handleView("payments");
      }
    });
  };

  onSignupProcess = (formInfo, callback) => {
    const { cart, getAllInCart, updateLoginInfo } = this.props.context;
    const { first_name, last_name, email, phone, dob } = formInfo;

    Auth.registerUser({
      first_name,
      last_name,
      username: email,
      email,
      phone,
      dob: "21/07/1987",
      password: "1234",
      c_password: "1234",
    }).then((res) => {
      if (res?.status === 422 || !res?.success) {
        callback();
        let errObj = { ...res?.message };

        if ("email" in errObj) {
          this.setState({
            popupData: {
              ...this.state.popupData,
              show: true,
              type: "login_warn",
              text: errObj["email"],
              btnText: "Login to continue",
            },
          });
        }

        return;
      }

      if (res?.success) {
        this.setState({
          errMsg: "",
          btnLoader: false,
        });
        localStorage.setItem("AUTH_TOKEN", res.data?.token);
        updateLoginInfo(true);
        if (cart?.length) {
          cart.forEach((d, i) => {
            Fetch(
              "prod/cart/add",
              { ...d.params },
              { sendToken: true, token: res?.data?.token }
            )
              .then((res) => {
                if (res?.status === 422 || !res?.success) {
                  callback();
                  window.handleToastMsg(true, "Something went wrong");
                  return;
                }

                getAllInCart(true, res?.data?.token, { toggleCart: false });
                if (i === cart.length - 1) {
                  this.updateUserProfileInfo(
                    res?.data?.token,
                    formInfo,
                    callback
                  );
                  localStorage.removeItem("temp");
                }
              })
              .catch((e) => console.log(e));
          });
        }
      }
    });
  };

  onPaymentComplete = (view, data) => {
    this.setState({ view, order_data: data });
  };

  handleView = (view) => {
    this.setState({ view });
  };

  updateStoreID = (store_id) => {
    this.setState({ store_id });
  };

  updateState = (key, value, multipleObj = {}) => {
    if (Object.keys(multipleObj)?.length) {
      this.setState({ ...this.state, ...multipleObj });
      return;
    }

    this.setState({ ...this.state, [key]: value });
  };

  render() {
    const {
      isLoading,
      view,
      cardFieldsShow,
      cart,
      cart_id,
      order_data,
      savedFormInfo,
      showBillingAddressField,
      shippingCharges,
      locationDetails,
      onlyGiftCard,
      popupData,
      store_id,
    } = this.state;


    return (
      <div className="checkout">
        <MainLayout
          sectionLoadingOnly
          title="Checkout | Dark Sugar"
          loading={isLoading}
          breadcrumbProps={{ color: `var(--dark)` }}
        >
          <div className=" inline-block checkout-form width-100">
            <div className="checkout-nav" style={{ display: "none" }}>
              {" "}
              Bag
              <span> {">"} </span>checkout <span> {">"} </span> order
              confirmation
            </div>

            <div className="flex mb5 container">
              <PaymentForm
                show={view === "payments"}
                changeView={this.onPaymentComplete}
                cartID={cart_id}
                store_id={store_id}
                locationDetails={locationDetails}
                onlyGiftCard={onlyGiftCard}
                updateParentState={this.updateState}
              />

              <AddressHandler
                show={view === "address" && !cardFieldsShow}
                onChange={this.handleChange}
                handleBillingAddressCheckbox={this.handleBillingAddressCheckbox}
                billingAddressField={showBillingAddressField}
                cart={cart}
                onPaymentComplete={this.onPaymentComplete}
                onSubmit={this.onSubmit}
                onUpdateStoreID={this.updateStoreID}
                savedFormInfo={savedFormInfo}
                getShippingCharges={(shippingCharges) => {
                  this.setState({ shippingCharges });
                }}
                getLocationDetails={(obj) => {
                  this.setState({
                    locationDetails: {
                      ...this.state.locationDetails,
                      ...obj,
                    },
                  });
                }}
                updateParentState={this.updateState}
                cardFieldsShow={cardFieldsShow}
              />

              {view === "thank-you" ? (
                <div className="row thanks">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
                    <h4 className="thanks-heading center">
                      Thank you Your Order has been successfully placed !
                    </h4>
                    <p>
                      {" "}
                      Order Number: <span>{order_data[0]?.order_no}</span>
                    </p>

                    <div className="mt4">
                      <h3 className="mt2 mb2 f16 fw600">Price summary :</h3>
                      <div className="thanks-price-summary flex justify-space">
                        <ul>
                          <li>
                            {" "}
                            Date: <span>{order_data[0]?.created_at}</span>
                          </li>

                          <li>
                            {" "}
                            Transaction ID:{" "}
                            <span>{order_data[0]?.transaction_id}</span>
                          </li>

                          <li>
                            {" "}
                            Price:{" "}
                            <span>&#163; {order_data[0]?.price || 0}</span>
                          </li>

                          {order_data[0]?.discount ? (
                            <li>
                              {" "}
                              Discount:{" "}
                              <span>&#163; {order_data[0]?.discount}</span>
                            </li>
                          ) : null}

                          <li>
                            {" "}
                            Total:{" "}
                            <span>
                              &#163; {parseFloat(order_data[0]?.price) - 0 || 0}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <button
                      onClick={() => {
                        this.props.history.push("/account?view=orders");
                      }}
                      className="checkout-btn float-none mt2"
                    >
                      View Orders
                    </button>
                  </div>
                </div>
              ) : null}

              {view === "address" ? (
                <div className="col-xl-4 col-lg-4 col-md-4 mob-display-none checkout-right">
                  <Cart
                    show={true}
                    props={this.props}
                    from="checkout"
                    shippingCharges={shippingCharges}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </MainLayout>

        <Popup
          {...popupData}
          handleBtnClick={(type) => {
            if (type === "login_warn") {
              this.props.history.push("/login?r=/checkout");
              return;
            }
          }}
          onClose={() => {
            this.setState({
              popupData: { ...popupData, show: false, type: "", text: "" },
            });
          }}
        />
      </div>
    );
  }
}

export default withContext(withRouter(Checkout));

export const AddressHandler = ({
  cart,
  onSubmit,
  onUpdateStoreID,
  billingAddressField,
  savedFormInfo,
  getShippingCharges,
  getLocationDetails,
  updateParentState,
  cardFieldsShow,
  show
}) => {
  const [addressValues, setAddressValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isSameBillingAddress, setBillingAddress] = useState(false);
  const [showBillingAddressField, toggleBillingAddressField] = useState(false);
  const [showStoreList, setShowStoreList] = useState(null);
  const [storesList, setStoresList] = useState([]);
  const [storeListLoading, setStoreListLoading] = useState(false);
  const [store_id, set_store_id] = useState(null);
  const [countries, setCountries] = useState([]);
  const [states, setStatesList] = useState([]);
  const [btnLoader, setBtnLoader] = useState(false);
  const [onlyGiftCard, setOnlyGiftCard] = useState(false);

  useEffect(() => {
    if (cart?.length) {
      let nonGiftItem = cart?.find((d) => {
        let parentCategory =
          d.parent_category &&
          Array.isArray(d.parent_category) &&
          d.parent_category?.length
            ? d?.parent_category[0]
            : d?.parent_category && d?.parent_category?.slug
            ? d.parent_category
            : null;

        if (parentCategory) {
          return (
            parentCategory.slug === "personalised-box" ||
            parentCategory.slug === "prepacked-items" ||
            parentCategory.slug === "hot-chocolates"
          );
        }

        return (
          d.category.slug === "personalised-box" ||
          d.category.slug === "prepacked-items" ||
          d.category.slug === "hot-chocolates"
        );
      });

      if (!nonGiftItem) {
        setOnlyGiftCard(true);
        updateParentState("onlyGiftCard", true);
      }
    }
  }, [cart]);

  useEffect(() => {
    if (Object.keys(savedFormInfo)?.length) {
      setAddressValues(JSON.parse(JSON.stringify(savedFormInfo)));
    } else {
      setAddressValues(JSON.parse(JSON.stringify(formInfo)));
    }

    Fetch("prod/alllocations", null, { method: "get", token: false }).then(
      (res) => {
        if (res) {
          setCountries(res?.data);

          if (savedFormInfo?.country) {
            let selectedCountryObj = res?.data?.find(
              (d) => d.country.name === savedFormInfo?.country
            );
            const { id, country, state } = selectedCountryObj;
            setStatesList(state);
            getLocationDetails({ location_id: id, country_id: country?.id });

            if (savedFormInfo?.state && state?.length) {
              const selectedStateObj = state.find(
                (dd) => dd.name === savedFormInfo?.state
              );
              getLocationDetails({ state_id: selectedStateObj?.id });
            }
          }
        }
      }
    );
  }, [savedFormInfo]);

  useEffect(() => {
    if (billingAddressField) {
      toggleBillingAddressField(billingAddressField);
    }
  }, [billingAddressField]);

  const handleDeliveryMethod = (_evt) => {
    const { name } = _evt.target;

    if (name === "ship") {
      setShowStoreList(0);
      return;
    }

    setShowStoreList(1);
    getStoreList();
  };

  const getStoreList = () => {
    setStoreListLoading(true);
    Fetch("prod/allstores", null, { sendToken: false, method: "get" }).then(
      (res) => {
        setStoreListLoading(false);
        if (res?.data?.length) {
          setStoresList(res?.data);
        }
      }
    );
  };

  const handleChange = (evt) => {
    const { value, name, type, checked } = evt.target;

    if (name === "dob") {
      const dateFormat = isDate(value, {
        format: "DD/MM/YYYY",
        strictMode: true,
        delimiters: ["/"],
      });

      if (dateFormat) {
        setAddressValues({ ...addressValues, [name]: value });
        setErrors({ ...errors, [name]: "Invalid date format" });
        return;
      }

      setAddressValues({ ...addressValues, [name]: value });
      setErrors({ ...errors, [name]: "" });
      handleAddressFields({ ...addressValues, [name]: value });
      return;
    }

    if (name === "email") {
      if (isEmail(value)) {
        setAddressValues({ ...addressValues, [name]: value });
        setErrors({ ...errors, [name]: "" });
        handleAddressFields({ ...addressValues, [name]: value });
        return;
      }

      setAddressValues({ ...addressValues, [name]: value });
      setErrors({ ...errors, [name]: "Invalid email" });
      return;
    }

    if (name === "phone") {
      const numbersOnly = isNumeric(value);
      if (value !== "" && !numbersOnly) return;
      if (value.length < 10) {
        setAddressValues({ ...addressValues, [name]: value });
        setErrors({ ...errors, [name]: "Invalid phone number" });
        return;
      }

      if (value.length > 15) {
        setAddressValues({
          ...addressValues,
          [name]: value.substring(0, value.length - 1),
        });
        setErrors({ ...errors, [name]: "" });
        return;
      }

      setAddressValues({
        ...addressValues,
        [name]: value,
      });
      setErrors({ ...errors, [name]: "" });
      handleAddressFields({ ...addressValues, [name]: value });
      return;
    }

    if (name === "country") {
      const selectedObj = countries?.find((d) => d.country.name === value);
      const { state, price, id, country } = selectedObj;
      // set_country_code(selectedCountry?.phoneprefix);
      setAddressValues({
        ...addressValues,
        [name]: value,
        bcountry: value,
        state: null,
        bstate: null,
        city: "",
        bcity: "",
        street_1: "",
        street_2: "",
        postcode: "",
        bpostcode: "",
        bstreet_1: "",
        bstreet_2: "",
      });

      setErrors({ ...errors, [name]: "", bcountry: "" });
      handleAddressFields({
        ...addressValues,
        [name]: value,
        bcountry: value,
        state: null,
        bstate: null,
        city: "",
        bcity: "",
        street_1: "",
        street_2: "",
        postcode: "",
        bpostcode: "",
        bstreet_1: "",
        bstreet_2: "",
      });

      setStatesList(state);
      getShippingCharges(price);
      getLocationDetails({
        location_id: id,
        country_id: country?.id,
        state_id: null,
      });
      return;
    }

    if (name === "state") {
      const selectedState = states?.find((d) => value === Number(d.name));
      setAddressValues({
        ...addressValues,
        [name]: value,
        bstate: value,
      });
      setErrors({ ...errors, [name]: "", bstate: "" });
      handleAddressFields({ ...addressValues, [name]: value, bstate: value });
      getLocationDetails({ state_id: Number(selectedState?.id) });
      return;
    }

    // this.handleAddressFields({ ...this.state.formInfo, [name]: value });
    handleAddressFields({ ...addressValues, [name]: value });

    setAddressValues({
      ...addressValues,
      [name]: value,
    });
    setErrors({ ...errors, [name]: "" });
    return;
  };

  const handleBillingAddressCheckbox = (event) => {
    const {
      first_name,
      last_name,
      email,
      phone,
      city,
      country,
      postcode,
      state,
      street_1,
      street_2,
      bcountry,
    } = addressValues;

    const { checked, value, name } = event.target;

    if (checked) {
      setBillingAddress(true);
      setAddressValues({
        ...addressValues,
        bfirst_name: first_name,
        blast_name: last_name,
        bemail: email,
        bphone: phone,
        bcity: city,
        bstate: state,
        bcountry: country,
        bpostcode: postcode,
        bstreet_1: street_1,
        bstreet_2: street_2,
      });

      setErrors({
        ...errors,
        bfirst_name: "",
        blast_name: "",
        bemail: "",
        bphone: "",
        bcity: "",
        bstate: "",
        bcountry: "",
        bpostcode: "",
        bstreet_1: "",
        bstreet_2: "",
      });
      return;
    }

    setBillingAddress(false);
    setAddressValues({
      ...addressValues,
      bfirst_name: "",
      blast_name: "",
      bemail: "",
      bphone: "",
      bcity: "",
      bstate: "",
      bcountry: "",
      bpostcode: "",
      bstreet_1: "",
      bstreet_2: "",
    });
  };

  const handleAddressFields = (formInfo) => {
    const {
      first_name,
      last_name,
      email,
      phone,
      country,
      postcode,
      state,
      street_1,
      street_2,
    } = formInfo;

    if (
      first_name?.trim()?.length &&
      last_name?.trim()?.length &&
      email?.trim()?.length &&
      phone?.trim()?.length &&
      country?.trim().length &&
      postcode?.trim().length &&
      street_1?.trim().length &&
      street_2?.trim().length
    ) {
      if (states?.length && state?.trim().length)
        return toggleBillingAddressField(true);
      return toggleBillingAddressField(true);
    }

    return;
  };

  const handleSubmit = (_evt) => {
    _evt.preventDefault();

    if (btnLoader) return;

    if (!cart?.length) {
      window.handleToastMsg(true, "No items available in cart");
      return;
    }

    for (let d in errors) {
      if (errors[d]) return;
    }
    let tempErrObj = {};

    if(!onlyGiftCard && showStoreList === null){
      window.handleToastMsg(true, "Please select delivery type...")
      return;
    }

    if(showStoreList && store_id === null){
      window.handleToastMsg(true, "Please select store for pickup...")
      return;
    }

    if (store_id || onlyGiftCard) {
      let { first_name, last_name, phone, email } = addressValues;

      let newObj = {
        first_name,
        last_name,
        phone,
        email,
      };

      Object.keys(newObj)?.forEach((d) => {
        if (!newObj[d]) {
          tempErrObj[d] = "Required";
        }
      });

      setErrors({ ...errors, ...tempErrObj });
      if (Object.keys(tempErrObj)?.length) return;
      setBtnLoader(true);
      onSubmit(addressValues, () => setBtnLoader(false), { onlyGiftCard });
      return;
    }

    for (let d in addressValues) {
      if (isSameBillingAddress) {
        if (
          d !== "bcity" &&
          d !== "bstate " &&
          d !== "bcountry" &&
          d !== "bpostcode" &&
          d !== "bstreet_1" &&
          d !== "bstreet_2" &&
          d !== "bemail" &&
          d !== "bfirst_name" &&
          d !== "blast_name" &&
          d !== "bphone" &&
          d !== "email_verified_at" &&
          d !== "trial_ends_at" &&
          d !== "pm_last_four" &&
          d !== "pm_type" &&
          d !== "stripe_id"
        ) {
          if (states?.length) {
            if (tempErrObj[d] === null || tempErrObj[d] === "") {
              tempErrObj[d] = "Required !";
            }
          } else {
            if (d === "state") {
              delete tempErrObj[d];
            } else {
              if (tempErrObj[d] === null || tempErrObj[d] === "") {
                tempErrObj[d] = "Required !";
              }
            }
          }
        }
      } else {
        if (
          d !== "email_verified_at" &&
          d !== "trial_ends_at" &&
          d !== "pm_last_four" &&
          d !== "pm_type" &&
          d !== "stripe_id"
        ) {
          if (states?.length) {
            if (tempErrObj[d] === null || tempErrObj[d] === "") {
              tempErrObj[d] = "Required !";
            }
          } else {
            if (d === "state") {
              delete tempErrObj[d];
            } else {
              if (tempErrObj[d] === null || tempErrObj[d] === "") {
                tempErrObj[d] = "Required !";
              }
            }
          }
        }
      }
    }

    setErrors({ ...errors, ...tempErrObj });
    if (Object.keys(tempErrObj).length) return;
    setBtnLoader(true);
    onSubmit(addressValues, () => setBtnLoader(false));
  };

  if(!show) return null;

  const {
    bemail,
    bfirst_name,
    blast_name,
    bphone,
    bcity,
    bcountry,
    bpostcode,
    bstate,
    bstreet_1,
    bstreet_2,

    first_name,
    last_name,
    email,
    phone,
    city,
    country,
    postcode,
    state,
    street_1,
    street_2,
  } = addressValues;

  if (btnLoader) {
    return (
      <div className="checkout-main">
        <div className="checkout-loader">
          <div>
            <RingLoader />
            Getting things ready for you...
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="container col-xl-8 col-lg-8 col-md-8 col-sm-12"
      style={{ display: cardFieldsShow ? "none" : "" }}
    >
      <div className="checkout-main">
        <form method="POST" onSubmit={handleSubmit}>
          <div className="checkout-head mb24 mt2">Personal Information</div>
          <div className="checkout-margin">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <label>First name</label>
              <input
                type="text"
                // readOnly
                placeholder="Your name"
                name="first_name"
                className={errors?.["first_name"] ? "red-border" : ""}
                value={first_name}
                onChange={handleChange}
              ></input>

              {errors["first_name"] ? (
                <div style={{ padding: "10px 0 10px 0" }}>
                  <p className="errMsg">{errors["first_name"]}</p>
                </div>
              ) : null}
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <label>last name</label>
              <input
                type="text"
                placeholder="Your last name"
                className={errors?.["last_name"] ? "red-border" : ""}
                value={last_name}
                name="last_name"
                onChange={handleChange}
              ></input>

              {errors["last_name"] ? (
                <div style={{ padding: "10px 0 10px 0" }}>
                  <p className="errMsg">{errors["last_name"]}</p>
                </div>
              ) : null}
            </div>

            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <label>Email Address</label>
              <input
                type="text"
                placeholder="Email Address"
                className={errors?.["email"] ? "red-border" : ""}
                value={email}
                name="email"
                onChange={handleChange}
              ></input>

              {errors["email"] ? (
                <div>
                  <p className="errMsg">{errors["email"]}</p>
                </div>
              ) : null}
            </div>

            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <label>Phone Number</label>
              <input
                type="text"
                placeholder="Phone Number"
                className={errors?.["phone"] ? "red-border" : ""}
                value={phone}
                name="phone"
                onChange={handleChange}
              ></input>

              {errors["phone"] ? (
                <div>
                  <p className="errMsg">{errors["phone"]}</p>
                </div>
              ) : null}
            </div>
          </div>

          {onlyGiftCard ? null : (
            <div className="checkout-list">
              <div className="checkout-head mb24 mt5">Delivery Method</div>

              <ul className="listing ">
                {" "}
                <li>
                  <input
                    type="radio"
                    id="Ship"
                    onChange={handleDeliveryMethod}
                    checked={showStoreList === 0}
                    name="ship"
                  />
                  <label htmlFor="Ship">
                    <span></span>
                    Ship
                  </label>
                </li>
                <li>
                  <input
                    type="radio"
                    id="delivery"
                    onChange={handleDeliveryMethod}
                    name="delivery"
                    checked={showStoreList}
                  />
                  <label htmlFor="delivery">
                    <span></span>
                    Pickup
                  </label>
                </li>
              </ul>
            </div>
          )}

          <div className="checkout-list">
            {storeListLoading ? (
              <div className="checkout-loader">
                <div>
                  <RingLoader />
                  Getting available pick up locations..
                </div>
              </div>
            ) : null}

            {showStoreList && !storeListLoading ? (
              <React.Fragment>
                <div className="checkout-head mb24 mt5">Pickup location</div>
                <ul className="listing">
                  {" "}
                  {storesList?.length
                    ? storesList.map((d, i) => {
                        return (
                          <li className="cursor-pointer padding-0">
                            <input
                              type="radio"
                              id={`store_${d.id}`}
                              name="store"
                              onChange={(evt) => {
                                set_store_id(d.id);
                                onUpdateStoreID(Number(d.id));
                              }}
                              checked={Number(store_id) === Number(d.id)}
                            />
                            <label
                              htmlFor={`store_${d.id}`}
                              className="text-transform-none"
                            >
                              <span></span>

                              <div className="flex justify-space width-100">
                                <div className="checkout-address">
                                  {d.name}
                                  <p>{d.address}</p>
                                </div>
                                <div className="checkout-price">
                                  <div>{d.price}</div>
                                  <p>{d.time}</p>
                                </div>
                              </div>
                            </label>
                          </li>
                        );
                      })
                    : null}
                </ul>
              </React.Fragment>
            ) : null}
          </div>

          {showStoreList === 0 ? (
            <React.Fragment>
              <div className="checkout-head mb24 mt5">Deliery Address</div>

              <div className="checkout-margin flex flex-wrap">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                  <label>Country</label>
                  <select
                    onChange={handleChange}
                    value={country}
                    selected={country}
                    placeholder="Country"
                    name="country"
                    className={errors?.["country"] ? "red-border" : ""}
                  >
                    <option
                      disabled
                      selected
                      // value={d.id}
                      // defaultValue=""
                    >
                      Choose Country
                    </option>

                    {countries.map((d, i) => (
                      // <option key={i} value={d.id}>
                      <option key={i} value={d.country?.name}>
                        {d.country?.name}
                      </option>
                    ))}
                  </select>

                  {errors["country"] ? (
                    <div>
                      <p className="errMsg">{errors["country"]}</p>
                    </div>
                  ) : null}
                </div>

                {states?.length ? (
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                    <label>State</label>

                    <select
                      onChange={handleChange}
                      value={state}
                      selected={state}
                      placeholder="state"
                      name="state"
                      disabled={!country?.trim().length}
                      className={errors?.["state"] ? "red-border" : ""}
                    >
                      <option
                        disabled
                        selected
                        // value={d.id}
                        // defaultValue=""
                      >
                        Choose State
                      </option>

                      {states?.map((dd, ind) => (
                        <option key={ind} value={dd.name}>
                          {dd.name}
                        </option>
                      ))}
                    </select>

                    {errors["state"] ? (
                      <div>
                        <p className="errMsg">{errors["state"]}</p>
                      </div>
                    ) : null}
                  </div>
                ) : null}

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                  <label>City</label>
                  <input
                    type="text"
                    placeholder="City"
                    value={city}
                    className={errors?.["city"] ? "red-border" : ""}
                    name="city"
                    onChange={handleChange}
                  ></input>

                  {errors["city"] ? (
                    <div>
                      <p className="errMsg">{errors["city"]}</p>
                    </div>
                  ) : null}
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                  <label>postcode</label>
                  <input
                    type="text"
                    placeholder="Postcode"
                    value={postcode}
                    name="postcode"
                    className={errors?.["postcode"] ? "red-border" : ""}
                    onChange={handleChange}
                  ></input>

                  {errors["postcode"] ? (
                    <div>
                      <p className="errMsg">{errors["postcode"]}</p>
                    </div>
                  ) : null}
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <label>Street address line 1</label>
                  <input
                    type="text"
                    placeholder="Street address line 1"
                    value={street_1}
                    className={errors?.["street_1"] ? "red-border" : ""}
                    name="street_1"
                    onChange={handleChange}
                  ></input>

                  {errors["street_1"] ? (
                    <div>
                      <p className="errMsg">{errors["street_1"]}</p>
                    </div>
                  ) : null}
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <label>Street address line 2</label>
                  <input
                    type="text"
                    placeholder="Street address line 2"
                    className={errors?.["street_2"] ? "red-border" : ""}
                    value={street_2}
                    name="street_2"
                    onChange={handleChange}
                  ></input>

                  {errors["street_2"] ? (
                    <div>
                      <p className="errMsg">{errors["street_2"]}</p>
                    </div>
                  ) : null}
                </div>
              </div>

              {showBillingAddressField ? (
                <div className="checkout-main checkout-list">
                  <div className="checkout-head mb24 mt5">Billing address</div>

                  <ul className="listing ">
                    <li className={isSameBillingAddress ? "" : "line-through"}>
                      <input
                        type="checkbox"
                        id="address"
                        onChange={handleBillingAddressCheckbox}
                      />

                      <label htmlFor="address">
                        <span></span>
                        My billing address is the same as my delivery address
                      </label>
                    </li>
                  </ul>

                  {!isSameBillingAddress ? (
                    <React.Fragment>
                      <div className="checkout-margin">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                          <label>First name</label>
                          <input
                            type="text"
                            placeholder="Your first name"
                            name="bfirst_name"
                            className={
                              errors?.["bfirst_name"] ? "red-border" : ""
                            }
                            value={bfirst_name}
                            onChange={handleChange}
                          ></input>

                          {errors["bfirst_name"] ? (
                            <div style={{ padding: "10px 0 10px 0" }}>
                              <p className="errMsg">{errors["bfirst_name"]}</p>
                            </div>
                          ) : null}
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                          <label>last name</label>
                          <input
                            type="text"
                            placeholder="Your last name"
                            className={
                              errors?.["blast_name"] ? "red-border" : ""
                            }
                            value={blast_name}
                            name="blast_name"
                            onChange={handleChange}
                          ></input>

                          {errors["blast_name"] ? (
                            <div style={{ padding: "10px 0 10px 0" }}>
                              <p className="errMsg">{errors["blast_name"]}</p>
                            </div>
                          ) : null}
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <label>Email Address</label>
                          <input
                            type="text"
                            placeholder="Email Address"
                            className={errors?.["bemail"] ? "red-border" : ""}
                            value={bemail}
                            name="bemail"
                            onChange={handleChange}
                          ></input>

                          {errors["bemail"] ? (
                            <div>
                              <p className="errMsg">{errors["bemail"]}</p>
                            </div>
                          ) : null}
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <label>Phone Number</label>
                          <input
                            type="text"
                            placeholder="Phone Number"
                            className={errors?.["bphone"] ? "red-border" : ""}
                            value={bphone}
                            name="bphone"
                            onChange={handleChange}
                          ></input>

                          {errors["bphone"] ? (
                            <div>
                              <p className="errMsg">{errors["bphone"]}</p>
                            </div>
                          ) : null}
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                          <label>Country</label>
                          <select
                            value={bcountry}
                            selected={bcountry}
                            placeholder="Country"
                            name="bcountry"
                            className={errors?.["bcountry"] ? "red-border" : ""}
                            onChange={handleChange}
                            disabled
                          >
                            <option
                              disabled
                              selected
                              // value={d.id}
                              // defaultValue=""
                            >
                              Choose Country
                            </option>

                            {countries.map((d, i) => (
                              <option key={i} value={d.country?.name}>
                                {d.country?.name}
                              </option>
                            ))}
                          </select>

                          {errors["bcountry"] ? (
                            <div>
                              <p className="errMsg">{errors["bcountry"]}</p>
                            </div>
                          ) : null}
                        </div>

                        {states?.length ? (
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <label>State</label>
                            <select
                              onChange={handleChange}
                              value={bstate}
                              selected={bstate}
                              placeholder="bstate"
                              name="bstate"
                              disabled={!bcountry?.trim().length}
                              className={errors?.["bstate"] ? "red-border" : ""}
                            >
                              <option
                                disabled
                                selected
                                // value={d.id}
                                // defaultValue=""
                              >
                                Choose State
                              </option>

                              {states?.map((dd, ind) => (
                                <option key={ind} value={dd.name}>
                                  {dd.name}
                                </option>
                              ))}
                            </select>

                            {errors["bstate"] ? (
                              <div>
                                <p className="errMsg">{errors["bstate"]}</p>
                              </div>
                            ) : null}
                          </div>
                        ) : null}

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                          <label>postcode</label>
                          <input
                            type="text"
                            placeholder="Postcode"
                            value={bpostcode}
                            name="bpostcode"
                            className={
                              errors?.["bpostcode"] ? "red-border" : ""
                            }
                            onChange={handleChange}
                          ></input>

                          {errors["bpostcode"] ? (
                            <div>
                              <p className="errMsg">{errors["bpostcode"]}</p>
                            </div>
                          ) : null}
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                          <label>City</label>
                          <input
                            type="text"
                            placeholder="City"
                            value={bcity}
                            className={errors?.["bcity"] ? "red-border" : ""}
                            name="bcity"
                            onChange={handleChange}
                          ></input>

                          {errors["bcity"] ? (
                            <div>
                              <p className="errMsg">{errors["bcity"]}</p>
                            </div>
                          ) : null}
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <label>Billibg address line 1</label>
                          <input
                            type="text"
                            placeholder="Street address line 1"
                            value={bstreet_1}
                            name="bstreet_1"
                            className={
                              errors?.["bstreet_1"] ? "red-border" : ""
                            }
                            onChange={handleChange}
                          ></input>

                          {errors["bstreet_1"] ? (
                            <div>
                              <p className="errMsg">{errors["bstreet_1"]}</p>
                            </div>
                          ) : null}
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <label>Billing address line 2</label>
                          <input
                            type="text"
                            placeholder="Street address line 2"
                            value={bstreet_2}
                            name="bstreet_2"
                            className={
                              errors?.["bstreet_2"] ? "red-border" : ""
                            }
                            onChange={handleChange}
                          ></input>

                          {errors["bstreet_2"] ? (
                            <div>
                              <p className="errMsg">{errors["bstreet_2"]}</p>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </React.Fragment>
                  ) : null}
                </div>
              ) : null}
            </React.Fragment>
          ) : null}

          {cart?.length ? (
            <button
              type="submit"
              className="checkout-btn mt2"
              // onClick={this.onSubmit}
            >
              {btnLoader ? "please wait..." : "Proceed to pay"}
            </button>
          ) : null}
        </form>
      </div>
    </div>
  );
};

const formInfo = {
  bemail: null,
  bfirst_name: null,
  blast_name: null,
  bphone: null,

  bcity: null,

  bcountry: "",

  bpostcode: null,
  bstate: null,
  bstreet_1: null,
  bstreet_2: null,
  city: null,

  country: "",

  created_at: "",
  dob: "",
  email: "",
  email_verified_at: "",
  first_name: "",
  is_active: 0,
  last_name: "",
  name: "",
  phone: "",
  postcode: null,
  role_id: "",
  settings: [],
  state: "sdhghsgjgas",
  street_1: null,
  street_2: null,
  updated_at: "",
  username: "",
};

{
  /* <div className="checkout-main mt5 display-none">
                      <div className="checkout-margin checkout-list">
                        <div className="col-xl-6">
                          <div className="checkout-head mb24 ">
                            Personal Information
                          </div>

                          <div className="">
                            <ul className="listing">
                              {paymentMethods.map((d, i) => (
                                <li
                                  className={
                                    +payment_method === d.id
                                      ? ""
                                      : "line-through"
                                  }
                                  key={i}
                                >
                                  <input
                                    type="checkbox"
                                    id={`pm-${d.id}`}
                                    name="payment_method"
                                    value={d.id}
                                    checked={+payment_method === d.id}
                                    onChange={this.handleChange}
                                  />
                                  <label htmlFor={`pm-${d.id}`}>
                                    <span></span>
                                    {d.name}
                                  </label>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>

                        <div className="col-xl-6" style={{ display: "none" }}>
                          <div className="checkout-head mb24 ">
                            Personal Information
                          </div>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. It has
                            survived not only five centuries, but also the leap
                            into electronic typesetting, remaining essentially
                            unchanged. It was popularised in the 1960s with the
                            release of Letraset sheets containing.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="checkout-main checkout-list mb5 mt5">
                      <ul className="listing display-none">
                        <li className="">
                          <input
                            type="checkbox"
                            checked={newsLetter}
                            id="newLetter-1"
                            name="newsLetter"
                            onChange={this.handleChange}
                          />
                          <label htmlFor="newLetter-1">
                            <span></span>I would like to receive the newsletter
                          </label>
                        </li>
                      </ul>

                      <p style={{ display: "none" }}>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of
                        Letraset sheets containing.
                      </p>
                    </div>

                    {cart?.length ? (
                      <button
                        type="submit"
                        className="checkout-btn"
                        // onClick={this.onSubmit}
                      >
                        {btnLoader ? "please wait..." : "Proceed to pay"}
                      </button>
                    ) : null} */
}
{
  /* </form> */
}

{
  /* {showPaymentBtns ? (
                    payment_method < 3 ? (
                      <div
                        style={{ maxWidth: "255px", minHeight: "200px" }}
                        className={showPaymentBtns ? "" : "display-none"}
                      >
                        <PaypalPay
                          {...this.state}
                          onPaymentComplete={this.onPaymentComplete}
                          onValidation={this.onSubmit}
                          options={options}
                          amount={amount}
                          currency={currency}
                          style={style}
                        />
                      </div>
                    ) : payment_method === 3 && showBitcoinBtn ? (
                      <CoinbaseCommerceButton
                        checkoutId={CryptoCheckoutID}
                        onChargeSuccess={(d) => {
                          return this.onPaymentComplete("", "SUCCESS");
                        }}
                        onChargeFailure={(e) => {
                          console.log(e);
                          window.handleToastMsg(
                            true,
                            "Payment couldn't be completed"
                          );
                        }}
                        onPaymentDetected={(e) => {
                          window.handleToastMsg(
                            true,
                            "A Payment Has Been Detected. We will email you when the payment has been completed.",
                            5000
                          );
                        }}
                      />
                    ) : null
                  ) : null} */
}
