import React from "react";
import Modal from "../Modal";

export default function LoginErrorWarning({ show, type="login_warn", onClose, handleBtnClick, btnText, text }) {
  if (!show) return null;

  return (
    <Modal onClose={onClose}>
      <p>
        <strong>Warning: </strong>{text || "The email has already been taken."}
      </p>

     <div className="center"> <button onClick={()=>handleBtnClick(type)}>{btnText ?? "Login to continue"}</button>
     </div>
</Modal>
  );
}
