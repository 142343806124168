import React, { useState, useEffect } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import Fetch from "../../common/Fetch";
import { withContext } from "../../context/Context";

import RingLoader from "../RingLoader";

const appearance = {
  theme: "stripe",

  variables: {
    colorPrimary: "#0570de",
    colorBackground: "#ffffff",
    colorText: "#30313d",
    colorDanger: "#df1b41",
    fontFamily: "Ideal Sans, system-ui, sans-serif",
    spacingUnit: "2px",
    borderRadius: "4px",
    // See all possible variables below
  },
};

function PaymentForm({
  cartID,
  changeView,
  store_id,
  locationDetails,
  show=false,
  context: {
    couponCodeInfo: { discountCodeID },
    getAllInCart,
    updateDiscountID,
  },

  onlyGiftCard=false
}) {
  const stripe = useStripe();
  const elements = useElements({ appearance });

  const [loading, setLoading] = useState(true);
  const [defaultPaymentCard, setDefaultPaymentCard] = useState([]);

  useEffect(() => {
    if(show){
      Fetch("prod/paymentMethods", null, { method: "get", sendToken: true }).then(
        (res) => {
          setLoading(false);
          if (res && res.success) {
            let defPayMathod = res.data.filter((d) => d.default)[0]?.id;
            setDefaultPaymentCard(defPayMathod);
          }
        }
      );
    }
    window.scrollTo(0, 0);
  }, [show]);

  const createOrder = (cart_id, payment_method, secure = 1) => {
    setLoading(true);
    const { location_id, country_id, state_id } = locationDetails;
    console.log({store_id, onlyGiftCard, country_id, state_id, location_id})

    return Fetch(
      "prod/order/create",
      { 
        cart_id,
        payment_method,
        secure,
        store_id: onlyGiftCard ? null : store_id,
        location_id: onlyGiftCard || store_id ? null : location_id,
        country_id: onlyGiftCard || store_id ? null : country_id,
        state_id: onlyGiftCard || store_id ? null : state_id,
        discount_id: discountCodeID,
      },
      { method: "post", sendToken: true }
    ).then((res) => {
      if (secure === 0) {
        if (res.status === 200 && res?.message?.client_secret) {
          return stripe
            .confirmCardPayment(res?.message?.client_secret)
            .then((data) => {
              if (data.paymentIntent?.status === "succeeded") {
                createOrder(cart_id, payment_method, 1);
                return;
              }
              setLoading(false);
              window.handleToastMsg(true, "Something went wrong");
              return;
            });
        }

        setLoading(false);

        if (res.status === 200 && res.success) {
          window.handleToastMsg(
            true,
            res?.message || "Order placed successfully"
          );
          changeView("thank-you", res?.data);
          getAllInCart(true, false, {
            update: true,
            toggleCart: false,
          });
          updateDiscountID(null, "", 0, "");
          window.scrollTo(0, 0);
          return;
        }

        window.handleToastMsg(true, "something went wrong");
        return;
      }

      if (res.success) {
        window.handleToastMsg(
          true,
          res?.message || "Order placed successfully"
        );
        changeView("thank-you", res?.data);
        getAllInCart(true, false, {
          update: true,
          toggleCart: false,
        });
        updateDiscountID(null, "", 0, "");
        window.scrollTo(0, 0);
        return;
      }

      window.handleToastMsg(true, res?.message || "something went wrong");
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements || loading) return;

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
    });

    createOrder(cartID, paymentMethod.id, 0);
  };

  if(!show) return null;

  if (loading) {
    return (
      <div className="checkout-main">
        <div className="checkout-loader">
          <div>
            <RingLoader />
            Processing your request, do not refresh or press the back button...
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container col-xl-8 col-lg-8 col-md-8 col-sm-12 mt3">
      {defaultPaymentCard ? (
        <div>
          <button
            style={{ display: "block" }}
            className="btn btn-save"
            onClick={() => createOrder(cartID, defaultPaymentCard, 0)}
          >
            Pay using default payment method
          </button>

          <div className="mt3 mb2 f-14" style={{ display: "block" }}>
            {" "}
            OR{" "}
          </div>
        </div>
      ) : null}

      <form onSubmit={handleSubmit}>
        <div className="row-div full-width-col checkout-form">
          <label>Card number</label>
          <div className="checkout-payment">
            {" "}
            <CardNumberElement />
          </div>

          <label>Expiration date</label>
          <div className="checkout-payment">
            <CardExpiryElement />
          </div>

          <label className="cvc-wrap">CVC</label>
          <div className="checkout-payment">
            <CardCvcElement />
          </div>

          <button
            type="submit"
            disabled={!stripe}
            className="checkout-btn mt1 float-none"
          >
            {" "}
            Pay now{" "}
          </button>
        </div>
      </form>
    </div>
  );
}

export default withContext(PaymentForm);
