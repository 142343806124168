import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import Fetch from "../common/Fetch";
import RingLoader from "./RingLoader";

const initialMetaInfo = {
  // o_password: "",
  n_password: "",
  c_password: "",
};

function ChangePassword({ isLoggedIn = false, email, token, history, updatePage}) {
  const [metaInfo, setMetaInfo] = useState(initialMetaInfo);
  const [errors, setErrors] = useState(initialMetaInfo);
  const [btnLoader, setBtnLoader] = useState(false);

  useEffect(() => {
    const copyMetaInfo = { ...initialMetaInfo };
    // if (!isLoggedIn) {
    // delete copyMetaInfo[o_password];
    setMetaInfo(copyMetaInfo);
    setErrors(copyMetaInfo);
    // }
  }, [isLoggedIn]);

  const handleChange = (_evt) => {
    const { name, value } = _evt.target;
    setMetaInfo({ ...metaInfo, [name]: value });
  };

  const onUpdatePassword = () => {
    const { n_password, c_password } = metaInfo;
    if (!n_password.trim().length || !c_password.trim().length) {
      let err1 = !n_password.trim().length;
      let err2 = !c_password.trim().length;
      if (err1) {
        setErrors({ ...errors, n_password: "Required" });
      }
      if (err2) {
        setErrors({ ...errors, c_password: "Required" });
      }
      return;
    }

    if (n_password.trim() !== c_password.trim()) {
      window.handleToastMsg(
        true,
        "Passwords do not match, please check all the fields"
      );
      setTimeout(() => {
        window.handleToastMsg(false, "");
      }, 3000);
      return;
    }

    setBtnLoader(true);
    Fetch(
      "auth/password/reset",
      { email, password: n_password, token },
      { isToken: false }
    ).then((res) => {
      setBtnLoader(false);
      const copyMetaInfo = { ...initialMetaInfo };
      setMetaInfo(copyMetaInfo);
      setErrors(copyMetaInfo);
      if (res?.success) {
        window.handleToastMsg(
          true,
          res.message || "Password changed successfully, please continue login"
        );
        setTimeout(() => {
          window.handleToastMsg(false, "");
        }, 3000);
        updatePage && updatePage();
        window.location.href="/login"
        return;
      }
      window.handleToastMsg(
        true,
        res.message || "Unable to change password, please try after some time"
      );
      setTimeout(() => {
        window.handleToastMsg(false, "");
      }, 3000);
    });
  };
  // const [old_password, set_old_password] = useState("");
  // const [n_password]

  const { o_password, n_password, c_password } = metaInfo;

  return (
    <React.Fragment>
      <div className="login-head pb4">CHANGE PASSWORD</div>
      <div className="login-input">
        {/* <div className=" mb2">
          {" "}
          <label className="mb1">Old Password</label>
          <input
            type="text"
            className={errors?.[o_password] ? "red-border" : ""}
            placeholder="Enter your currentPassword"
            name="o_password"
            value={o_password}
            onChange={handleChange}
          />
        </div> */}

        <div className=" mb2">
          {" "}
          <label className="mb1">New Password</label>
          <input
            type="password"
            className={errors?.[n_password] ? "red-border" : ""}
            placeholder="Enter new password"
            name="n_password"
            value={n_password}
            onChange={handleChange}
          />
        </div>

        <div className="mb2">
          {" "}
          <label className="mb1">Confirm Password</label>
          <input
            type="password"
            className={errors?.[c_password] ? "red-border" : ""}
            placeholder="Confirm your new password"
            name="c_password"
            value={c_password}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="p0 flex login-right-btn mt4 mb1">
        <button className="login-signup-btn" onClick={onUpdatePassword}>
          {btnLoader ? <RingLoader /> : "continue"}
        </button>
      </div>
    </React.Fragment>
  );
}

export default withRouter(ChangePassword);
