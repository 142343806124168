import React from "react";
import icons from "../common/Images";
const { crossnew } = icons;
export default function Modal({ children, onClose }) {
  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-cross" onClick={onClose}>
          <img src={crossnew} className="float-right" alt="close"></img>
        </div>
        {children}
      </div>
    </div>
  );
}
