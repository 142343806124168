export const sidebarLinks = [
  {
    title: "Home",
    id: 0,
    path: "/",
    icon: false,
    show: true,
  },
  {
    title: "Shop",
    id: 1,
    path: "/shop",
    icon: false,
    show: true,
  },
  {
    title: "About us",
    id: 2,
    path: "/about-us",
    icon: false,
    show: true,
  },
  {
    title: "My Account",
    id: 3,
    path: "/account",
    icon: true,
    show: true,
  },
  {
    title: "Contact Us",
    id: 4,
    path: "/contact-us",
    icon: false,
    show: true,
  },

  {
    title: "Bookmarks",
    id: 5,
    path: "/account?type=6",
    icon: false,
    show: false,
  },
];

export const months = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};
