const expressDeliveryMsg = {
    1: {
        title: "Gift wrapping",
        desc: "Elevate your gift with our selection of six African-inspired wrapping papers. After choosing your chocolates, simply select your favorite wrapping paper to make your gift truly unique." 
    },
    2: {
        title: "Add Gift Message",
        desc: "Make your gift even more personal by including a heartfelt message. You can add your custom note just before checkout."
    },
    3: {
        title: "International Delivery",
        desc: `We offer delivery across the UK and worldwide. Check if we can deliver to your location at checkout. If you encounter any issues, please reach out to us at <a href="mailto:info@darksugars.co.uk">info@darksugars.co.uk</a> and we’ll be happy to assist you.`
    }
}

export default expressDeliveryMsg;