import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Link from "../common/Link";
import { plainStringConverter } from "../common/utils";

import RingLoader from "../components/RingLoader";
// import Breadcrumbs  from "../components/Breadcrumb";

import Arrow from "../../src/assets/arrow-left.svg";
import { withRouter } from "react-router";
import { withContext } from "../context/Context";

function MainLayout(props) {
  const {
    children,
    hideBreadCrumb = false,
    hideBorder = false,
    loading,
    title,
    isHeaderTransparent,
    location,
    breadcrumbProps,

    authRequired,
    redirect = false,
    redirectUrl = "",
    history,
    context: { prevPageInfo, currentPage, pageInfo },
  } = props;

  const docElement = useRef(null);
  const [scrollPos, setScrollPos] = useState(0);

  const onScroll = () => {
    const scrollY = window.scrollY; //Don't get confused by what's scrolling - It's not the window
    setScrollPos(Number(scrollY));
  };

  useEffect(() => {
    document.title = title;
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <main>
      {loading ? (
        <div className="main-loader">
          <RingLoader mainLoader />
        </div>
      ) : (
        <div
          className={
            isHeaderTransparent && scrollPos
              ? "main transparent-header"
              : "main"
          }
          ref={docElement}
        >
          <Header hideBorder={hideBorder} />
          <div className="breadcrumb-main">
            <div className="container">
              {location.pathname !== "/" ? (
                <div className="breadcrumb">
                  <div className="flex align-center">
                    <button
                      className="mr1"
                      onClick={() => {
                        const { history } = props;
                        if (pageInfo?.[currentPage]?.route) {
                          history.push(pageInfo?.[currentPage]?.route);
                          return;
                        }
                        history.push("/");
                      }}
                    >
                      {" "}
                      <img src={Arrow} />
                    </button>

                    {!hideBreadCrumb && pageInfo?.[currentPage]?.route ? (
                      <Link href={pageInfo?.[currentPage]?.route} styles={{color: breadcrumbProps?.color ?? ""}}>
                        {plainStringConverter(pageInfo?.[currentPage]?.name) ??
                          "home"}
                      </Link>
                    ) : null}
                  </div>

                  {/* <Breadcrumbs/>
                  Previous Screen Name */}
                </div>
              ) : null}
            </div>
          </div>
          {children}
          <Footer />
        </div>
      )}
    </main>
  );
}

export default withContext(withRouter(MainLayout));
