import React, {useEffect} from "react";
import { Link, withRouter } from "react-router-dom";
import { withContext } from "../context/Context";

function LinkCustom({context: {getPrevScrName}, location, children, href, styles, className, onClick }) {
  useEffect(() => {
    return () => {
      getPrevScrName({name: "Shop", path: location.pathname})
    }
  }, []);

  if(href) {
    return (
      <Link to={href} style={{...styles}} className={`default-anchor ${className}`}>
        {children}
      </Link>
    );
  }

  return (
    <button styles={styles} className={`btn ${className}`} onClick={onClick}>
      {children}
    </button>
  );
}

export default withContext(withRouter(LinkCustom))
