import React, { useState } from "react";

export default function TextArea({tilte, updateParentFxn, disabled, style, value, stopTyping, beforeOnChange}) {
  const [text, setText] = useState("");
  const  [personalisedMsg, setPersonalisedMsg] = useState("")

  const handleChange = (_evt) => {
    const { value } = _evt.target;
    if(value.length > 200) return;
    setText(value);
    updateParentFxn(value);
  };

  // const []
  return (
    <React.Fragment>
      <div className="heading" style={style}>{tilte}</div>
      <textarea
        onChange={handleChange}
        placeholder="Insert message here..."
        value={value ? value : text}
        disabled={disabled}
        onFocus={()=>{
          if(stopTyping && beforeOnChange){
            beforeOnChange();
            return;
          }
        }}
      ></textarea>
    </React.Fragment>
  );
}
