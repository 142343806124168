import React, { Component } from "react";
import MainLayout from "../layouts/MainLayout";
import icons from "../common/Images";

import Cart from "../components/CartComp";
import Fetch from "../common/Fetch";

import { withRouter } from "react-router-dom";

const { Truffle } = icons;
class Error extends Component {
  componentDidMount = () => {
  };

  render() {
    return (
      <div className="">
        <MainLayout sectionLoadingOnly title="404 | Dark Sugar">
          <div className="container flex align-center flex-wrap error">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 error-main ">
              <h3>
                {" "}
                404 ERROR · <span>PAGE NOT FOUND</span>
              </h3>
              <p>
                The page you are looking for might have been removed or
                temporarily unavailable.
              </p>
              <div
                className="error-para cursor-pointer"
                onClick={() => this.props.router.push("/")}
              >
                YOU CAN RETURN TO THE HOME PAGE
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 error-main-right">
              <img src={Truffle} alt=""></img>
            </div>
          </div>
        </MainLayout>
      </div>
    );
  }
}

export default withRouter(Error);
