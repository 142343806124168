import React, {Component} from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default function MainSlider({customArrows, ref,  children, breakPoints, slidesToShow = 1, params, scrollMultiSlides }) {
  let extraParameters = {
    speed: 500,
    initialSlide: 0,
    slidesToShow,
    slidesToScroll: 1,
    infinite: false,
    autoplay: false,
    dots: false,
    className: '',
    centerMode: false,
    slide: 'div'
  };

  if(customArrows){
    extraParameters["nextArrow"] = <RightArrow/>
    extraParameters["prevArrow"] = <LeftArrow/>
  }else{
    delete extraParameters["nextArrow"];
    delete extraParameters["prevArrow"];
  }

  const para = {
    ...extraParameters,
    ...params,
  };

  function showSlidesCount(object) {
    if (!object) return null;

    return (
      Object.entries(object) &&
      Object.entries(object)?.map(([brk, number]) => {
        return {
          breakpoint: Number(brk),
          settings: {
            slidesToShow: Number(number),
            slidesToScroll: scrollMultiSlides ? Number(number) : 1,
            // infinite: params?.infinite,
            // dots: params?.dots ? true : false
            // ...para
          },
        };
      })
    );
  }

  const responsive = { responsive: showSlidesCount(breakPoints) };
  // ?.sort((a,b)=> a.breakPoints - b.breakPoints) 

  const settings = {
    ...para,
    ...responsive
  }

  return <Slider {...settings} ref={ref}>{children}</Slider>;
}


const LeftArrow=(props)=>{
  const {className, onClick, currentSlide, style} = props;

  return (<button className={!onClick ? "display-none" : "custom-btn-l"} onClick={onClick}>Dark Sugar</button>)
}

const RightArrow=(props)=>{
  const {className, onClick, currentSlide, style} = props;
  return (<button className={!onClick ? "display-none" : "custom-btn-r"} onClick={onClick}>Dark Sugar-1</button>)
}