import React, {useEffect, useState} from "react";
import { withContext } from "../context/Context";
import { withRouter } from "react-router";

import MainLayout from "../layouts/MainLayout";

function Refund({location, context: { pagesData }}) {
  const [loading, setLoading] = useState(true);
  const [meta_data, set_meta_data] = useState({});

    useEffect(()=>{
        window.scrollTo(0,0);
    }, [])

    useEffect(()=>{
      let slug = location.pathname.split("/")[1];
      if(!Object.keys(meta_data).length){
        if(slug in pagesData){
          set_meta_data(pagesData[slug]);
          setLoading(false);
        }
      }
    }, [pagesData])


  return (
    <MainLayout title="Refund Policy | Dark Sugar" loading={loading}>
      <div className="terms">
        <div className="container ">
          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 terms-container">
            <h4>{meta_data?.title}</h4>
            <div className="terms-para" dangerouslySetInnerHTML={{__html: meta_data?.body}} >
              {/* This website is operated by DARK SUGARS COCOA HOUSE. Throughout
              the site, the terms “we”, “us” and “our” refer to DARK SUGARS
              COCOA HOUSE. DARK SUGARS COCOA HOUSE offers this website,
              including all information, tools and services available from this
              site to you, the user, conditioned upon your acceptance of all
              terms, conditions, policies and notices stated here.
              <br />
              <br />
              By visiting our site and/ or purchasing something from us, you
              engage in our “Service” and agree to be bound by the following
              terms and conditions (“Terms of Service”, “Terms”), including
              those additional terms and conditions and policies referenced
              herein and/or available by hyperlink. These Terms of Service apply
              to all users of the site, including without limitation users who
              are browsers, vendors, customers, merchants, and/ or contributors
              of content.
              <br />
              <br />
              Please read these Terms of Service carefully before accessing or
              using our website. By accessing or using any part of the site, you
              agree to be bound by these Terms of Service. If you do not agree
              to all the terms and conditions of this agreement, then you may
              not access the website or use any services. If these Terms of
              Service are considered an offer, acceptance is expressly limited
              to these Terms of Service.
              <br />
              <br />
              Any new features or tools which are added to the current store
              shall also be subject to the Terms of Service. You can review the
              most current version of the Terms of Service at any time on this
              page. We reserve the right to update, change or replace any part
              of these Terms of Service by posting updates and/or changes to our
              website. It is your responsibility to check this page periodically
              for changes. Your continued use of or access to the website
              following the posting of any changes constitutes acceptance of
              those changes.
              <br />
              <br />
              Our store is hosted on Shopify Inc. They provide us with the
              online e-commerce platform that allows us to sell our products and
              services to you.
              <br />
              <br />
              SECTION 1 - ONLINE STORE TERMS
              <br />
              <br />
              By agreeing to these Terms of Service, you represent that you are
              at least the age of majority in your state or province of
              residence, or that you are the age of majority in your state or
              province of residence and you have given us your consent to allow
              any of your minor dependents to use this site.
              <br />
              You may not use our products for any illegal or unauthorized
              purpose nor may you, in the use of the Service, violate any laws
              in your jurisdiction (including but not limited to copyright
              laws).
              <br />
              You must not transmit any worms or viruses or any code of a
              destructive nature.
              <br />
              A breach or violation of any of the Terms will result in an
              immediate termination of your Services.
              <br />
              <br />
              SECTION 2 - GENERAL CONDITIONS
              <br />
              <br />
              We reserve the right to refuse service to anyone for any reason at
              any time.
              <br />
              You understand that your content (not including credit card
              information), may be transferred unencrypted and involve (a)
              transmissions over various networks; and (b) changes to conform
              and adapt to technical requirements of connecting networks or
              devices. Credit card information is always encrypted during
              transfer over networks.
              <br />
              You agree not to reproduce, duplicate, copy, sell, resell or
              exploit any portion of the Service, use of the Service, or access
              to the Service or any contact on the website through which the
              service is provided, without express written permission by us.
              <br />
              The headings used in this agreement are included for convenience
              only and will not limit or otherwise affect these Terms.
              <br />
              <br />
              SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION
              <br />
              <br />
              We are not responsible if information made available on this site
              is not accurate, complete or current. The material on this site is
              provided for general information only and should not be relied
              upon or used as the sole basis for making decisions without
              consulting primary, more accurate, more complete or more timely
              sources of information. Any reliance on the material on this site
              is at your own risk.
              <br />
              This site may contain certain historical information. Historical
              information, necessarily, is not current and is provided for your
              reference only. We reserve the right to modify the contents of
              this site at any time, but we have no obligation to update any
              information on our site. You agree that it is your responsibility
              to monitor changes to our site.
              <br />
              <br />
              SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES
              <br />
              <br />
              Prices for our products are subject to change without notice.
              <br /> */}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default withContext(withRouter(Refund));

