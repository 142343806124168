import React, { useEffect, useState } from "react";
import Layout from "../layouts/MainLayout";
import { withRouter } from "react-router-dom";
import { withContext } from "../context/Context";
import { timeToDateConverter, download_file} from "../common/utils";
import Images from "../common/Images";
import Fetch from "../common/Fetch";
import Link from "../common/Link";

const { Gift, boxes } = Images;

const order_states = {
  0: "Order created",
  1: "processing",
  2: "shipped",
  3: "delivered",
  4: "returned",
};

function ViewOrder(props) {
  const [isLoading, setLoading] = useState(true);
  const [orderDetails, setOrderDetails] = useState([{ invoice: "", orders: [], order_no: null, price: "", created_at: "" }]);

  useEffect(() => {
    const { match } = props;
    const { params } = match;

    Fetch(`prod/order/${params.id}`, null, {
      sendToken: true,
      method: "get",
    }).then((res) => {
      if (res?.success) {
        setOrderDetails(res.data);
        setLoading(false);
      }
    });
  }, []);

  const [{ invoice, orders, order_no, price, created_at, state, discount }] =
    orderDetails;

  return (
    <Layout title="Order Details | Dark Sugar" loading={isLoading}>
      <div className="container cart">
        <div className="view-order">
          {" "}
          order <span>#{order_no ?? ""}</span> was placed on{" "}
          <span>{created_at}</span> and is currently on <span>{order_states[state]}</span> state
          {/* <span>{timeToDateConverter("2022-12-25T17:18:10.000000Z")}</span> */}
        </div>
        <React.Fragment>
          <div className="row mb2">
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 cart-right">
              <div className="view-head"> Order Details</div>

              <div className="cart-total">
                {orders && orders?.length
                  ? orders.map((d, i) => (
                      <div
                        className="cart-total-inner flex justify-space view-card"
                        key={i}
                      >
                        <div>
                          {" "}
                          {d.product.name}
                          <span>X {d.count}</span>
                        </div>
                        <span>£{d.price}</span>
                      </div>
                    ))
                  : null}

                {/* <div className="cart-total-inner flex justify-space view-card">
                  <div>
                    {" "}
                    ASSORTED TRUFFLES
                    <span>X 1</span>
                  </div>
                  <span>£833</span>
                </div>
                <div className="cart-total-inner flex justify-space view-card">
                  <div>
                    {" "}
                    ASSORTED TRUFFLES
                    <span>X 1</span>
                  </div>
                  <span>£833</span>
                </div>

                <div className="cart-total-inner flex justify-space view-card">
                  <div>
                    {" "}
                    ASSORTED TRUFFLES
                    <span>X 1</span>
                  </div>
                  <span>£833</span>
                </div> */}

                <div className="cart-total-inner flex justify-space">
                  Subtotal
                  <span className="orange">£{discount ? Math.abs(parseFloat(price) - parseFloat(discount))?.toFixed(2) : price}</span>
                </div>
                <div className="cart-total-inner flex justify-space">
                  Discount
                  <span className="orange">£{discount || 0}</span>
                </div>
                <div className="cart-total-inner flex justify-space">
                  Total
                  <span className="orange">£{price}</span>
                </div>
              </div>

              {/* <Link className="btn btn-checkout mt2">Download Bill</Link> */}
              {/* <button className="btn btn-checkout mt2">Download Bill</button> */}
              <button className="btn btn-checkout mt2" onClick={()=>
                download_file(invoice, `${order_no}.pdf`)
              }>
                Download Bill
              </button>
            </div>
          </div>
          <hr></hr>
        </React.Fragment>
      </div>
    </Layout>
  );
}

export default withContext(withRouter(ViewOrder));
