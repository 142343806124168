import React, { useEffect } from "react";
import PaymentForm from "../components/stripe-payment/PaymentForm";
import { withContext } from "../context/Context";
import MainLayout from "../layouts/MainLayout";

// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";

// let PUBLIC_KEY = process.env.REACT_APP_STRIPE_P_K_TEST;
// const stripePromise = loadStripe(PUBLIC_KEY);
// const secretKey =
//   "sk_test_51KFiu2SHI5DQd142WdliqIvTkEOx68tf9Ek5dlM7RrjadAV4M5F8p2BUn5NtWMpixK7tpodiwxYa3fn9zKx7KGHu00Jg5Pi1lg";

function StripePayment({
  context: {
    handlePaymentPageRedirection,
    isRedirectedFromCheckout,
    isUserLogin,
    cartID_paymentPage,
  },
}) {
  useEffect(() => {
    return () => {
      handlePaymentPageRedirection(false, null);
    };
  }, []);

  // const options = {
  //   // clientSecret: secretKey,
  //   appearance: {
  //     theme: "stripe",

  //     variables: {
  //       colorPrimary: "#0570de",
  //       colorBackground: "#ffffff",
  //       colorText: "#30313d",
  //       colorDanger: "#df1b41",
  //       fontFamily: "Ideal Sans, system-ui, sans-serif",
  //       spacingUnit: "2px",
  //       borderRadius: "4px",
  //       // See all possible variables below
  //     },
  //   },
  // };
  // const elements = stripe.elements({clientSecret, appearance});

  if (!isUserLogin) {
    return null;
  }

  return (
    <>
      <MainLayout>
        <div className="checkout ">
          <main>
            <div className="main pt3">
              {/* <Elements stripe={stripePromise} options={options}> */}
              <PaymentForm />
              {/* </Elements> */}
            </div>
          </main>
        </div>
      </MainLayout>
    </>
  );
}

export default withContext(StripePayment);
