import React, { useEffect, useState } from "react";
import { withContext } from "../context/Context";
import { withRouter } from "react-router";

import MainLayout from "../layouts/MainLayout";

function Story({location, context: { pagesData }}) {
  const [loading, setLoading] = useState(true);
  const [meta_data, set_meta_data] = useState({});


    useEffect(()=>{
        window.scrollTo(0,0);
    }, [])

    useEffect(()=>{
      let slug = location.pathname.split("/")[1];

      if(!Object.keys(meta_data).length){
        if(slug in pagesData){
          set_meta_data(pagesData[slug]);
          setLoading(false);
        }
      }
    }, [pagesData])


  return (
    <MainLayout title="Story | Dark Sugar" loading={loading}>
      <div className="terms">
        <div className="container ">
          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 terms-container">
            <h4>{meta_data?.title}</h4>
            <div className="terms-para" dangerouslySetInnerHTML={{__html: meta_data?.body}}>
              {/* <div className="rte">
                <p className="pb1">
                  Before there was chocolate, there was cocoa. Dark Sugars is
                  the story of one African woman’s journey to tell the story of
                  cocoa - from tree to tummy.
                </p>
                <p className="pb1">
                  Nyanga began her quest in Spitalfields Market with just two
                  truffles and a lot of chutzpah. Soon enough people fell in
                  love with the authentic taste of the truffles and word got
                  around about the woman who was bringing the sweet taste of
                  Africa in the form of chocolates.
                </p>
                <p className="pb1">
                  This journey then continued to the famous Borough Market,
                  where people would queue up to taste the irresistible
                  chocolate that everyone would rave about. After many exciting
                  years at Borough, Nyanga packed up her truffles and flew to
                  South America and West Africa; where she spent three years
                  researching cocoa on her family's farm. She returned feeling
                  excited to bring her experience in Ghana to the streets of
                  East London.
                </p>
                <p className="pb1">
                  In 2013, Nyanga began her next adventure of bringing her
                  handcrafted chocolates to Brick Lane. Known for it’s famous
                  curry houses, Nyanga revitalised Brick Lane by bringing the
                  authentic taste of African cocoa. People came&nbsp;to the
                  shop&nbsp;from all around the world&nbsp;just to get a little
                  taste of Ghanaian cocoa beans.
                </p>
                <p className="pb1">
                  Today you can find Dark Sugars on Brick Lane, a Dark Sugars
                  ice cream shop in Greenwich and a 4 storey flagship Cacao
                  House in Greenwich that has recently opened at the end of
                  2021, with more sites on the horizon. When you walk into Dark
                  Sugars you will be immediately transported to West Africa by
                  taking in the aroma of roasting cocoa beans, admiring the
                  African imported decor, watch mango slices swirling in
                  luscious dark chocolate, taste the sinful&nbsp;thickness of
                  Pitch Black Hot Chocolate and listen to African music that
                  will make you want to sway your hips to the sound of
                  the&nbsp;Senegalese&nbsp;<i>sabar.</i>
                </p>
                <p className="pb1">
                  At Dark Sugars it’s about the whole experience and we want to
                  bring you quality chocolate without pretension and with all
                  the passion of our rich and vibrant culture. Come on by and
                  ‘Make Yourself Happy.’&nbsp;
                </p>
              </div> */}
             
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default withContext(withRouter(Story));
