import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Fetch from "./Fetch";
import icons from "./Images";
import { geturl } from "./utils";
const {
    search,
} = icons;
export const Search = () => {
    const [searchOpen, setSearchOpen] = useState(false)
    const [searchValue, setSearchValue] = useState(false)
    const [searchList, setSearchList] = useState([])
    const wrapperRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setSearchOpen(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);


    useEffect(() => {
        if (searchValue.length > 2) {
            const delayDebounceFn = setTimeout(() => {
                onsearchSubmit(searchValue)
            }, 1000)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [searchValue]);

    
    const onsearchSubmit = (val) => {
        Fetch(`prod/search/${val}`, null, { method: 'get' }).then(res => {
            setSearchOpen(true)
            if (res?.success) {
                setSearchList(res.data)
            } else {
                setSearchList([])
            }
        })
    }
    return <div className="pl2  flex align-center header-search" ref={wrapperRef}>
        <img alt="" src={search} className="pr1"></img>
        <input type="text" placeholder="SEARCH" onChange={(e) => setSearchValue(e.target.value)} />
        <div className="header-search-dropdown">
            <ul>

                {searchOpen ?
                    searchList.length ? searchList?.map((item, key) => {
                        return <Link to={geturl(item)}><li key={key}>{item.name}</li></Link>
                    })
                        : <li>No Data Found</li>
                    : ''
                }
            </ul>
        </div>
    </div>
}