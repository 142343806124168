import React, { Component } from "react";
import { withRouter } from "react-router-dom";
// import { withContext } from "../context/MainContext";
import MainLayout from "../layouts/MainLayout";
import Fetch from "../common/Fetch";
import Auth from "../common/Auth";
import Link from "../common/Link";
import { checkItemBeforeAddingCart } from "../common/utils";
import { updateCartItemAPICount } from "../common/common-api-calls";
import RingLoader from "../components/RingLoader";
import ChangePassword from "../components/ChangePassword";
import * as queryString from "query-string";

import {
  isEmail,
  isNumeric,
  isDate,
  trim,
  isAlphanumeric,
  isEmpty,
  equals,
  isStrongPassword,
  isAlpha,
  isMobilePhone,
} from "validator";

import Images from "../common/Images";
import { withContext } from "../context/Context";

const { register, users, unlock, pearls } = Images;

const initialInfo = {
  first_name: "",
  last_name: "",
  username: "",
  email: "",
  phone: "",
  dob: "",
  password: "",
  c_password: "",
  // username_email: "",
};

const errorMsgs = {
  first_name: "Please fill first name",
  last_name: "Please fill last name",
  username: "Please enter username",
  email: "Please enter valid Email Address",
  phone: "Please enter valid Phone Number",
  dob: "Incorrect Date Format!",
  password:
    "Password must contain atleast 1 Lowercase, 1 Uppercase, 1 Special character and 1 Number",
  // username_email: "",
};

class Login extends Component {
  state = {
    isLoading: true,
    currentPage: "login",
    page: "login",
    userInfo: initialInfo,
    agree_tnc: false,

    successMsg: "",
    errorMsg: "",
    errors: {
      email: "",
      phone: "",
      dob: "",
      username: "",
      password: "",
    },

    fpView: "fp-1",
    fp_btnText: "Continue",

    redirectUrl: "/",

    btnLoader: false,

    fp_token: "",
    fp_email: "",
  };

  componentWillMount() {
    const { pathname, search } = this.props.location;
    if (search) {
      const { r } = queryString.parse(search);
      if (r) {
        this.setState({ redirectUrl: r });
        return;
      }
      return;
    }
    // let page = pathname.replace("/", "");
    // this.props.history.replace(pathname, "/");
  }

  componentDidMount() {
    const { pathname, search } = this.props.location;
    let page = pathname.replace("/", "");
    // https://darksugar.adhvikinfotech.com/passwordReset?token=4f0e5e2049c02d0f906b84f6cc3f900fb5114082154d540512e56535c130462e&email=negi.paras809@gmail.com
    if (page === "forgot-password") {
      if (search) {
        const { token, email } = queryString.parse(search);
        this.setState({ fp_token: token, fp_email: email, fpView: "fp-2" });
        this.props.history.replace(pathname, "/forgot-password");
      }
    }

    this.setState({ page, isLoading: false });
    return;
  }

  handleChange = (_evt) => {
    const { page } = this.state;
    const { name, value, type, checked } = _evt.target;

    this.setState({ errMsg: "", successMsg: "" });

    const updateState = (inpName, val, err) => {
      if (err === "undefined") {
        this.setState({ userInfo: { ...this.state.userInfo, [inpName]: val } });
      } else {
        this.setState({
          userInfo: { ...this.state.userInfo, [inpName]: val },
          errors: { ...this.state.errors, [inpName]: err },
        });
      }
    };

    if (page === "login") {
      if (type === "checkbox") {
        if (name === "agree_tnc") {
          this.setState({ [name]: checked });
        }
        return;
      }

      updateState(name, value);
      return;
    }

    _evt.preventDefault();

    const validInput = validatorFxn(name, value);

    if (name === "phone") {
      if (!validInput) return;
      if (value.length < 8) {
        updateState(name, value, errorMsgs[name]);
        return;
      }

      if (value.length > 15) {
        updateState(name, value.substring(0, value.length - 1), "");
        return;
      }
      // if (isMobilePhone(value, ["en-GB"])) {
      //   updateState(name, value, "");
      //   return;
      // }
      updateState(name, value, "");
      return;
    }

    if (name === "email") {
      if (isEmail(value)) {
        updateState(name, value, "");
        return;
      }
      updateState(name, value, errorMsgs[name]);
      return;
    }

    if (name === "dob") {
      let date = value.split("-");
      date = date.reverse();
      date = date.join("/");

      const dateFormat = isDate(date, {
        format: "DD/MM/YYYY",
        strictMode: true,
        delimiters: ["/"],
      });

      if (dateFormat) {
        updateState(name, value, "");
        return;
      }
      updateState(name, value, errorMsgs[name]);
      return;
    }

    if (validInput) {
      updateState(name, value);
      return;
    }

    return;
  };

  loginUser = () => {
    const { userInfo, agree_tnc, redirectUrl, errors } = this.state;
    const { email, password } = userInfo;
    const { updateLoginInfo, getAllInCart } = this.props.context;

    if (!email.trim().length && !password.trim().length) {
      this.setState({ errMsg: "Please fill all the details" });
      return;
    }

    if (!email.trim().length) {
      this.setState({ errMsg: "Please fill email address" });
      return;
    }

    if (!password.trim().length) {
      this.setState({ errMsg: "Password can't be empty" });
      return;
    }

    if (!agree_tnc) {
      this.setState({ errMsg: "Please agree to the terms & condition" });
      return;
    }

    this.setState({ btnLoader: true });

    return Auth.loginUser({
      email,
      password,
    })
      .then((res) => {
        if (res?.success) {
          this.setState({
            successMsg: "Successfully signed in !",
            errMsg: "",
            btnLoader: false,
          });
          localStorage.setItem("AUTH_TOKEN", res.data?.token);
          updateLoginInfo(true);
          this.updateCartItemsOnLogin(res.data?.token);
          // getAllInCart(true, false, { toggleCart: false });
          // localStorage.removeItem("temp");
          setTimeout(() => {
            this.setState({ successMsg: "" });
            this.props.history.push(redirectUrl);
          }, 3000);
          return;
        } else {
          let err = "";
          if (res?.message === "Unauthorised.") {
            err = res.data["error"];
          } else {
            err = res.message["email"];
          }
          this.setState({ errMsg: err, successMsg: "", btnLoader: false });
          return;
        }
      })
      .catch((err) => console.error(err));
  };

  onRegisterUSer = () => {
    const { updateLoginInfo } = this.props.context;
    const { userInfo, errors, redirectUrl } = this.state;
    const copyErrors = { ...errors };

    Object.keys(userInfo).forEach((d) => {
      if (!userInfo[d]?.trim().length) {
        // copyErrors[d] = `${d} can't be empty`;
        copyErrors[d] = `Requied !`;
      }
    });

    this.setState({ errors: copyErrors });

    for (let key in copyErrors) {
      if (copyErrors[key]) return;
    }

    if (userInfo.password !== userInfo.c_password) {
      this.setState({
        errMsg: "Passwords do not match",
      });
      return;
    }

    this.setState({ btnLoader: true });

    return Auth.registerUser(userInfo).then((res) => {
      if (res?.success) {
        this.setState({
          successMsg: "Your account has been created successfully !",
          errMsg: "",
          btnLoader: false,
        });

        localStorage.setItem("AUTH_TOKEN", res.data?.token);
        updateLoginInfo(true);
        // localStorage.removeItem("temp");
        setTimeout(() => {
          this.setState({ successMsg: "" });
          this.props.history.push(redirectUrl);
        }, 3000);

        return;

        // this.setState({
        //   btnLoader: false,
        //   userInfo: initialInfo,
        //   successMsg:
        //     "You have successfully created an account on Dark Sugar. Please check your email to activate the account",
        //   errors: {},
        //   errMsg: "",
        // });

        // setTimeout(() => {
        //   this.setState({ successMsg: "", page: "login" });
        //   return this.props.history.push("/login");
        // });

        // return;
      }

      this.setState({
        errMsg: "",
        btnLoader: false,
        successMsg: "",
        errors: { ...errors, ...res?.message },
      });
      return;
    });
  };

  //////////////////UPDATE CART ITEMS ON LOGIN///////////////////////////////

  updateCartItemsOnLogin = async (token) => {
    const { getAllInCart } = this.props.context;
    let tempCartItems = Auth.handleLocalStorage("temp", null, "get");
    let copyCartItems = [...tempCartItems];

    for (let index in tempCartItems) {
      let res;
      let cartRes = await Fetch("prod/cart", null, {
        method: "get",
        sendToken: true,
        token: token ? token : false,
      });
      if (cartRes?.data?.length) {
        let checkItemsStatus = checkItemBeforeAddingCart(
          tempCartItems[Number(index)].params,
          cartRes?.data
        );
        const { update, cartID } = checkItemsStatus;
        if (update) {
          res = await updateCartItemAPICount(cartID, "update");
        }else{
          res = await Fetch(
            "prod/cart/add",
            { ...tempCartItems[Number(index)].params },
            { sendToken: true, token }
          );
        }
      } else {
        res = await Fetch(
          "prod/cart/add",
          { ...tempCartItems[Number(index)].params },
          { sendToken: true, token }
        );
      }

      if (res?.success) {
        if (Number(index) === tempCartItems.length - 1) {
          localStorage.removeItem("temp");
          getAllInCart(true, token, { toggleCart: false });
        }
      } else {
        localStorage.removeItem("temp");
      }
    }
  };

  /////////////////////FORGOT PASSWORD PAGE FUNCTIONS/////////////////////////////
  /**
   *
   * @param {String} btnText
   * @returns
   */
  onForgotPassword = (btnText = "Continue") => {
    const { email } = this.state.userInfo;
    if (btnText.toLocaleLowerCase() === "back to login") {
      this.setState({
        page: "login",
        email: "",
        fp_btnText: "Continue",
        successMsg: "",
      });

      this.props.router.push("/login");
      return;
    }

    if (isEmpty(email) || !trim(email)) {
      this.setState({ errMsg: "Please enter valid email address" });
      return null;
    }

    this.setState({ btnLoader: true });
    Fetch("auth/password/forgot", { email }).then((res) => {
      this.setState({ btnLoader: false });
      if (res?.success) {
        this.setState({
          successMsg: res?.message,
          errMsg: "",
          fp_btnText: "Back to Login",
          email: "",
        });
        return;
      }

      this.setState({
        successMsg: "",
        errMsg: "something went wrong, please try after some time",
      });
    });
  };

  render() {
    const {
      page,
      userInfo,
      errors,
      isLoading,
      errMsg,
      successMsg,
      agree_tnc,
      fpView,
      fp_btnText,
      redirectUrl,
      btnLoader,
    } = this.state;

    const {
      first_name,
      last_name,
      username,
      email,
      phone,
      dob,
      password,
      c_password,
    } = userInfo;

    return (
      <div className="mob-border">
        <MainLayout loading={isLoading} title="Login | Signup">
          <section className="login">
            <div className="container">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="login-left">
                  <img src={register} alt=""></img>
                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="login-right">
                  {page === "login" ? (
                    <React.Fragment>
                      <div className="login-head pb4">My ACCOUNT</div>
                      <div className="login-input">
                        <div className="flex mb2">
                          {" "}
                          <img src={users} alt="img-1" />
                          <input
                            type="text"
                            className={errors?.[email] ? "red-border" : ""}
                            placeholder="User name or Email address"
                            name="email"
                            value={email}
                            onChange={this.handleChange}
                          />
                        </div>

                        <div className="flex mb2">
                          {" "}
                          <img src={unlock} alt="img-1" />{" "}
                          <input
                            type="password"
                            placeholder="Password"
                            name="password"
                            className={errors?.[password] ? "red-border" : ""}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="login-agree pb3">
                        <input
                          type="checkbox"
                          id="agree"
                          name="agree_tnc"
                          checked={agree_tnc ? true : false}
                          onChange={this.handleChange}
                        />
                        <label htmlFor="agree">
                          {/* <span></span>I agree with the <a href="">T&C</a>. */}
                          <span></span>I agree with the{" "}
                          <Link
                            styles={{ textDecoration: "underline" }}
                            href="/terms-and-conditions"
                          >
                            T&C
                          </Link>
                          .
                        </label>
                      </div>

                      {errMsg || successMsg ? (
                        <div className="errpage">
                          <p className={successMsg ? "successMsg" : "errMsg"}>
                            {errMsg || successMsg}
                          </p>
                        </div>
                      ) : null}

                      <div className="flex login-right-btn mb1">
                        <button
                          onClick={this.loginUser}
                          className="login-signup-btn orange"
                        >
                          {btnLoader ? <RingLoader /> : "sign in"}
                        </button>

                        <button
                          className="login-signup-btn "
                          onClick={() => {
                            const { history } = this.props;
                            history.push("/register");
                            this.setState({
                              page: "register",
                              errMsg: "",
                              successMsg: "",
                              errors: {},
                            });
                            return;
                          }}
                        >
                          {" "}
                          sign up
                        </button>
                      </div>

                      <div className="login-forget">
                        <a
                          onClick={() => {
                            this.setState({
                              page: "forgot-password",
                              view: "fp-1",
                            });
                            this.props.history.push("/forgot-password");
                            return;
                          }}
                        >
                          Forgot your password?
                        </a>
                      </div>
                    </React.Fragment>
                  ) : page === "register" ? (
                    <React.Fragment>
                      <div className="login-head pb4">create ACCOUNT</div>
                      <div className="login-input">
                        <div className="login-heading">
                          {" "}
                          Personal Information{" "}
                        </div>

                        <div className="register flex mb2">
                          <div className={"register-inner"}>
                            <input
                              type="text"
                              placeholder="Your first name"
                              className={
                                errors?.["first_name"] ? "red-border" : ""
                              }
                              name="first_name"
                              value={first_name}
                              onChange={this.handleChange}
                            />
                            {errors["first_name"] ? (
                              <div style={{ padding: "10px 0 10px 0" }}>
                                <p
                                  style={{ color: "red", fontSize: "14px" }}
                                  className="errMsg"
                                >
                                  {errors["first_name"]}
                                </p>
                              </div>
                            ) : null}
                          </div>
                          <div className={"register-inner"}>
                            <input
                              type="text"
                              placeholder="Your last name"
                              className={
                                errors?.["last_name"] ? "red-border" : ""
                              }
                              name="last_name"
                              value={last_name}
                              onChange={this.handleChange}
                            />
                            {errors["last_name"] ? (
                              <div style={{ padding: "10px 0 10px 0" }}>
                                <p
                                  style={{ color: "red", fontSize: "14px" }}
                                  className="errMsg"
                                >
                                  {errors["last_name"]}
                                </p>
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="register flex mb2">
                          <div className={"register-inner"}>
                            <input
                              type="text"
                              placeholder="Username"
                              className={
                                errors?.["username"] ? "red-border" : ""
                              }
                              name="username"
                              value={username}
                              onChange={this.handleChange}
                            />
                            {errors["username"] ? (
                              <div style={{ padding: "10px 0 10px 0" }}>
                                <p
                                  style={{ color: "red", fontSize: "14px" }}
                                  className="errMsg"
                                >
                                  {errors["username"]}
                                </p>
                              </div>
                            ) : null}
                          </div>

                          <div className="register-inner">
                            <input
                              type="date"
                              placeholder="07/09/2021"
                              name="dob"
                              requiredPattern="\d{2}-\d{2}-\d{4}"
                              value={dob}
                              onChange={this.handleChange}
                              className={errors?.["dob"] ? "red-border" : ""}
                            />

                            {errors["dob"] ? (
                              <div style={{ padding: "10px 0 10px 0" }}>
                                <p
                                  style={{ color: "red", fontSize: "14px" }}
                                  className="errMsg"
                                >
                                  {errors["dob"]}
                                </p>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <div className="login-input pb3">
                        <div className="login-heading">Account Information</div>
                        <input
                          type="text"
                          placeholder="Email address"
                          className={
                            errors?.["email"] ? "red-border mb2" : "mb2"
                          }
                          name="email"
                          value={email}
                          onChange={this.handleChange}
                        />

                        {errors["email"] ? (
                          <div
                            style={{
                              padding: "0 0 10px 0",
                              marginTop: " -1rem",
                            }}
                          >
                            <p
                              style={{ color: "red", fontSize: "14px" }}
                              className="errMsg"
                            >
                              {errors["email"]}
                            </p>
                          </div>
                        ) : null}

                        <input
                          type="text"
                          placeholder="Phone number"
                          className={
                            errors?.["phone"] ? "red-border mb2" : "mb2"
                          }
                          name="phone"
                          value={phone}
                          onChange={this.handleChange}
                        />

                        {errors["phone"] ? (
                          <div
                            style={{
                              padding: "0 0 10px 0",
                              marginTop: " -1rem",
                            }}
                          >
                            <p
                              style={{ color: "red", fontSize: "14px" }}
                              className="errMsg"
                            >
                              {errors["phone"]}
                            </p>
                          </div>
                        ) : null}

                        <input
                          type="password"
                          placeholder="Password"
                          className={
                            errors["password"] ? "red-border mb2" : "mb2"
                          }
                          name="password"
                          value={password}
                          onChange={this.handleChange}
                        />

                        {errors["password"] ? (
                          <div
                            style={{
                              padding: "0 0 10px 0",
                              marginTop: " -1rem",
                            }}
                          >
                            <p
                              style={{ color: "red", fontSize: "14px" }}
                              className="errMsg"
                            >
                              {errors["password"]}
                            </p>
                          </div>
                        ) : null}

                        <input
                          type="text"
                          placeholder="Confirm Password"
                          className={errors["c_password"] ? "red-border" : ""}
                          name="c_password"
                          value={c_password}
                          onChange={this.handleChange}
                        />

                        {errors["c_password"] ? (
                          <div
                            style={{
                              padding: "0 0 10px 0",
                              marginTop: "10px",
                            }}
                          >
                            <p
                              style={{ color: "red", fontSize: "14px" }}
                              className="errMsg"
                            >
                              {errors["c_password"]}
                            </p>
                          </div>
                        ) : null}
                      </div>

                      {errMsg || successMsg ? (
                        <div
                          className="errpage p0"
                          style={{ padding: "0 0 10px 0", marginTop: " -1rem" }}
                        >
                          <p className={successMsg ? "successMsg" : "errMsg"}>
                            {errMsg || successMsg}
                          </p>
                        </div>
                      ) : null}

                      <div className="flex  mt2 ">
                        <button
                          onClick={this.onRegisterUSer}
                          // className="orange"
                          // className="login-signup-btn"
                          className="login-signup-btn orange"
                        >
                          {btnLoader ? <RingLoader /> : "sign up here"}
                        </button>

                        {/* <button
                          onClick={() => {
                            const { history } = this.props;
                            history.push("/login");
                            this.setState({
                              page: "login",
                              errMsg: "",
                              successMsg: "",
                              errors: {},
                            });
                            window.scrollTo(0,0);
                            return;
                          }}
                          className="orange"
                        >
                          Log In
                        </button> */}
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {fpView === "fp-2" ? (
                        <ChangePassword
                          isLoggedIn={false}
                          email={this.state.fp_email}
                          token={this.state.fp_token}
                          updatePage={() => this.setState({ page: "/login" })}
                        />
                      ) : (
                        <React.Fragment>
                          <div className="login-head pb4">FORGOT PASSWORD</div>
                          <div className="login-input">
                            <div className="flex mb2">
                              {" "}
                              <img src={users} alt="img-1" />
                              <input
                                type="text"
                                className={errors?.[email] ? "red-border" : ""}
                                placeholder="Email address"
                                name="email"
                                value={email}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          {errMsg || successMsg ? (
                            <div className="errpage">
                              <p
                                className={successMsg ? "successMsg" : "errMsg"}
                              >
                                {errMsg || successMsg}
                              </p>
                            </div>
                          ) : null}

                          <div
                            className={`flex login-right-btn ${
                              errMsg || successMsg ? "mt2" : "mt4"
                            }mb1`}
                          >
                            <button
                              onClick={()=>this.onForgotPassword(fp_btnText)}
                              className="login-signup-btn"
                            >
                              {btnLoader ? <RingLoader /> : fp_btnText}
                            </button>
                          </div>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                </div>
              </div>
              <div className="col-sm-12 center desk-none mt3">
                <img src={pearls}></img>
              </div>
            </div>
          </section>
        </MainLayout>
      </div>
    );
  }
}

export default withContext(withRouter(Login));

function validatorFxn(name, str) {
  let isValid = false;
  // const numbersOnly = /\d$/.test(str);
  const numbersOnly = isNumeric(str);
  const nameFormat = /^[a-zA-Z\s]+$/.test(str);
  const emptyStr = isEmpty(str);

  switch (name) {
    case "first_name":
      if (emptyStr || (nameFormat && trim(str).length < 21)) {
        isValid = true;
      }
      break;

    case "last_name":
      if (emptyStr || (nameFormat && trim(str).length < 21)) {
        isValid = true;
      }
      break;

    case "username":
      if (emptyStr || (isAlphanumeric(str) && trim(str).length < 15)) {
        isValid = true;
      }
      break;

    case "phone":
      if (emptyStr || numbersOnly) {
        isValid = true;
      }
      break;

    case "password":
      // if (isStrongPassword(str)) {
      isValid = true;
      // }
      break;

    case "c_password":
      // if (isStrongPassword(str)) {
      isValid = true;
      // }
      break;

    default:
      break;
  }

  return isValid;
}
