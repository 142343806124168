import React, { useState, useEffect } from "react";
import icons from "../common/Images";
import Link from "../common/Link";

import { mailChimpURL } from "../common/Urls";

import MailchimpSubscribe from "react-mailchimp-subscribe";
import { withContext } from "../context/Context";

const {
  Logo,
  fb,
  insta,
  logo_ice,
  phone,
  message,
  plays,
  location,
  hour,
} = icons;

function Footer({ context }) {
  const [email, setEmail] = useState("");
  const [metaData, setMetaData] = useState({});

  useEffect(() => {
    let metaData = context.footerData;
    setMetaData(metaData);
  }, [context.footerData]);

  if (!Object.values(metaData).length) {
    return null;
  }

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-inner">
          <div className="row flex flex-wrap">
            <div className="col-xl-4  col-lg-4 col-md-4 col-sm-12 ">
              <div className="footer-first footer-inputs">

                <MailchimpSubscribe
                  url={mailChimpURL}
                  render={({ subscribe, status, message }) => (
                    <>
                      <div className="mb1 mt1">
                      {status === "sending" && (
                        <div style={{ color: "blue" }}>sending...</div>
                      )}
                      {status === "error" && (
                        <div
                          style={{ color: "red" }}
                          dangerouslySetInnerHTML={{ __html: message }}
                        />
                      )}
                      {status === "success" && (
                        <div style={{ color: "green" }}>Subscribed !</div>
                      )}
                      </div>

                      <div className="mb2">
                      <MailchimpSubscribe url={mailChimpURL}/>
                      </div>
                    </>
                  )}
                />

                <div className="footer-address">
                  <ul>
                    <li>
                      <a target="_blank" href={`tel:${metaData.phone_number}`}>
                        <img src={phone} alt="insta"></img>
                        {metaData.phone_number}
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href={`mailto:${metaData.email}`}>
                        <img src={message} alt="insta"></img>
                        {metaData.email}
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href={metaData.facebook}>
                        <img src={fb} alt="insta"></img>
                        Facebook
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href={metaData.instagram}>
                        <img src={insta} alt="insta"></img>
                        Instagram
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href={metaData.tiktok}>
                        <img src={plays} alt="insta"></img>
                        Tiktok
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-5  col-lg-5 col-md-5 col-sm-12 footer-leftBorder footer-padding footer-center">
              <Link
                href="/"
                style={{ position: "relative", zIndex: "5" }}
                className="center width-100 inline-block mb4"
              >
                <img src={Logo} alt="logo"></img>
              </Link>
              <div className="row ">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 footer-text">
                  <div className="">
                    <h4>{metaData.address1_title}</h4>
                    <div className="flex">
                      <img src={location} alt="insta"></img>
                      <div>
                        {metaData.address1_name}
                        <span>
                          {metaData.address1_city}, {metaData.address1_zipcode}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    <img src={hour} alt="insta"></img>
                    <div>
                      <div className="mob-display-none">
                        {metaData.address1_hours_title}
                      </div>
                      <span>{metaData.address1_hours}</span>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 footer-text">
                  <div className="">
                    <h4>{metaData.address2_title}</h4>
                    <div className="flex">
                      <img src={location} alt="insta"></img>
                      <div>
                        {metaData.address2_name}
                        <span>
                          {metaData.address2_city}, {metaData.address2_zipcode}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    <img src={hour} alt="insta"></img>
                    <div>
                      <div className="mob-display-none">
                        {metaData.address2_hours_title}
                      </div>
                      <span>{metaData.address2_hours}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3  col-lg-3 col-md-3 col-sm-12 footer-padding footer-center">
              <img src={logo_ice} alt="logo-ice" className="mb4"></img>
              <div className="footer-text mb0">
                <div className="">
                  <h4>{metaData.address_title}</h4>
                  <div className="flex">
                    <img src={location} alt="insta"></img>
                    <div>
                      {metaData.address_name}
                      <span>
                        {metaData.address_city}, {metaData.address_zipcode}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <img src={hour} alt="insta"></img>
                  <div>
                    <div className="mob-display-none">
                      {metaData.address_hours_title}
                    </div>
                    <span>{metaData.address_hours}</span>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className="footer-term center pt3">
          <Link href="/terms-and-conditions">Terms & Conditions</Link> -{" "}
          <Link href="/privacy-policy">Privacy Policy</Link>
        </div>
      </div>
    </footer>
  );
}

export default withContext(Footer);
