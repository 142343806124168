import React, { Component } from "react";
import { withRouter } from "react-router";
import MainLayout from "../layouts/MainLayout";
import TextArea from "../components/TextArea";
import {
  addSubtractFxn,
  priceCalculator,
  checkItemBeforeAddingCart,
} from "../common/utils";
// import Select from "../components/form-elements/Select";
import Select from "react-dropdown-select";
import Auth from "../common/Auth";
import icons from "../common/Images";
import Filters from "../components/Filters";
import Fetch from "../common/Fetch";
import { withContext } from "../context/Context";
import { updateCartItemAPICount } from "../common/common-api-calls";
import Popup from "../components/popups/PriceAddWarning";
import ExpressDileveryMsg from "../components/common-comps/ExpressDileveryMsg";

const { Basket } = icons;

// const PageWrapper = (Comp)=>(props)=>{
//   const [pageRoute, setPageRoute ]= useState(null);

//   useEffect(()=>{
//     console.log({props})
//   }, [props]);

//   if(pageRoute) return null;

//   return <Comp {...props}/>
// }

class PackedProdCustomize extends Component {
  state = {
    isLoading: true,
    prodID: "",
    pageTitle: "",
    data: {},
    totalUnits: 100,
    basePrice: 0,
    units: 1,
    amount: 0,

    cartDetails: {
      purchaseUnits: 0,
      itemPrice: 0,
      box_id: 1,
      flavors: null,
      items: [],
      message: "",
      //tags: ""
      gift_wrap: [],
    },

    selectedFlavor: [],

    tags: "",
    types: "",

    cartBtnText: "Add To Basket",

    selectedTagIds: [],
    selectedTypeIds: [],

    priceAddWarningPopup: { show: false, shownOnce: 0 },
    pageSlug: "",
  };

  componentDidMount() {
    this.getPageRoutes();
  }

  componentDidUpdate(nextProps) {
    const { id3 } = nextProps.match.params;
    if (!this.state.isLoading && id3 && this.state.pageSlug) {
      console.log({ id3 });
        if (id3 !== this.state.pageSlug) {
        this.getPageRoutes();
      }
    }
  }

  getPageRoutes = () => {
    const { id3 } = this.props.match.params;
    const pageTitle = id3
      ?.replaceAll("_", " ")
      ?.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
    this.setState({ pageTitle, pageSlug: id3 });
    this.initialFetch(id3);
  };

  initialFetch = (slug) => {
    this.setState({ isLoading: true });

    Fetch(`prod/productbyslug/${slug}`, null, { method: "get" })
      .then((res) => {
        if (res?.success) {
          const totalUnits = parseInt(res.data[0].unit);
          const [{ flavors, sizes, price }] = res.data;

          const basePrice =
            flavors && flavors.length
              ? parseFloat(flavors[0]?.price)
              : sizes && sizes.length
              ? parseFloat(sizes[0]?.price)
              : parseFloat(+price);

          let flavoursArr =
            flavors?.length &&
            flavors.map((d) => {
              return { ...d, value: d.id, label: d.name };
            });

          let selectedFlavor =
            flavoursArr.length && flavoursArr.filter((d, i) => i === 0);
          let flavor =
            selectedFlavor && selectedFlavor[0].id
              ? selectedFlavor[0].id
              : null;

          this.setState({
            data: res.data[0],
            isLoading: false,
            totalUnits,
            basePrice,

            units: 1,
            amount: basePrice,

            cartDetails: {
              ...this.state.cartDetails,
              box_id: sizes[0]?.id,
              category_id: 1,
              purchaseUnits: 1,
              itemPrice: basePrice,
              flavors: flavor,
            },
            selectedFlavor,
          });
        }
      })
      .catch((err) => console.error(err));
  };

  chooseBoxSize = (obj) => {
    // const itemPrice = priceCalculator(parseInt(obj.price), purchaseUnits);
    this.setState({
      basePrice: obj.price,
      units: 1,
      amount: parseFloat(obj.price),
      cartDetails: {
        ...this.state.cartDetails,
        box_id: obj.id,
        purchaseUnits: 1,
        itemPrice: parseFloat(obj.price),
      },
    });
  };

  chooseVarient = (value) => {
    this.setState({
      basePrice: value[0].price,
      units: 1,
      amount: parseFloat(value[0].price),
      cartDetails: {
        ...this.state.cartDetails,
        flavors: value[0].id,
        purchaseUnits: 1,
        itemPrice: parseFloat(value[0].price),
      },
      selectedFlavor: value,
    });
  };

  updateMessage = (message) => {
    this.setState({
      cartDetails: { ...this.state.cartDetails, message },
    });
  };

  updateTagAndTypeIds = (arr, identifier) => {
    if (identifier === "tags") {
      this.setState({ selectedTagIds: arr });
      return;
    }
    this.setState({ selectedTypeIds: arr });
    return;
  };

  handleProdQuantity = (type, unit) => {
    const { basePrice, totalUnits } = this.state;
    let purchaseUnits = addSubtractFxn(type, unit, totalUnits);
    let itemPrice = priceCalculator(basePrice, purchaseUnits);
    this.setState({
      units: purchaseUnits,
      amount: itemPrice,
      cartDetails: {
        ...this.state.cartDetails,
        purchaseUnits: purchaseUnits,
        itemPrice,
      },
    });
  };

  onAddItemsToCart = (inStock) => {
    const { data, prodID, btnLoader, cartBtnText, cartDetails, basePrice } =
      this.state;
    if (!inStock) return;
    const { isUserLogin, cart } = this.props.context;
    if (btnLoader) return;
    if (cartBtnText === "Go To Basket") {
      this.props.history.push("/cart");
      return;
    }
    const { message, itemPrice } = cartDetails;
    const tempParams = {
      boxes: data.sizes?.length
        ? [
            {
              box_id: cartDetails?.box_id,
              box_count: cartDetails?.purchaseUnits,
            },
          ]
        : [],
      counts: cartDetails?.purchaseUnits,
      flavour_id: data?.flavors.length ? cartDetails?.flavors : null,
      product_id: data?.id,
      category_id: data?.category?.id,
      gifts: [],
      tags: [],
      types: [],
      messages: [{ message }],
      // price: message?.length ? 1.5 + itemPrice : itemPrice,
      price: itemPrice,
      size_id: data.sizes?.length ? cartDetails?.box_id : "",
      items: [],
    };

    if (!isUserLogin) {
      let localData = Auth.handleLocalStorage("temp", null, "get");
      let processedData = checkItemBeforeAddingCart(
        tempParams,
        localData,
        true
      );

      const { updateIndex, finalParams } = processedData;
      let cart_id = Math.floor(Math.random() * 1000 + 1);

      if (updateIndex !== null) {
        localData[updateIndex].count = localData[updateIndex].count + 1;
        localData["params"] = { ...finalParams };
      } else {
        const beforeFinalObj = {
          boxes: data.sizes?.length
            ? [
                {
                  box: [{ id: cartDetails?.box_id }],
                  count: cartDetails?.purchaseUnits,
                },
              ]
            : [],
          basePrice,

          id: cart_id,
          cart_id,
          parent_category: data?.parent_category,
          product: {
            name: data?.name,
            photo: data?.photo,
            note: data?.note,
            id: finalParams.product_id,
            description: data?.description,
          },
          category: data?.category,
          count: cartDetails?.purchaseUnits,
          items: [],
          // price: message?.length ? 1.5 + itemPrice : itemPrice,
          price: itemPrice,
          size_id: cartDetails?.box_id ? cartDetails?.box_id : null,
          flavors: cartDetails?.flavors ? cartDetails?.flavors : null,
          params: { ...finalParams },
        };
        localData.push(beforeFinalObj);
      }

      Auth.handleLocalStorage("temp", localData, "set");
      this.setState({ cartBtnText: "Go To Basket" });
      window.handleToastMsg(true, "Item Added Successfully");
      setTimeout(() => {
        window.handleToastMsg(false, "");
      }, 2000);
      this.props.context.getAllInCart(false);
      return;
    }

    this.setState({ btnLoader: true });

    let checkItemsStatus = checkItemBeforeAddingCart(tempParams, cart);
    const { update, finalParams, cartID } = checkItemsStatus;

    if (update) {
      updateCartItemAPICount(cartID, "update", prodID)
        .then((res) => {
          if (res?.success) {
            this.setState({ btnLoader: false, cartBtnText: "Go To Basket" });
            this.props.context.getAllInCart(true);
            window.handleToastMsg(true, res.message);

            setTimeout(() => {
              window.handleToastMsg(false, "");
            }, 3000);
            return;
          }

          this.setState({ btnLoader: false, cartBtnText: "Add to Basket" });
          window.handleToastMsg(true, "Something went wrong");

          setTimeout(() => {
            window.handleToastMsg(false, "");
          }, 3000);
          return;
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }

    return Fetch("prod/cart/add", { ...finalParams }, { sendToken: true }).then(
      (res) => {
        if (res.success) {
          this.setState({ btnLoader: false, cartBtnText: "Go To Basket" });
          this.props.context.getAllInCart(true);
          window.handleToastMsg(true, res.message);

          setTimeout(() => {
            window.handleToastMsg(false, "");
          }, 3000);
          return;
        }

        this.setState({ btnLoader: false, cartBtnText: "Add to Basket" });
        window.handleToastMsg(true, "Something went wrong");

        setTimeout(() => {
          window.handleToastMsg(false, "");
        }, 3000);
        return;
      }
    );
  };
  // http://localhost:3001/account/orders/77

  render() {
    const {
      isLoading,
      pageTitle,
      data,
      units,
      basePrice,
      cartDetails,
      cartBtnText,
      btnLoader,
      selectedFlavor,
      priceAddWarningPopup,
    } = this.state;

    // console.log({priceAddWarningPopup})

    const { box_id } = cartDetails;

    const galleryImages = data?.galleryimages
      ? Object.values(data?.galleryimages)?.filter((d, i) => i !== 0 && d)
      : [];

    return (
      <MainLayout
        loading={isLoading}
        title={pageTitle.replaceAll("-", " ") + " | Dark Sugar"}
      >
        <div className="truffle">
          <section className="container ">
            <div className="sp-top">
              <div className="sp-top__left col-xl-7 col-lg-7 col-md-7 col-sm-12">
                <div className="sp-top__left-img">
                  <img src={data.photo} alt="" />
                </div>

                <div className="flex sp-top__inner packed-img">
                  {galleryImages?.length
                    ? galleryImages?.map((d, i) => (
                        <div>
                          <img key={i} src={d} alt="" />
                        </div>
                      ))
                    : null}
                </div>
              </div>
              {/* Gift Voucher */}

              <div className="sp-top__right col-xl-5 col-lg-5 col-md-5 col-sm-12">
                <h3 className="mb1">
                  {" "}
                  {data?.name}
                  {/* SIGNATURE 1 · <span>TRUFFLES</span>{" "} */}
                </h3>

                <div className="sp-top__content">
                  <div className="mb1">
                    <p
                      className="mb1"
                      dangerouslySetInnerHTML={{ __html: data.description }}
                    ></p>

                    <hr />
                  </div>

                  <div
                    className="mb1 chilli-para"
                    dangerouslySetInnerHTML={{ __html: data.note }}
                  ></div>

                  <div className="chilli-main">
                    <div className="sp-unit-price">
                      {data.flavors && data.flavors.length ? (
                        <div className="sp-unit-price__price chilli-price-end packed-width mb1">
                          <div className="sp-unit-price__head">
                            <div className="chilli-head">Variants</div>
                          </div>

                          <div className="custom-select amount">
                            <Select
                              className=""
                              multi={false}
                              searchable={true}
                              values={selectedFlavor}
                              options={data.flavors.map((d) => {
                                return {
                                  ...d,
                                  value: d.id,
                                  label: d.name,
                                };
                              })}
                              autoFocus={false}
                              onChange={this.chooseVarient}
                              placeholder="Select varients"
                            />
                          </div>
                        </div>
                      ) : null}

                      {data.sizes?.length ? (
                        <div className="sp-unit-price__price chilli-price-end packed-width">
                          <div className="sp-unit-price__head">
                            <div className="chilli-head">Size</div>
                          </div>

                          <div className="amount flex align-center">
                            {data?.sizes?.length
                              ? data.sizes.map((d, i) => (
                                  <span
                                    key={i}
                                    className={
                                      box_id === d.id
                                        ? "active pointer"
                                        : "pointer"
                                    }
                                    onClick={() => this.chooseBoxSize(d)}
                                  >
                                    {d.size}
                                    {i === data?.sizes?.length - 1 ? null : (
                                      <div className="gift-voucher-dots"></div>
                                    )}
                                  </span>
                                ))
                              : null}
                          </div>
                        </div>
                      ) : null}
                    </div>

                    {data.tags?.length || data.types?.length ? (
                      <div className="truffle-listing packed-listing">
                        <Filters
                          showTypeFilters={false}
                          tags={data?.tags}
                          types={data?.types}
                          getTagsAndTypes={this.updateTagsAndTypes}
                        />
                      </div>
                    ) : null}

                    <div className="flex justify-space">
                      <div className="sp-unit-price__units chilli-price-end  packed-start">
                        <div className="sp-unit-price__head">
                          <div className="chilli-head">UNITS</div>
                        </div>

                        <div className="qty">
                          <button
                            className="btn btn-plus-minus"
                            onClick={() =>
                              this.handleProdQuantity("inc", units)
                            }
                          >
                            +
                          </button>
                          <span>{units}</span>
                          <button
                            className="btn btn-plus-minus"
                            onClick={() =>
                              this.handleProdQuantity("dec", units)
                            }
                          >
                            -
                          </button>
                        </div>
                      </div>
                      <div className="sp-unit-price__units chilli-price-unit packed-end">
                        <div className="sp-unit-price__head">
                          <div className="chilli-head">PRICE</div>
                        </div>

                        <div className="packed-head">
                          &#163;{parseFloat(basePrice)?.toFixed(2)}
                        </div>
                      </div>
                    </div>

                    <div className="sp-message">
                      <TextArea
                        tilte="PERSONALISED MESSAGE"
                        // stopTyping={!priceAddWarningPopup.shownOnce}
                        stopTyping={false}
                        beforeOnChange={() => {
                          if (!priceAddWarningPopup.shownOnce) {
                            this.setState({
                              priceAddWarningPopup: {
                                ...priceAddWarningPopup,
                                show: true,
                              },
                            });
                          }
                        }}
                        updateParentFxn={this.updateMessage}
                      />

                      <button
                        className={`add-btn flex align-center mt2 ${
                          !data.is_stock
                            ? "product-inactive"
                            : units
                            ? "product-active"
                            : "product-inactive"
                        }`}
                        style={{ position: "relative", minWidth: "20rem" }}
                        disabled={!units}
                        onClick={() => this.onAddItemsToCart(data.is_stock)}
                      >
                        <img src={Basket} alt="" />{" "}
                        {btnLoader
                          ? "Loading..."
                          : data.is_stock // <RingLoader/>
                          ? cartBtnText
                          : "Out of stock"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ExpressDileveryMsg/>

            <div className="col-xl-12 col-lg-12 col-md-12 home-two flex flex-wrap">
              {data?.rightimage && (
                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 chilli-img">
                  <img src={data?.rightimage} alt="" />
                </div>
              )}

              {data?.right_text_description && (
                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 home-end home-two-right">
                  <div className="home-two-head"> {data?.right_text_title}</div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: data?.right_text_description,
                    }}
                  ></p>
                </div>
              )}
            </div>

            <div className="col-xl-12 col-lg-12 col-md-12 home-two flex flex-wrap">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12  order-2">
                {data?.left_text_description && (
                  <div className="home-two-left home-end">
                    <div className="home-two-head ">
                      {" "}
                      {data?.left_text_title}
                    </div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: data?.left_text_description,
                      }}
                    ></p>
                  </div>
                )}
              </div>

              {data?.leftimage && (
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 chilli-img chilli-img-two">
                  <img src={data?.leftimage} alt="" />
                </div>
              )}
            </div>

            {data?.contentimage && (
              <div className="chilli-img chilli-img-two">
                <img src={data?.contentimage} alt=""></img>
              </div>
            )}
          </section>
        </div>

        <Popup
          {...priceAddWarningPopup}
          onClose={() => {
            this.setState({
              priceAddWarningPopup: {
                ...priceAddWarningPopup,
                shownOnce: 1,
                show: false,
              },
            });
          }}
        />
      </MainLayout>
    );
  }
}

export default withContext(withRouter(PackedProdCustomize));
