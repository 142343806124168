import axios from "axios";
import baseUrl from "../common/Urls";
import Auth from "./Auth";

export default async function Fetch(
  endPoint,
  extraParams,
  options,
  callBack,
  url
) {
  const commonParams = {};
  const authToken = localStorage.getItem("AUTH_TOKEN");
  const inFormData = options?.inFormData ?? false;
  const method = options?.method ?? "post";
  const sendToken = options?.sendToken ?? false;
  const headerParams = options?.headerParams ?? {};
  const token = options?.token ? options?.token : authToken;
  const sendParamWithDelete = options?.sendParamWithDelete ?? false;

  const parameters = { ...commonParams, ...extraParams };

  function headers(isToken, token) {
    let headers = {
      Accept: "application/json",
    };
    if (isToken) {
      headers["Authorization"] = "Bearer " + token;
    }
    if(sendParamWithDelete) {
      delete headers["Accept"];
    }

    if (headerParams) {
      headers = { ...headers, ...headerParams };
    }
    return headers;
  }

  function convertToForm() {
    let data = new FormData();
    for (let name in parameters) {
      data.append(name, parameters[name]);
    }
    return data;
  }

  return axios[method ? method : "post"](
    url ? url : baseUrl + endPoint,
    inFormData
      ? convertToForm()
      : method === "delete" && sendParamWithDelete
      ? {
          headers: headers(sendToken, token),
          data: {
            ...parameters,
          },
        }
      : method === "get" || (!sendParamWithDelete && method === "delete")
      ? { headers: headers(sendToken, token) }
      : parameters,

    { headers: headers(sendToken, token) }
  )
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      if (err?.response?.status === 422) {
        return err?.response?.data;
      }
      if (err?.response?.status === 401) {
        window.handleToastMsg(
          true,
          "Session expired"
        );
        Auth.logoutUser();
        return;

        
        if (authToken) {
          Auth.refreshToken().then((res) => {
            if (typeof res === "object" && !res.token) {
              window.handleToastMsg(
                true,
                "Something went wrong, please try after some time"
              );
              return { success: false };
            }

            Fetch(endPoint, parameters, {
              ...options,
              sendToken: true,
              token: res,
            });
          });

          return;
        }
        return err?.response;
      }

      console.error(err);
      return Promise.reject(err);
    });
}
