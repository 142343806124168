import { logDOM } from "@testing-library/react";
import React, { useState, useEffect } from "react";

export default function Filters({
  showTypeFilters,
  tags,
  types,

  getTagsAndTypes,
  updateTagAndTypeIds,
  // getFinalFilteredItems,
  updateFilteredItems,

  boxItems = [],
}) {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [allergens, addAllergens] = useState({});
  const [itemType, setItemType] = useState({});
  // const [tagsArr, setTagsArr] = useState([]);

  useEffect(() => {
    setItems(boxItems);
    // setFilteredItems(boxItems);
  }, []);

  const handleFilters = (_evt, from = "types") => {
    const { name, value } = _evt.target;
    const copyFilterItems =
      from === "tags" ? { ...allergens } : { ...itemType };

    if (from === "tags") {
      if (value in copyFilterItems) {
        delete copyFilterItems[value];
      } else {
        copyFilterItems[value] = Number(value);
      }

      let filteredArr = loopThroughFunc(copyFilterItems, from);
      setFilteredItems(filteredArr);
      updateFilteredItems(filteredArr);
      addAllergens(copyFilterItems);
      return;
    }

    if (from === "types") {
      if (value in copyFilterItems) {
        delete copyFilterItems[value];
      } else {
        copyFilterItems[value] = Number(value);
      }

      let filteredArr = loopThroughFunc(copyFilterItems, from);
      setFilteredItems(filteredArr);
      updateFilteredItems(filteredArr);
      setItemType(copyFilterItems);
      return;
    }

    // if (updateTagAndTypeIds) {
    //   let arr = [];
    //   if (Object.values(copyFilterItems)?.length) {
    //     Object.values(copyFilterItems)?.forEach((d) => {
    //       let obj =
    //         from === "tags" ? { tag_id: Number(d) } : { type_id: Number(d) };
    //       arr.push(obj);
    //     });
    //   }
    //   updateTagAndTypeIds(arr);
    // }

    // if (getTagsAndTypes) {
    //   if (from === "tags") {
    //     getTagsAndTypes(copyFilterItems, itemType);
    //     return;
    //   }
    //   getTagsAndTypes(allergens, copyFilterItems);
    //   return;
    // }
  };

  const loopThroughFunc = (obj, type) => {
    let arr1 = [];
    let arr2 = [];
    if (type === "tags") {
      let selectedTypes = Object.values(itemType);
      let selectedTags = Object.values(obj);

      if (selectedTypes.length) {
        selectedTypes.forEach((d) => {
          items.forEach((dd) => {
            if (dd.typesArr.includes(d)) {
              arr1.push(dd);
            }
          });
        });
      }

      selectedTags.forEach((d) => {
        items.forEach((dd) => {
          if (dd.tagsArr.includes(d)) {
            arr2.push(dd);
          }
        });
      });
    } else {
      let selectedTags = Object.values(allergens);
      let selectedTypes = Object.values(obj);

      if (selectedTags.length) {
        selectedTags.forEach((d) => {
          items.forEach((dd) => {
            if (dd.tagsArr.includes(d)) {
              arr1.push(dd);
            }
          });
        });
      }

      selectedTypes.forEach((d) => {
        items.forEach((dd) => {
          if (dd.typesArr.includes(d)) {
            arr2.push(dd);
          }
        });
      });
    }

    arr1 = arr1.concat(arr2);
    let finalArr = [...new Set(arr1)];
    return finalArr;
  };

  
  return (
    <div className="flex flex-wrap row">
      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div className="listing">
          <div className="listing-head">Allergens & Preferences</div>
          <ul>
            {tags?.length
              ? tags.map((d, i) => (
                  <li
                    className={allergens[d.id] === d.id ? "" : "line-through"}
                    key={i}
                  >
                    <input
                      type="checkbox"
                      id={"tags-" + d.id}
                      name="tagsFilter"
                      value={d.id}
                      checked={allergens?.[d.id] === Number(d.id)}
                      onChange={(e) => handleFilters(e, "tags")}
                    />

                    <label htmlFor={"tags-" + d.id}>
                      <span></span>
                      {d.name}
                    </label>
                  </li>
                ))
              : null}
          </ul>
        </div>
      </div>

      
      {showTypeFilters ? (
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
          <div className="listing">
            <div className="listing-head">Types</div>

            <ul>
              {types?.length
                ? types.map((d, i) => (
                    <li
                      className={itemType[d.id] === d.id ? "" : "line-through"}
                      key={i}
                    >
                      <input
                        type="checkbox"
                        id={"type-" + d.id}
                        name="typeFilter"
                        value={d.id}
                        checked={itemType?.[d.id] === Number(d.id)}
                        onChange={(e) => handleFilters(e, "types")}
                      />

                      <label htmlFor={"type-" + d.id}>
                        <span></span>
                        {d.name}
                      </label>
                    </li>
                  ))
                : null}
            </ul>
          </div>
        </div>
      ) : null}
    </div>
  );
}
