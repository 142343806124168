import React, { Component } from "react";
import { withRouter } from "react-router";
import MainLayout from "../layouts/MainLayout";
import Auth from "../common/Auth";
import YoutubeBackground from "react-youtube-background";
import { checkItemBeforeAddingCart } from "../common/utils";
import { updateCartItemAPICount } from "../common/common-api-calls";
import { withContext } from "../context/Context";
import Slider from "../components/Slider.js";
import icons from "../common/Images";
import Fetch from "../common/Fetch";

// import {Spotify} from "react-spotify-embed";
// import SpotifyPlayer from "../components/third-party/Spotify";

const { circle_tick, star, circle } = icons;

class Home extends Component {
  state = {
    pageLoading: false,
    allTags: [],
    allTagProduct: [],
    loading: {
      banner: true,
      reviews: true,
    },
    tagsId: [],
    bannerData: [],
    selectedId: [],
    hotChoco: [],
    hotChocoInfo: {},

    selectedTagsObj: {},

    miscellaneousData: {},
  };

  static getDerivedStateFromProps(props, state) {
    const { totalCartItems, footerData } = props.context;

    let miscellaneousData = {};

    if (!Object.keys(miscellaneousData)?.length) {
      miscellaneousData = { ...footerData };
    }

    if (!totalCartItems && Object.values(state.hotChocoInfo).length) {
      let copy = { ...state.hotChocoInfo };
      for (let key in copy) {
        copy[key] = { loading: false, btnText: "Add to cart" };
      }
      return {
        hotChocoInfo: copy,
        miscellaneousData,
      };
    }
    return { miscellaneousData };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getTags();
    this.getHotChoco();

    Fetch("homeslider/allhomesliders", null, { method: "get" })
      .then((res) => {
        if (res?.success) {
          this.setState({
            loading: { ...this.state.loading, banner: false },
            bannerData: res.data,
          });
        }
      })
      .catch((err) => console.log(err));

    Fetch("page/allreviews", null, { method: "get" })
      .then((res) => {
        if (res?.success) {
          this.setState({
            loading: { reviewSection: false },
            reviewsData: res?.data,
          });
        }
      })
      .catch((err) => console.log(err));
  }

  getHotChoco = () => {
    let temp = {};
    Fetch("prod/allproducts/hot-chocolates", null, {
      method: "get",
    }).then((res) => {
      if (res?.success) {
        res?.data.forEach((d) => {
          // temp[d[0].id] = { loading: false, btnText: "Add to cart" };
          temp[d.id ?? d[0].id] = { loading: false, btnText: "Add to cart" };
        });

        this.setState({
          hotChocoInfo: temp,
          hotChoco: res?.data,
        });
      }
    });
  };

  getTags = () => {
    Fetch("prod/alltags", null, { method: "get" }).then((res) => {
      if (res?.success) {
        // var selectedId = [];
        // res.data?.map((item) => {
        //   if (item.id === res.data[0]?.id) {
        //     selectedId.push({ id: res.data[0]?.id, active: true });
        //   } else {
        //     selectedId.push({ id: item.id, active: false });
        //   }
        // });
        // let tempObj={};
        let selectedTags = res.data.map((d) => {
          return d.id;
        });

        this.setState({
          allTags: res.data,
          // tagsId: [res.data[0]?.id],
          // selectedId: selectedId,
          // selectedTagsObj: tempObj
        });

        this.allTagCLick(selectedTags.toString());
      }
    });
  };

  allTagCLick = (ids) => {
    Fetch(`prod/allproducts?tag_id=${ids}`, null, {
      method: "get",
    }).then((res) => {
      if (res.success) {
        this.setState({
          allTagProduct: res.data,
        });
      }
    });
  };

  selectTag = (id) => {
    const { tagsId, selectedId, selectedTagsObj } = this.state;
    const tagObjCopy = { ...selectedTagsObj };
    const idData = [...tagsId, id];

    const updateData = [];
    if (id in tagObjCopy) {
      delete tagObjCopy[id];
    } else {
      tagObjCopy[id] = id;
    }

    if (Array.isArray(selectedId) && selectedId?.length) {
      selectedId.forEach((d) => {
        if (d.id === id) {
          updateData.push({ id: id, active: !d.active });
        } else {
          updateData.push({ id: d.id, active: d.active });
        }
      });
    }

    let ids = Object.values(tagObjCopy);

    if (ids?.length) {
      this.allTagCLick(ids.toString());
    }

    this.setState({
      tagsId: idData,
      selectedId: updateData,
      selectedTagsObj: tagObjCopy,
    });
  };

  addHotChocolatesToCart = (data, toProdPage = false) => {
    const { hotChocoInfo } = this.state;
    let copyChocoObj = { ...hotChocoInfo };

    const { isUserLogin, cart } = this.props.context;
    if (toProdPage) {
      this.props.history.push(
        `/shop/${data?.parent_category?.[0]?.slug}/${data?.category?.slug}/${data.slug}`
      );
      return;
    }

    const isItemInCart = copyChocoObj[data.id]?.btnText === "Go To Basket";

    if (isItemInCart) {
      return this.props.history.push("/cart");
    }
    const itemPrice = data?.flavors?.length
      ? data.flavors[0].price
      : data.price
      ? data.price
      : 10;

    copyChocoObj[data.id] = { loading: true, btnText: "Add to basket" };

    this.setState({ hotChocoInfo: copyChocoObj });

    const tempParams = {
      boxes: [],
      product_id: data?.id,
      category_id: data.category?.id,
      gifts: [],
      tags: [],
      messages: [{ message: "" }],
      price: parseFloat(itemPrice),
      size_id: "",
      items: [],
      counts: 1,
      flavour_id: data?.flavors?.length ? data.flavors[0].id : "",
    };

    if (!isUserLogin) {
      let localData = Auth.handleLocalStorage("temp", null, "get");
      let processedData = checkItemBeforeAddingCart(
        tempParams,
        localData,
        true
      );
      let cart_id = Math.floor(Math.random() * 1000 + 1);

      const { updateIndex, finalParams } = processedData;
      if (updateIndex !== null) {
        localData[updateIndex].count = localData[updateIndex].count + 1;
        localData["params"] = { ...finalParams };
      } else {
        let beforeFinalObj = {
          boxes: [],
          parent_category: data.parent_category,
          product: {
            name: data?.name,
            photo: data?.photo,
            note: data?.note,
            id: data?.id,
            description: data?.description,
          },
          basePrice: parseFloat(itemPrice),
          id: cart_id,
          cart_id,
          count: 1,
          category: data?.category,
          items: [],
          price: parseFloat(itemPrice),
          size_id: data.sizes.length ? data.sizes[0].id : "",
          flavors: data.flavors.length ? data.flavors[0].id : "",
          params: { ...finalParams },
        };
        localData.push(beforeFinalObj);
      }

      Auth.handleLocalStorage("temp", localData, "set");
      copyChocoObj[data.id] = { loading: false, btnText: "Go To Basket" };
      this.setState({ hotChocoInfo: copyChocoObj });
      window.handleToastMsg(true, "Item Added Successfully");
      setTimeout(() => {
        window.handleToastMsg(false, "");
      }, 3000);
      this.props.context.getAllInCart(false);
      return;
    }

    let checkItemsStatus = checkItemBeforeAddingCart(tempParams, cart);
    console.log({ checkItemsStatus });
    const { update, finalParams, cartID } = checkItemsStatus;

    if (update) {
      updateCartItemAPICount(cartID, "update", finalParams?.product_id)
        .then((res) => {
          if (res?.success) {
            this.props.context.getAllInCart(true);
            copyChocoObj[data.id] = { loading: false, btnText: "Go To Basket" };
            this.setState({ hotChocoInfo: copyChocoObj });
            window.handleToastMsg(true, res.message);
            setTimeout(() => {
              window.handleToastMsg(false, "");
            }, 3000);
            return;
          }

          window.handleToastMsg(true, "Something went wrong");
          setTimeout(() => {
            window.handleToastMsg(false, "");
          }, 3000);
          return;
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }

    Fetch("prod/cart/add", { ...finalParams }, { sendToken: true }).then(
      (res) => {
        if (res?.success) {
          this.props.context.getAllInCart(true);
          copyChocoObj[data.id] = { loading: false, btnText: "Go To Basket" };
          this.setState({ hotChocoInfo: copyChocoObj });
          window.handleToastMsg(true, res.message);
          setTimeout(() => {
            window.handleToastMsg(false, "");
          }, 3000);
          return;
        }

        window.handleToastMsg(true, "Something went wrong");
        setTimeout(() => {
          window.handleToastMsg(false, "");
        }, 3000);
        return;
      }
    );
  };

  render() {
    const {
      pageLoading,
      loading,
      reviewsData,
      bannerData,
      hotChoco,
      hotChocoInfo,
      miscellaneousData,

      selectedTagsObj,
      allTags,
      allTagProduct,
      selectedId,
    } = this.state;

    function startsArray(count) {
      let arr = [];
      for (var i = 0; i < count; i++) {
        arr.push(star);
      }

      return arr;
    }

    const {
      seller_title,
      seller_subtitle,
      seller_lefttextcontent,
      seller_lefttextheading,
      seller_righttextcontent,
      seller_righttextheading,
      slider_text,
      rightimage,
      leftimage,
    } = miscellaneousData;

    return (
      <MainLayout
        hideBreadCrumb
        title="Home | Dark Sugar"
        loading={pageLoading}
        isHeaderTransparent
      >
        <div className="home-banner-main">
          <section className="home-banner">
            {/* <div className="home-banner__audio container">
              <div className="box col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="box-1 flex align-center justify-space">
                  <SpotifyPlayer />
                </div>
              </div>
              <div className="box col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="box-1 flex align-center justify-space">
                  <SpotifyPlayer />
                </div>
              </div>
            </div> */}

            {bannerData?.length ? (
              <Slider
                breakPoints={{ 1024: 1, 900: 1, 600: 1 }}
                customArrows
                params={{
                  slidesToShow: 1,
                  infinite: false,
                  className: "home-page-carousel",
                  centerMode: false,
                }}
              >
                {bannerData?.map((d, i) => (
                  <div className="home-banner__video" key={i}>
                    {d.bannerimage ? (
                      <img src={d.bannerimage.original_url} alt="" />
                    ) : (
                      <YoutubeBackground
                        videoId={d.url}
                        aspectRatio="16:9"
                        // overlay={rgba(0,0,0,.4)}
                        className={"height-100"} /* default -> null */
                        nocookie={
                          true
                        } /* default -> false | sets host to https:/*www.youtube-nocookie.com to avoid loading Google's cookies */
                      ></YoutubeBackground>
                    )}
                    <div className="home-banner-text">
                      <h2>{d.banner_text}</h2>
                      <button
                        onClick={() => {
                          this.props.history.push("/shop/personalised-box");
                        }}
                      >
                        {d.button_name}
                      </button>
                    </div>
                  </div>
                ))}
              </Slider>
            ) : null}
          </section>

          <section className="home-marquee flex">
            <li>{slider_text}</li>
            <li>{slider_text}</li>
            <li>{slider_text}</li>
            <li>{slider_text}</li>
          </section>
        </div>

        <section className="home-seller center">
          <div className="home-seller-content">
            <div className="home-seller-head">{seller_title}</div>
            <div
              className="home-seller-subhead"
              dangerouslySetInnerHTML={{ __html: seller_subtitle }}
            ></div>
          </div>
        </section>

        <section className="home-two inline-block">
          <div className="container">
            <div className="row flex flex-wrap">
              <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 order-2">
                <div className="home-two-left">
                  <div
                    className="home-two-head justify-right"
                    dangerouslySetInnerHTML={{ __html: seller_lefttextheading }}
                  ></div>
                  <p
                    dangerouslySetInnerHTML={{ __html: seller_lefttextcontent }}
                  ></p>
                </div>
              </div>
              {/* <Fade direction="right"> */}
              <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 home-two-img1">
                <img src={leftimage} alt=""></img>
              </div>
              {/* </Fade> */}
            </div>
            <div className="row flex flex-wrap">
              {/* <Fade direction="left"> */}
              <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12  home-two-img">
                <img src={rightimage} alt=""></img>
              </div>
              {/* </Fade> */}

              <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 home-end">
                <div className="home-two-right">
                  <div
                    className="home-two-head"
                    dangerouslySetInnerHTML={{
                      __html: seller_righttextheading,
                    }}
                  ></div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: seller_righttextcontent,
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="home-happy"> //To be used in future
          <h3 className="desk-none">
            How you’re <br /> feeling today?
          </h3>
          <h3 className="mob-none">who do you want to make happy today?</h3>
          <div className="home-happy-inner">
            <div className="scoller-main mb2">
              <div className="scoller flex ">
                {allTags?.map((item, key) => {
                  return (
                    <a
                      onClick={() => this.selectTag(item.id)}
                      type="button"
                      className={`flex align-center ${
                        selectedTagsObj[item.id] &&
                        selectedTagsObj[item.id] === Number(item.id)
                          ? "active"
                          : ""
                      }`}
                      key={key}
                    >
                      {" "}
                      <img
                        src={
                          selectedTagsObj[item.id] &&
                          selectedTagsObj[item.id] === Number(item.id)
                            ? circle_tick
                            : Plus
                        }
                        alt=""
                      />
                      {item.name}
                    </a>
                  );
                })}
              </div>
            </div>
          </div> 

          <div className="container ">
            <div className="row mb2 home-happy-today flex flex-wrap">
              {allTagProduct?.slice(0, 6)?.map((item, key) => {
                return (
                  <div
                    className="col-xl-4 col-lg-4 col-md-6 col-sm-6 cursor-pointer"
                    onClick={() => {
                      this.props.history.push(geturl(item));
                      return;
                    }}
                    key={key}
                  >
                    <img src={item?.photo} alt=""></img>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      */}

        {hotChoco?.length ? (
          <section className="home-hot ">
            <div className="container">
              <h3>Hot Chocolates</h3>
              <p>
                Welcome to our online world, a site where you can explore,
                chose, discover and have fun. We hope you enjoy it.
              </p>
              <div className="row home-hot-section">
                <Slider
                  breakPoints={{ 6000: 4, 1024: 4, 900: 3, 600: 2 }}
                  params={{
                    slidesToShow: 1,
                    infinite: false,
                    className: "home-page-carousel",
                    centerMode: false,
                  }}
                >
                  {hotChoco?.map((d, key) => {
                    return (
                      <div
                        className="col-xl-3 col-lg-3 col-md-4 home-hot-inner"
                        key={key}
                      >
                        <div
                          className="flex-classes mb2 cursor-pointer"
                          onClick={() => this.addHotChocolatesToCart(d, true)}
                        >
                          <img src={d.photo} alt={d.name}></img>
                          <div className="home-hot-head ">{d.name}</div>
                          <div
                            className="home-hot-content"
                            dangerouslySetInnerHTML={{
                              __html: d.description,
                            }}
                          />
                        </div>

                        <button onClick={() => this.addHotChocolatesToCart(d)}>
                          {hotChocoInfo?.[d.id]?.loading ? (
                            "Loading..."
                          ) : (
                            <>
                              <img
                                src={
                                  hotChocoInfo?.[d.id]?.btnText ===
                                  "Go To Basket"
                                    ? circle_tick
                                    : circle
                                }
                                alt=""
                              />
                              {hotChocoInfo?.[d.id]?.btnText}
                            </>
                          )}
                        </button>
                      </div>
                    );
                  })}
                </Slider>
              </div>

              <button
                className="home-hot-btn"
                onClick={() =>
                  this.props.history.push(
                    "/shop/prepacked-items/hot-chocolates"
                  )
                }
              >
                SEE ALL HOT CHOCOLATES
              </button>
            </div>
          </section>
        ) : null}

        {reviewsData?.length ? (
          <section className="review ">
            <div className="container ">
              <div className="review-section8">
                <h3>Reviews</h3>
                <div className="row">
                  <Slider
                    breakPoints={{ 6000: 4, 1024: 3, 900: 3, 600: 2 }}
                    params={{
                      slidesToShow: 1,
                      infinite: false,
                      className:
                        "home-page-carousel liveExam_slider review-section-slide  ",
                      // centerMode: true,
                      //  centerPadding: '20%',
                    }}
                  >
                    {loading?.reviewSection ? (
                      <p>Fetching Reviews...</p>
                    ) : reviewsData?.length ? (
                      reviewsData?.reverse()?.map((d, i) => (
                        <div
                          className="col-xl-3 col-lg-3 col-md-3 review-inner"
                          key={i}
                        >
                          <div className="review-slides mb5">
                            {/* <div dangerouslySetInnerHTML={{ __html: d.body }} /> */}
                            <div
                              className="mb1"
                              dangerouslySetInnerHTML={{ __html: d.content }}
                            />

                            <a
                              href={d.link}
                              target="_blank"
                              rel="noreferrer"
                              className="review-google default-anchor"
                            >
                              {d.title}
                            </a>

                            <div className="flex  review-star">
                              {startsArray(Number(d.rating))?.length
                                ? startsArray(Number(d.rating)).map((d, i) => (
                                    <img src={d} alt="" key={i} />
                                  ))
                                : null}
                            </div>
                          </div>
                          <img
                            className="review-google-img"
                            src={d.featureimage}
                            alt={d.title}
                            onError={() => {
                              console.log("unable to load");
                            }}
                            loading="lazy"
                          />
                        </div>
                      ))
                    ) : null}
                  </Slider>
                </div>
              </div>
            </div>
          </section>
        ) : null}

        <div className="container">
          <iframe
            title="spotify"
            style={{ borderRadius: "12px", marginBottom: "4rem" }}
            src="https://open.spotify.com/embed/playlist/0PnCBRl1EsnaSQLAI3TYli?utm_source=generator"
            width="100%"
            height="352"
            frameBorder={0}
            allowFullScreen={true}
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>
        </div>
      </MainLayout>
    );
  }
}

export default withContext(withRouter(Home));

