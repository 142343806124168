import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import Home from "../pages/Home";
import Shop from "../pages/Shop";
import Login from "../pages/Login";
import Error from "../pages/Error";
import Cart from "../pages/Cart";
import Account from "../pages/Account";
import Terms from "../pages/terms-and-conditions";
import Refund from "../pages/refund-policy";
import Privacy from "../pages/privacy-policy";
import ProductSubCategory from "../pages/ProductSubCategory";
import CustomizePersonalisedProducts from "../pages/CustomizeProducts";
import PackedProdCustomize from "../pages/PackedProdCustomize.js";
import Checkout from "../pages/Checkout";
import StripePayment from "../pages/StripePayment";
import Contact from "../pages/contact";
import Story from "../pages/story";
import ViewOrders from "../pages/ViewOrders";

let PUBLIC_KEY = process.env.REACT_APP_STRIPE_P_K_TEST;
const stripePromise = loadStripe(PUBLIC_KEY);

export const handlePaymentComps = (comp) => {
  const options = {
    appearance: {
      theme: "stripe",

      variables: {
        colorPrimary: "#0570de",
        colorBackground: "#ffffff",
        colorText: "#30313d",
        colorDanger: "#df1b41",
        fontFamily: "Ideal Sans, system-ui, sans-serif",
        spacingUnit: "2px",
        borderRadius: "4px",
        // See all possible variables below
      },
    },
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      {comp}
    </Elements>
  );
};

export const pageRoutes = [
  {
    path: "/",
    comp: <Home />,
    exact: true,
    authRequired: false,
  },

  {
    path: "/shop",
    comp: <Shop />,
    exact: true,
    authRequired: false,
  },

  {
    path: "/shop/:id",
    comp: <ProductSubCategory />,
    exact: true,
    authRequired: false,
  },

  {
    path: "/shop/:id/:id2",
    comp: <CustomizePersonalisedProducts />,
    exact: true,
    authRequired: false,
  },

  {
    path: "/shop/:id/:id2/:id3",
    comp: <PackedProdCustomize />,
    exact: true,
    authRequired: false,
  },

  {
    path: "/login",
    comp: <Login />,
    exact: true,
    authRequired: false,
  },

  {
    path: "/register",
    comp: <Login />,
    exact: true,
    authRequired: false,
  },

  {
    path: "/forgot-password",
    comp: <Login />,
    exact: true,
    authRequired: false,
  },
  
  {
    path: "/cart",
    comp: <Cart />,
    exact: true,
    authRequired: false,
  },

  {
    path: "/PackedProdCustomize",
    comp: <PackedProdCustomize />,
    exact: true,
    authRequired: false,
  },

  {
    path: "/account",
    comp: handlePaymentComps(<Account />),
    exact: true,
    authRequired: false,
  },

  {
    path: "/account/orders/:id",
    comp: handlePaymentComps(<ViewOrders />),
    exact: true,
    authRequired: false,
  },

  {
    path: "/checkout",
    comp: handlePaymentComps(<Checkout />),

    exact: true,
    authRequired: false,
  },
  {
    path: "/payments",
    comp: handlePaymentComps(<StripePayment />),
    exact: true,
    authRequired: false,
  },
  {
    path: "/terms-and-conditions",
    comp: <Terms />,
    exact: true,
    authRequired: false,
  },
  {
    path: "/refund-policy",
    comp: <Refund />,
    exact: true,
    authRequired: false,
  },
  {
    path: "/privacy-policy",
    comp: <Privacy />,
    exact: true,
    authRequired: false,
  },
  {
    path: "/about-us",
    comp: <Story />,
    exact: true,
    authRequired: false,
  },
  {
    path: "/contact-us",
    comp: <Contact />,
    exact: true,
    authRequired: false,
  },
  {
    path: "/404",
    comp: <Error />,
    exact: true,
    authRequired: false,
  },
];