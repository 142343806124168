import React from "react";
import expressDeliveryMsg from "../../demo/deliveryMsg";
import Tooltip from "../Tooltip";

export default function ExpressDileveryMsg() {
  return (
    <div className="truffle-free mt3 flex flex-wrap">
      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
        <div className="flex align-center justify-center truffle-free-inner">
          <div className="mr1">{expressDeliveryMsg[1]?.title}</div>
          <Tooltip text={expressDeliveryMsg[1]?.desc} />
        </div>
      </div>
      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
        <div className="flex align-center justify-center truffle-free-inner">
          {" "}
          <div className="mr1">{expressDeliveryMsg[2]?.title}</div>
          <Tooltip text={expressDeliveryMsg[2]?.desc} />
        </div>
      </div>
      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
        <div className="flex align-center justify-center truffle-free-inner">
          {" "}
          <div className="mr1">{expressDeliveryMsg[3]?.title}</div>
          <Tooltip text={expressDeliveryMsg[3]?.desc} />
        </div>
      </div>
    </div>
  );
}
