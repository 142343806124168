import React, { Component } from "react";
import { withRouter } from "react-router";
import Layout from "../layouts/MainLayout";

import Images from "../common/Images";
import Fetch from "../common/Fetch";
import Link from "../common/Link";
import { withContext } from "../context/Context";

const { shop1, shop_p } = Images;
class Shop extends Component {
  state = {
    isLoading: true,
    prodCategories: [],
  };

  componentDidMount() {
    Fetch("prod/allcategories", null, { method: "get" }).then((res) => {
      if (res.success) {
        this.setState({ prodCategories: res.data, isLoading: false });
      }else{
        this.setState({isLoading: false})
      }
    }).catch(err=>console.log(err));
  }

  onClickProdCategory = (name, slug) => {
    const {context} = this.props;
    const path = name.toLowerCase().replaceAll(" ", "+");
    return `/shop/${slug}`;
  };

  render() {
    const { isLoading, prodCategories } = this.state;

    return (
      <Layout title="Shop | Dark Sugar" loading={isLoading}>
        <section className="shop-banner shop">
          <h2>SHOP</h2>
        </section>

        <section className="container shop-section">
          <div className=" flex flex-wrap shop-section-change">
            {prodCategories?.length
              ? prodCategories.map((d, i) => (
                  <Link
                    href={() => this.onClickProdCategory(d.name, d.slug)}
                    key={i}
                    className="shop-section-inner"
                    target={d.name}
                  >
                    <img src={d.photo} alt={d.name}></img>
                    <div>
                      <h3> {d.name}</h3>
                    </div>
                  </Link>
                ))
              : null}

            {/* <div
              className="shop-section-inner"
              onClick={() => this.props.history.push("/personlised")}
            >
              <img src={shop1}></img>
              <div>
                <h3> Classic Boxes</h3>
              </div>
            </div>
            <div
              className="shop-section-inner"
              onClick={() => this.props.history.push("/personlised")}
            >
              <img src={shop1}></img>
              <div>
                <h3> Classic Boxes</h3>
              </div>
            </div>
            <div
              className="shop-section-inner"
              onClick={() => this.props.history.push("/personlised")}
            >
              <img src={shop1}></img>
              <div>
                <h3> Classic Boxes</h3>
              </div>
            </div>
            <div
              className="shop-section-inner"
              onClick={() => this.props.history.push("/personlised")}
            >
              <img src={shop1}></img>
              <div>
                <h3> Classic Boxes</h3>
              </div>
            </div>

            <div
              className="shop-section-inner"
              onClick={() => this.props.history.push("/personlised")}
            >
              <img src={shop_p}></img>
              <div>
                <h3> Classic Boxes</h3>
              </div>
            </div>
            <div
              className="shop-section-inner"
              onClick={() => this.props.history.push("/personlised")}
            >
              <img src={shop1}></img>
              <div>
                <h3> Classic Boxes</h3>
              </div>
            </div>
            <div
              className="shop-section-inner"
              onClick={() => this.props.history.push("/personlised")}
            >
              <img src={shop1}></img>
              <div>
                <h3> Classic Boxes</h3>
              </div>
            </div>
            <div
              className="shop-section-inner"
              onClick={() => this.props.history.push("/personlised")}
            >
              <img src={shop1}></img>
              <div>
                <h3> Classic Boxes</h3>
              </div>
            </div>
            <div
              className="shop-section-inner"
              onClick={() => this.props.history.push("/personlised")}
            >
              <img src={shop1}></img>
              <div>
                <h3> Classic Boxes</h3>
              </div>
            </div> */}

          </div>
        </section>
      </Layout>
    );
  }
}

export default withContext(withRouter(Shop));
