import React, { useEffect, useState } from "react";
import { withContext } from "../context/Context";
import { withRouter } from "react-router";

import MainLayout from "../layouts/MainLayout";

function Contact({location, context: { pagesData }}) {
  const [loading, setLoading] = useState(true);
  const [meta_data, set_meta_data] = useState({});

    useEffect(()=>{
        window.scrollTo(0,0);
    }, [])

    useEffect(()=>{
      let slug = location.pathname.split("/")[1];
      if(!Object.keys(meta_data).length){
        if(slug in pagesData){
          set_meta_data(pagesData[slug]);
          setLoading(false);
        }
      }
    }, [pagesData])


  return (
    <MainLayout title="Contact | Dark Sugar" loading={loading}>
      <div className="terms">
        <div className="container ">
          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 terms-container">
            <h4>{meta_data?.title}</h4>
            <div className="terms-para" dangerouslySetInnerHTML={{__html: meta_data?.body}}>
              {/* <div className="center">
                <p>
                  <strong>Dark Sugars Chocolates</strong>
                </p>
                <p>141 Brick Lane</p>
                <p>London, E1 6SB</p>
                <p>
                  Opening Hours
                  <br />
                  Mon to Sun : 10am - 8pm
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>Dark Sugars Cocoa House</strong>
                </p>
                <p>9 Nelson Road</p>
                <p>London, SE10 9JB</p>
                <p>
                  Opening Hours
                  <br />
                  Mon to Sun : 10am - 10pm
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>Dark Sugars Ice Creams</strong>
                </p>
                <p>21 Nelson Road</p>
                <p>London, SE10 9JB</p>
                <p>
                  Opening Hours
                  <br />
                  Mon to Sun : 10am - 10pm
                </p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <div>&nbsp;</div>
                <div>
                  Want to get in touch? Drop us a line at{" "}
                  <a href="mailto:info@darksugars.co.uk?subject=Info">
                    {" "}
                    info@darksugars.co.uk
                  </a>{" "}
                  or via phone +44 (0) 7429 472606 or visit us on{" "}
                  <a href="https://www.facebook.com/darksugarschocolates/">
                    Facebook
                  </a>
                  , <a href="https://twitter.com/DarkSugars">Twitter</a> and{" "}
                  <a href="https://www.instagram.com/darksugars/">Instagram</a>.
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default withContext(withRouter(Contact));
