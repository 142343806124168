import React, { useState, useEffect } from "react";
import Fetch from "../common/Fetch";
import { withContext } from "../context/Context";
// import { getAllDiscountCodes } from "../common/common-api-calls";

function ApplyCoupons({
  // allDiscountList,
  context: {
    updateDiscountID,
    couponCodeInfo: {
      validCouponCode,
      discountAmount,
      discountCodeID,
      couponValueType,
    },
  },
  getPriceAfterDiscount,
  priceBeforeDiscount,
}) {
  const [couponCode, setCouponCode] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [applyBtnText, setApplyBtnText] = useState("Apply");

  //const [discountedValue, setDiscountedValue] = useState("");
  useEffect(() => {
    if (!couponCode.trim().length && validCouponCode) {
      setCouponCode(validCouponCode);
      setSuccessMsg("Applied !");
      setApplyBtnText("Remove");
      getPriceAfterDiscount("apply", discountAmount, couponValueType);
    }
  }, [discountCodeID, validCouponCode]);

  const handleChange = (_evt) => {
    const { name, value } = _evt.target;
    setSuccessMsg("");
    setErrorMsg("");
    setCouponCode(value);
  };

  const onApplyCoupon = (_evt) => {
    _evt.preventDefault();

    if (applyBtnText === "Remove") {
      setCouponCode("");
      setApplyBtnText("Apply");
      setSuccessMsg("");
      setErrorMsg("");
      getPriceAfterDiscount("remove", 0);
      updateDiscountID(null, "", 0, "");

      window.handleToastMsg(true, "Coupon code remove successfully");
      return;
    }

    if (!couponCode.trim().length) {
      setErrorMsg("Coupon code can't be empty");
      return;
    }

    Fetch(`prod/alldiscounts/${couponCode}`, null, { method: "get" }).then(
      (response) => {
        if (response?.success) {
          let [{ value, value_type, id }] = response.data;

          if (Number(priceBeforeDiscount) < Number(value)) {        
            setSuccessMsg("");
            setErrorMsg("Voucher or coupon not applicable please add more products");
            getPriceAfterDiscount("remove", 0);
            return;
          }

          updateDiscountID(id, couponCode, value, value_type);
          setErrorMsg("");
          setSuccessMsg("Applied !");
          setApplyBtnText("Remove");
          getPriceAfterDiscount("apply", value, value_type);
          return;
        }

        setSuccessMsg("");
        setErrorMsg("Invalid coupon code");
        // updateDiscountID(null, "", 0, "");
        getPriceAfterDiscount("remove", 0);
        return;
      }
    );

    // return;
    // getAllDiscountCodes().then((res) => {
    //   if (res?.status) {
    //     let selectedCode =
    //       res?.discountList.find((d) => d.code === couponCode) || {};
    //     if (selectedCode?.code) {
    //       let activatedDate =
    //         selectedCode?.activated_at.includes("/") &&
    //         selectedCode?.activated_at.split("/");
    //       let date1 = +activatedDate[0].replace("0", "");
    //       let month1 = +activatedDate[1].replace("0", "");
    //       let year1 = +activatedDate[2];
    //       let newActivatedDate = new Date(year1, month1, date1);

    //       let deactivatedDate =
    //         selectedCode?.deactivated_at.includes("/") &&
    //         selectedCode?.deactivated_at.split("/");
    //       let date2 = +deactivatedDate[0].replace("0", "");
    //       let month2 = +deactivatedDate[1].replace("0", "");
    //       let year2 = +deactivatedDate[2];
    //       let newDeactivatedDate = new Date(year2, month2, date2);

    //       if (newActivatedDate.getTime() > newDeactivatedDate.getTime()) {
    //         setErrorMsg("Coupon code expired !");
    //         getPriceAfterDiscount("apply", 0, "fixed");
    //         setApplyBtnText("Remove");
    //         setSuccessMsg("");
    //         return;
    //       }

    //       updateDiscountID(selectedCode?.id);
    //       setErrorMsg("");
    //       setSuccessMsg("Applied !");
    //       setApplyBtnText("Remove");
    //       getPriceAfterDiscount(
    //         "apply",
    //         parseFloat(selectedCode?.value),
    //         selectedCode.valueType
    //       );
    //       return;
    //     }

    //     setSuccessMsg("");
    //     setErrorMsg("Invalid coupon code");
    //     updateDiscountID(null);
    //     getPriceAfterDiscount("remove", 0);
    //     return;
    //   }

    //   setSuccessMsg("");
    //   setErrorMsg("Something went wrong !");
    //   updateDiscountID(null);
    //   getPriceAfterDiscount("remove", 0);
    // });
  };

  return (
    <div className="width-100">
      <p className="mb1 mt1 apply-coupon-heading">
        Introduce your Coupon/Voucher Code
      </p>

      <form
        onSubmit={onApplyCoupon}
        method="post"
        className="flex width-100 apply-coupon-form"
      >
        <input
          type="text"
          onChange={handleChange}
          name="couponCode"
          id="couponCode"
          placeholder="Your code"
          className={`width-60 mr2 mb2 mt1`}
          value={couponCode}
        />

        <button type="submit" className="btn btn-apply-code">
          {applyBtnText}
        </button>
      </form>

      {errorMsg || successMsg ? (
        <p
          className={
            errorMsg ? "errMsg mb1" : successMsg ? "successMsg mb1" : ""
          }
        >
          {errorMsg || successMsg}
        </p>
      ) : null}
    </div>
  );
}

export default withContext(ApplyCoupons);
