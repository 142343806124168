import React, { useEffect, useState } from "react";
import icons from "../common/Images";
import Auth from "../common/Auth";
// import { withContext } from "../context/MainContext";
import Link from "../common/Link";
import Cart from "./CartComp";
import { sidebarLinks } from "../demo/demo";

// import Slide from "react-reveal/Slide";

import { withRouter } from "react-router-dom";
import { withContext } from "../context/Context";
import { Search } from "../common/search";

import MailchimpSubscribe from "react-mailchimp-subscribe";
import { mailChimpURL } from "../common/Urls";

// const url =
//   "https://darksugars.us13.list-manage.com/subscribe/post?u=827886460323d77ca7d0c4eff&amp;id=a543c8a737&amp;f_id=0072c1e2f0";

const htmlTag = document.querySelector("html");

const {
  Menu,
  Logo,
  logo,
  user,
  Basket,
  search,
  leftArrow,
  insta,
  fb,
  play,
  account_white,
  cross,
  Gift,
} = icons;

function Header(props) {
  const [showMenu, toggleMenu] = useState(0);
  const [showCart, toggleCart] = useState(0);
  const [itemsInCart, setItemsInCart] = useState(props.context?.cart?.length);
  const [darkMode, setDarkMode] = useState(0);
  const {
    isUserLogin,
    rightCartProps,
    handleRightCart,
    cart,
    logoutUser,
    totalCartItems,
    footerData,
  } = props.context;

  useEffect(() => {
    setItemsInCart(totalCartItems);
  }, [cart?.length, totalCartItems]);

  useEffect(() => {
    return () => {
      toggleMenu(0);
      toggleCart(0);
      handleRightCart("toggle_cart", false);
    };
  }, []);

  useEffect(() => {
    toggleCart(rightCartProps.show);
  }, [rightCartProps]);

  const userSettingUpdate = (value) => {
    if (value === 1) {
      setDarkMode(1);
      htmlTag.setAttribute("data-theme", "dark");
      localStorage.dataTheme = 1;
    }

    if (value === 0) {
      setDarkMode(0);
      localStorage.dataTheme = 0;
      htmlTag.setAttribute("data-theme", "");
    }
  };

  const onUserLogout = (loginCheck) => {
    if (loginCheck) {
      logoutUser();
      props.history.push("/");
      return;
    }
    props.history.push("/login");
  };

  return (
    <header>
      <LeftBar
        show={showMenu}
        data={{
          darkMode,
          push: props.history.push,
          toggleMenu: toggleMenu,
          userSettingUpdate: userSettingUpdate,
          footerData,
          push: props.history.push,
        }}
      />

      <Cart
        show={showCart}
        onClickCloseBtn={() =>
          props.context?.handleRightCart("toggle_cart", false)
        }
        props={props}
      />

      <div className="container z-index-1 ">
        <div className="header flex justify-space ">
          <div className="flex align-center">
            <button
              className="btn"
              onClick={() => {
                if (!showMenu) {
                  return toggleMenu(1);
                }
                return;
              }}
            >
              <img src={Menu}></img>
            </button>
            <Search />
          </div>

          <div className="logo">
            <Link href="/">
              <img src={Logo} alt=""></img>
            </Link>
          </div>

          <div className="flex align-center">
            <div className="dark-mode" style={{ display: "none" }}>
              Dark Mode
            </div>

            <div className="toggle px1 darks">
              <input
                type="checkbox"
                id="toggle"
                onChange={(e) => userSettingUpdate(e.target.checked ? 1 : 0)}
                checked={darkMode ? true : false}
              />
              <label htmlFor="toggle" className="toggle_btn"></label>
            </div>
            <div className="header-dropdown">
              <img src={account_white} className="header-user"></img>
              <div className="header-dropdown-content">
                <ul>
                  <li
                    onClick={() => {
                      props.history.push("/account");
                      return;
                    }}
                  >
                    <a> Orders</a>
                  </li>
                  <li
                    onClick={() => {
                      props.history.push("/account");
                      return;
                    }}
                  >
                    <a> Addresses</a>
                  </li>
                  <li
                    onClick={() => {
                      props.history.push("/account");
                      return;
                    }}
                  >
                    <a> Your Account</a>
                  </li>
                  <li onClick={() => onUserLogout(isUserLogin)}>
                    <a> {isUserLogin ? "Logout" : "Login"} </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className=" basket px1">BASKET · {itemsInCart}</div>

            <button
              className="btn"
              onClick={() => {
                if (!props.context?.cart?.length) {
                  // toggleCart(0);
                  return window.handleToastMsg(
                    true,
                    "No items available in cart !"
                  );
                }

                if (!showCart) {
                  return toggleCart(1);
                }

                return;
                // if(isUserLogin) {
                //   if (!props.context?.cart?.length) {
                //     // toggleCart(0);
                //     return window.handleToastMsg(
                //       true,
                //       "No items available in cart !"
                //     );
                //   }

                //   if (!showCart) {
                //     return toggleCart(1);
                //   }
                //   return;
                // }

                // let localData = Auth.handleLocalStorage("temp", null, "get");

                // if (!localData || localData.length) {
                //   // toggleCart(0);
                //   return window.handleToastMsg(
                //     true,
                //     "No items available in cart !"
                //   );
                // }

                // if (!showCart) {
                //   return toggleCart(1);
                // }
                // return;
              }}
            >
              <img src={Basket} alt="cart"></img>
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}
export default withContext(withRouter(Header));

export const LeftBar = ({
  show,
  data: { push, darkMode, toggleMenu, userSettingUpdate, footerData },
}) => {
  const filteredSidebarList = sidebarLinks.filter((d) => d.show);
  const [email, setEmail] = useState("");

  if (!show) return null;

  return (
    <div>
      <div className="header-menu">
        <div className="mb2">
          <button className="btn float-right" onClick={() => toggleMenu(0)}>
            <img src={cross} alt="cross" />
          </button>
        </div>

        <Search />

        <ul>
          {filteredSidebarList?.map(
            (d, i) =>
              d.show && (
                <li key={i} onClick={() => push(d.path)}>
                  {d.title}{" "}
                  {d.icon ? <img src={account_white} alt="acc-icon" /> : null}
                </li>
              )
          )}
        </ul>

        <div
          className="flex items-center dark-modes mt5"
          style={{ display: "none" }}
        >
          <div className="">Dark Mode</div>
          <div className="toggle px1" style={{ display: "none" }}>
            <input
              type="checkbox"
              id="toggle"
              onChange={(e) => userSettingUpdate(e.target.checked ? 1 : 0)}
              checked={darkMode ? true : false}
            />
            <label htmlFor="toggle" className="toggle_btn"></label>
          </div>
        </div>

        {/* <div className="footer-input flex align-center  header-menu-input mt4 mb2">
          <input type="text" placeholder="Sign Up to Newsletter" />
          <img src={leftArrow} alt="arrow"></img>
        </div> */}

        <MailchimpSubscribe
          url={mailChimpURL}
          render={({ subscribe, status, message }) => (
            <>
              <div className="mb1 mt1">
                {status === "sending" && (
                  <div style={{ color: "blue" }}>sending...</div>
                )}
                {status === "error" && (
                  <div
                    style={{ color: "red" }}
                    dangerouslySetInnerHTML={{ __html: message }}
                  />
                )}
                {status === "success" && (
                  <div style={{ color: "green" }}>Subscribed !</div>
                )}
              </div>

              <div className="mb2 footer-inputs">
                <MailchimpSubscribe url={mailChimpURL} />
              </div>

              {/* <div className="footer-input flex align-center mb4"> */}
              {/* <input
                          type="email"
                          placeholder="Sign up to our Newsletter"
                          onChange={(e) => setEmail(e.target.value)}
                        />

                        <button
                          className="btn"
                          onClick={() => {
                            if (email) {
                              return subscribe({email});
                            }
                            return;
                          }}
                          type="submit"
                        >
                          <img src={leftArrow} alt="left-arrow"></img>
                        </button> */}
              {/* </div> */}
            </>
          )}
        />

        {/* <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => (
            <div className="footer-input flex align-center mb4 mt4">
              <input
                type="email"
                placeholder="Sign up to our Newsletter"
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                className="btn"
                onClick={() => {
                  console.log({ email });
                  if (email) {
                    return subscribe({ email });
                  }
                  return;
                }}
                type="submit"
              >
                <img src={leftArrow} alt="left-arrow"></img>
              </button>
            </div>
          )}
        /> */}

        <div className="footer-social ">
          <a
            target="_blank"
            href={
              footerData.instagram ||
              "https://www.facebook.com/darksugarschocolates"
            }
          >
            <img src={insta} alt="insta"></img>
          </a>

          <a
            target="_blank"
            href={
              footerData.facebook ||
              "https://www.instagram.com/darksugars/?hl=en"
            }
          >
            <img src={fb} alt="fb"></img>
          </a>

          <a target="_blank" href={footerData.tiktok}>
            <img src={play} alt="play"></img>
          </a>
        </div>

        <ul className="header-terms mt5">
          <li onClick={() => push("/terms-and-conditions")}>
            {" "}
            Terms & Conditions
          </li>
          <li onClick={() => push("/refund-policy")}>Refund Policy</li>
          <li onClick={() => push("/privacy-policy")}>Privacy Policy</li>
        </ul>
      </div>
    </div>
  );
};
