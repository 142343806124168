import React, { Component } from "react";
import { withRouter } from "react-router";
import Fetch from "../common/Fetch";
import Layout from "../layouts/MainLayout";
import Images from "../common/Images";
import ExpressDileveryMsg from "./common-comps/ExpressDileveryMsg";

const { hotbanner } = Images;

class ClassicBox extends Component {
  state = {
    isLoading: true,
    pageTitle: "",
    subCatID: "",
    productList: [],
    slug: "",
    bannerImage: "",
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    const { params } = this.props.match;
    const pageSlug = params?.id2;

    const pageTitle = params?.id2
      .replaceAll("-", " ")
      ?.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());

    this.setState({ pageTitle, slug: pageSlug });

    Fetch(`prod/allcategories/${pageSlug}/productbyslug`, null, {
      method: "get",
    })
      .then((res) => {
        if (res.success) {
          let categoryObj = res?.data?.find(
            (item) => item.category.bannerimage
          );

          this.setState({
            productList: res.data,
            bannerImage: categoryObj
              ? categoryObj?.category?.bannerimage
              : hotbanner,
            isLoading: false,
          });
        }
      })
      .catch((err) => console.error(err));
  }

  onClickItems = (data) => {
    const { match } = this.props;
    const slug = data.slug ?? data[0]?.slug;
    let p1 = match.params.id;
    let p2 = match.params.id2;
    this.props.history.push(`/shop/${p1}/${p2}/${slug}`);
  };

  render() {
    const { isLoading, productList, pageTitle, bannerImage } = this.state;
    // console.log({bannerImage});

    return (
      <Layout
        title={`${pageTitle?.replaceAll("-", " ")}|Dark Sugar`}
        loading={isLoading}
      >
        <React.Fragment>
          <section style={{ background: `url(${bannerImage}) no-repeat`}} className={`shop-banner personalised`}>
            <h2>{pageTitle}</h2>
          </section>

          <section className="container">
            <ExpressDileveryMsg />
          </section>

          <section className="container shop-section classic-box">
            <div className=" flex flex-wrap personalised-section-change">
              {productList?.length
                ? productList.map((d, i) => (
                    <div
                      key={i}
                      className="personalised-section-inner"
                      onClick={() => this.onClickItems(d)}
                    >
                      <img
                        src={d.photo ?? d[0].photo}
                        alt={d.name ?? d[0].name}
                      ></img>
                      <div>
                        <h3> {d.name ?? d[0].name}</h3>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </section>
        </React.Fragment>
      </Layout>
    );
  }
}

export default withRouter(ClassicBox);
