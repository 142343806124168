import React from "react";
import icons from "../common/Images";

const { not } = icons;

export default function Tooltip({ text }) {
  return (
    <div className="not-tooltip">
      <img src={not}></img>
      <span className="not-tooltip-text">
        <div className="not-tooltip-border">
          <div className="mb1 mt1">
            {/* <h4>Lorem Ipsum</h4> */}
            <p dangerouslySetInnerHTML={{ __html: text }}></p>
          </div>
        </div>
      </span>
    </div>
  );
}
