import React, { useState, useEffect } from "react";
import { pageRoutes } from "./common/Routes";

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import icons from "./common/Images";
import "./styles/main.scss";
import Error from "./pages/Error";

const { cross } = icons;

function App(props) {
  const [showToast, toggleToast] = useState({ show: 0, msg: "" });

  useEffect(() => {
    window.handleToastMsg = handleToastMsg;
  }, []);

  const handleToastMsg = (show, msg, time = 3000) => {
    toggleToast({ show, msg });

    setTimeout(() => {
      toggleToast({ show: false, msg: "" });
    }, time);
  };

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {pageRoutes.map((d, i) => (
              <Route path={d.path} exact={d.exact} key={i}>
                {d.comp}
              </Route>
          ))}
          <Route component={Error} />
        </Switch>
      </Router>

      <Snackbar
        show={showToast?.show}
        msg={showToast?.msg}
        handleToastMsg={handleToastMsg}
      />
    </React.Fragment>
  );
}

export default App;

export const Snackbar = ({ show, msg, handleToastMsg }) => {
  if (!show) return null;
  return (
    <div id="snackbar" className="snack-bg show">
      {msg}
      <img onClick={() => handleToastMsg(false, "")} src={cross} alt="" />
    </div>
  );
};
