import React from "react";
import Modal from "../Modal";

export default function PriceAddWarning({ show, onClose }) {
  if (!show) return null;

  return (
    <Modal onClose={onClose}>
      <p>
        {" "}
        &#163; 1.5 will be charged per Gift Wrap and Personalised Message.
        Amount will be added to the total amount on Cart and Checkout page.
      </p>

      <div className="center"> <button onClick={onClose}>I am okay with it</button></div>
    </Modal>
  );
}
