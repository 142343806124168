import React, { Component, useEffect, useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";

import { download_file } from "../common/utils";

import Layout from "../layouts/MainLayout";
import icons from "../common/Images";
import Fetch from "../common/Fetch";
import Link from "../common/Link";

import { withRouter } from "react-router";
import { withContext } from "../context/Context";

const left_side_headings = [
  {
    label: "Orders",
    img: icons.orders,
    img_a: "",
    func: "",
    slug: "",
  },

  {
    label: "Your Account",
    img: icons.account,
    img_a: "",
    func: "",
    slug: "",
    // func: Auth.logoutUser;
  },

  {
    label: "Address",
    img: icons.address,
    img_a: "",
    func: "",
    slug: "",
  },

  {
    label: "Payment Method",
    img: icons.payment,
    img_a: "",
    func: "",
    slug: "",
  },

  {
    label: "Bookmark",
    img: icons.bookmark,
    img_a: "",
    func: "",
    slug: "",
  },

  {
    label: "Reset Password",
    img: icons.bookmark,
    img_a: "",
    func: "",
    slug: "",
  },

  {
    label: "Logout",
    img: icons.logout,
    img_a: "",
    func: "",
    slug: "",
  },
];

// const countries = getCountries();

const order_states = {
  0: "Order created",
  1: "processing",
  2: "shipped",
  3: "delivered",
  4: "returned",
};

class Account extends Component {
  state = {
    isLoading: true,
    avatar: "users/default.png",

    loadingState: {
      orders: true,
      account: true,
      address: true,
      payment: true,
    },

    formInfo: {
      bcity: null,
      bcountry: "UK",
      bemail: null,
      bfirst_name: null,
      blast_name: null,
      bphone: null,
      bpostcode: null,
      bstate: null,
      bstreet_1: null,
      bstreet_2: null,
      city: null,
      country: "UK",
      created_at: "",
      dob: "",
      email: "",
      email_verified_at: "",
      first_name: "",
      is_active: 0,
      last_name: "",
      name: "",
      phone: "",
      postcode: null,
      role_id: "",
      settings: [],
      state: null,
      street_1: null,
      street_2: null,
      updated_at: "",
      username: "",
    },
    countriesList: [],
    statesList: [],
    fav: [],
    view: "Orders",
    orderList: [],
    editMode: false,
    billProps: { show: false, data: {} },
  };

  componentDidUpdate() {
    const { pathname } = this.props.location;
    if (pathname) {
      let item = localStorage.getItem("AUTH_TOKEN");
      if (!item) {
        const redirectUrl = pathname;
        this.props.history.push(`/login?r=${redirectUrl}`);
        return;
      }
    }
  }

  componentDidMount() {
    Fetch("auth/my-profile", {}, { sendToken: true }).then((d) => {
      this.setState({ isLoading: false, formInfo: { ...d } });
      this.getAllLocations(d.country, d.state, d.bstate);
    });

    Fetch("prod/favourites", {}, { method: "get", sendToken: true }).then(
      (d) => {
        if (d.success) {
          this.setState({
            fav: d.data,
          });
        }
      }
    );

    this.fetchOrdersAPI();
    //this.getPaymentCardDetails();
  }

  getAllLocations = (
    selectedCountry = "",
    selectedState = "",
    billingState = ""
  ) => {
    Fetch("prod/alllocations", null, { method: "get", token: false }).then(
      (res) => {
        if (res) {
          this.setState({ countriesList: res?.data });
          if (selectedCountry) {
            let selectedCountryObj = res?.data?.find(
              (d) => d.country.name === selectedCountry
            );
            const { country, state } = selectedCountryObj;
            let stateObj;
            let bStateObj;

            if ((selectedState || billingState) && state?.length) {
              stateObj = state.find((dd) => dd.name === selectedState);
              bStateObj = state.find((dd) => dd.name === billingState);
            }
            this.setState({
              formInfo: {
                ...this.state.formInfo,
                state: stateObj?.name || "",
                bstate: bStateObj?.name || "",
                country: country?.name || "",
                bcountry: country?.name || "",
              },
              statesList: state,
            });
          }
        }
      }
    );
  };

  fetchOrdersAPI = () => {
    Fetch("prod/order", null, { method: "get", sendToken: true }).then(
      (res) => {
        this.setState({
          orderList: res.data,
          loadingState: { ...this.state.loadingState, orders: false },
        });
      }
    );
  };

  handleChange = (_evt) => {
    if (!this.state.editMode) return;
    const { name, value, type } = _evt.target;

    if (name === "bcountry") return;

    if (name === "country") {
      const { state } =
        this.state.countriesList.find((d) => d.country.name === value) || {};
      this.setState({
        formInfo: { ...this.state.formInfo, [name]: value, bcountry: value },
        statesList: state || [],
      });
      return;
    }

    this.setState({ formInfo: { ...this.state.formInfo, [name]: value } });
    return;
  };

  updateProfileInfo = () => {
    const { btnLoader, formInfo } = this.state;
    let params = { ...formInfo };
    Fetch("auth/update-profile", params, { sendToken: true }).then((d) => {
      if (d.success) {
        this.setState({ editMode: false });
        window.handleToastMsg(true, d?.message, 3000);
      }
    });
  };

  changeRightView = (view) => {
    this.setState({ view, editMode: false });
    if (view === "Reset Password") {
      this.setState({ editMode: true });
    }
  };

  addCart = (val) => {
    const data = {
      boxes: [
        { box_id: val.boxes[0]?.box[0]?.id, box_count: val.boxes[0]?.count },
      ],
      category_id: val?.category?.id,
      gifts: val?.gifts ? val?.gifts : [],
      items: val?.items
        ? val?.items.map((d) => {
            return { items_id: d.item?.[0]?.id, item_count: d.count };
          })
        : [],
      messages: val?.messages,
      price: val?.price,
      product_id: val?.product?.id,
      size_id: val?.size_id?.id,
      // tags: val?.tags ? val?.tags : [],
    };

    Fetch("prod/cart/add", { ...data }, { sendToken: true }).then((res) => {
      if (res.success) {
        this.removeBookmark(val.id);
        this.props.context.getAllInCart(true);
      }
    });
  };

  removeBookmark = (id) => {
    Fetch(`prod/favourites/${id}`, null, {
      method: "delete",
      sendToken: true,
    }).then((res) => {
      if (res?.success) {
        let favUpdate = this.state?.fav?.filter((item) => {
          return item.id !== id;
        });
        this.setState({
          fav: favUpdate,
        });
      }
    });
  };

  render() {
    const {
      isLoading,
      loadingState,
      view,
      orderList,
      avatar,
      formInfo,
      fav,
      editMode,
      countriesList,
      statesList,
    } = this.state;

    const {
      bcity,
      bcountry,
      bemail,
      bfirst_name,
      blast_name,
      bphone,
      bpostcode,
      bstate,
      bstreet_1,
      bstreet_2,
      city,
      country,
      dob,
      email,
      first_name,
      last_name,
      name,
      phone,
      postcode,
      state,
      street_1,
      street_2,
    } = formInfo;

    return (
      <React.Fragment>
        <Layout loading={isLoading} title="My Account | Dark Sugar">
          <div className="account">
            <div
              className={`account-container ${editMode ? "input-border" : ""}`}
            >
              <div className=" mb2">
                <h2>
                  {" "}
                  {name} · <span>welcome to your dashboard</span>
                </h2>
              </div>
              <hr />

              <div className=" py7 flex flex-wrap">
                <div className="account-left">
                  <ul>
                    {left_side_headings.map((d, i) => (
                      <li
                        key={i}
                        onClick={() => this.changeRightView(d.label)}
                        className="pointer"
                      >
                        <img src={d.img} alt="icons" />
                        {d.label}
                      </li>
                    ))}
                  </ul>
                </div>

                {view === "Orders" ? (
                  <div className="account-right">
                    <h3>Last orders</h3>
                    {loadingState?.orders ? (
                      <div className="mt3 f-14"> Please wait...</div>
                    ) : orderList?.length ? (
                      <div className="account-table">
                        <table className="mt3">
                          <tbody>
                            <tr>
                              <th> order date</th>
                              <th> order number</th>
                              <th> order</th>
                              <th> price</th>
                              <th>state</th>
                              <th>Download bill</th>
                              <th>View Orders</th>
                            </tr>

                            {orderList?.map((d, i) => (
                              <tr key={i}>
                                <td> {d.created_at}</td>
                                <td className="cursor-pointer">{d.order_no}</td>
                                <td>
                                  {d.orders && d.orders?.length ? (
                                    <ul>
                                      {d.orders.map((d, i) =>
                                        d.category &&
                                        d.category.slug ===
                                          "personalised-box" ? (
                                          <>
                                            <li key={i}>
                                              {i + 1}. {d.category.name}
                                            </li>
                                            <ul>
                                              {d.items?.length
                                                ? d.items.map((dd, ii) => (
                                                    <li key={ii}>
                                                      X{dd.count}{" "}
                                                      {dd.item[0]?.name}
                                                    </li>
                                                  ))
                                                : null}
                                            </ul>
                                          </>
                                        ) : (
                                          <li>
                                            {i + 1}. {d?.product?.name ?? ""}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  ) : null}
                                </td>
                                <td>&#163;{d.price}</td>
                                <td>{order_states[d.state]}</td>
                                <td
                                  onClick={() =>
                                    download_file(
                                      d.invoice,
                                      String(d.order_no) + ".pdf"
                                    )
                                  }
                                  className="cursor-pointer"
                                >
                                  PDF
                                </td>
                                <td
                                  onClick={() => {
                                    this.props.history.push(
                                      `/account/orders/${d.id}`
                                    );
                                  }}
                                  className="cursor-pointer"
                                >
                                  view Order
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="account-orders mt2">
                        {" "}
                        <div className="account-orders-inner">
                          No Previous Orders...
                        </div>
                        <Link href="/shop">Order now</Link>
                      </div>
                    )}
                  </div>
                ) : view === "Your Account" ? (
                  <div className="account-right">
                    <h3>
                      YOUR ACCOUNT
                      <button onClick={() => this.setState({ editMode: true })}>
                        Edit
                      </button>
                    </h3>
                    <div className=" mb3 account-table">
                      <div>
                        <div className="mt3 account-right-inner">
                          <div className=" flex mb1">
                            <label> First Name </label>
                            <input
                              type="text"
                              placeholder="Your first name"
                              name="first_name"
                              value={first_name}
                              onChange={this.handleChange}
                              readOnly={editMode ? false : true}
                            />
                          </div>
                          <div className="flex mb1">
                            <label> last name </label>
                            <input
                              type="text"
                              placeholder="your lastname"
                              name="last_name"
                              value={last_name}
                              onChange={this.handleChange}
                              readOnly={editMode ? false : true}
                            />
                          </div>
                          <div className="flex mb1">
                            <label> email</label>
                            <input
                              type="text"
                              placeholder="email@gmail.com"
                              name="email"
                              value={email}
                              onChange={this.handleChange}
                              readOnly={editMode ? false : true}
                            />
                          </div>

                          {/* <div className="flex mb1">
                          <label> PASSWORD </label>
                          <input
                            type="password"
                            placeholder="Your name"
                            onChange={this.handleChange}
                            readOnly={!editMode}
                          />
                        </div> */}

                          <div className="flex mb1">
                            <label>Phone </label>
                            <input
                              type="text"
                              placeholder="00000000000"
                              name="phone"
                              value={phone}
                              onChange={this.handleChange}
                              readOnly={editMode ? false : true}
                            />
                          </div>
                          <div className="flex">
                            <label>birthday </label>
                            <input
                              type="date"
                              placeholder="07/09/2021"
                              name="dob"
                              value={dob}
                              onChange={this.handleChange}
                              readOnly={editMode ? false : true}
                            />
                          </div>
                        </div>

                        <div className="flex mt3 account-news align-center display-none">
                          <input type="checkbox" id="news" />
                          <label htmlFor="news" className="flex">
                            <span></span>I want to receive the newsletter
                          </label>
                        </div>
                      </div>

                      <div className=" btn-abs mt2">
                        <button
                          className="btn btn-save"
                          onClick={this.updateProfileInfo}
                        >
                          save changes
                        </button>
                      </div>
                    </div>
                  </div>
                ) : view === "Address" ? (
                  <div className="account-right">
                    <h3>
                      Address
                      <button onClick={() => this.setState({ editMode: true })}>
                        Edit
                      </button>
                    </h3>
                    <div className=" account-table">
                      <div className="account-address">
                        <div className="mt3 account-right-inner account-right-adress">
                          <div className="mt3 mb3 account-right-head">
                            <div className="py1">Delivery Address</div>
                            <hr></hr>
                          </div>

                          <div className=" flex mb1">
                            <label> postcode </label>

                            <input
                              type="text"
                              placeholder="Your postcode"
                              name="postcode"
                              value={postcode}
                              onChange={this.handleChange}
                              readOnly={editMode ? false : true}
                            />
                          </div>

                          <div className="flex mb1">
                            <label> city </label>

                            <input
                              type="text"
                              placeholder="your city"
                              name="city"
                              value={city}
                              onChange={this.handleChange}
                              readOnly={editMode ? false : true}
                            />
                          </div>

                          <div className="flex mb1">
                            <label> country </label>
                            <select
                              onChange={this.handleChange}
                              name="country"
                              disabled={!editMode}
                            >
                              <option selected={!country} disabled>
                                Select country
                              </option>
                              {countriesList?.length
                                ? countriesList.map((item, index) => (
                                    <option
                                      key={index}
                                      value={item.country.name}
                                      selected={country === item.country.name}
                                    >
                                      {item.country.name}
                                    </option>
                                  ))
                                : null}
                            </select>
                            
                            {/* <input
                              type="text"
                              placeholder="Your country"
                              name="country"
                              value={country}
                              onChange={this.handleChange}
                              readOnly={editMode ? false : true}
                            /> */}
                          </div>

                          {statesList?.length ? (
                            <div className="flex mb1">
                              <label> State </label>
                              <select
                                onChange={this.handleChange}
                                name="state"
                                disabled={!editMode || !country}
                              >
                                <option selected={!state} disabled>
                                  Select state
                                </option>

                                {statesList.map((item, index) => (
                                  <option
                                    key={index}
                                    value={item.name}
                                    selected={state === item.name}
                                  >
                                    {item.name}
                                  </option>
                                ))}
                              </select>

                              {/* <input
                              type="text"
                              placeholder="Your county / State"
                              name="state"
                              value={state}
                              onChange={this.handleChange}
                              readOnly={editMode ? false : true}
                            /> */}
                            </div>
                          ) : null}

                          <div className="flex mb1">
                            <label> street address line 1 </label>
                            <input
                              type="text"
                              placeholder="Your street address line 1"
                              name="street_1"
                              value={street_1}
                              onChange={this.handleChange}
                              readOnly={editMode ? false : true}
                            />
                          </div>
                          <div className="flex">
                            <label>street address line 2 </label>
                            <input
                              type="text"
                              placeholder="Your street address line 2"
                              name="street_2"
                              value={street_2}
                              onChange={this.handleChange}
                              readOnly={editMode ? false : true}
                            />
                          </div>
                        </div>

                        <div className="flex my3 account-news align-center">
                          <input type="checkbox" id="news" />
                          <label htmlFor="news">
                            <span></span>
                            My billing address is the same as my delivery
                            address
                          </label>
                        </div>

                        <div className="mt3 account-right-inner account-right-adress">
                          <div className="mt3 mb3 account-right-head">
                            <div className="py1">Billing addresS</div>
                            <hr></hr>
                          </div>
                          <div className=" flex mb1">
                            <label> postcode </label>
                            <input
                              type="text"
                              placeholder="Your postcode"
                              name="bpostcode"
                              value={bpostcode}
                              onChange={this.handleChange}
                              readOnly={editMode ? false : true}
                            />
                          </div>
                          <div className="flex mb1">
                            <label> city </label>
                            <input
                              type="text"
                              placeholder="your city"
                              name="bcity"
                              value={bcity}
                              onChange={this.handleChange}
                              readOnly={editMode ? false : true}
                            />
                          </div>

                          <div className="flex mb1">
                            <label> country </label>
                            <input
                              type="text"
                              placeholder="Your country"
                              name="bcountry"
                              value={bcountry}
                              onChange={this.handleChange}
                              readOnly={editMode ? false : true}
                              disabled
                            />
                          </div>

                          {statesList?.length ? (
                            <div className="flex mb1">
                              <label> State </label>
                              <select
                                onChange={this.handleChange}
                                name="bstate"
                                disabled={!editMode || !bcountry}
                              >
                                <option selected={!bstate} disabled>
                                  Select state
                                </option>

                                {statesList.map((item, index) => (
                                  <option
                                    key={index}
                                    value={item.name}
                                    selected={bstate === item.name}
                                  >
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          ) : null}

                          {/* <div className="flex mb1">
                            <label> State </label>
                            <input
                              type="text"
                              placeholder="Your county / State"
                              name="bstate"
                              value={bstate}
                              onChange={this.handleChange}
                              readOnly={editMode ? false : true}
                            />
                          </div> */}

                          <div className="flex mb1">
                            <label> street address line 1 </label>
                            <input
                              type="text"
                              placeholder="Your street address line 1"
                              name="bstreet_1"
                              value={bstreet_1}
                              onChange={this.handleChange}
                              readOnly={editMode ? false : true}
                            />
                          </div>
                          <div className="flex">
                            <label>street address line 2 </label>
                            <input
                              type="text"
                              placeholder="Your street address line 2"
                              name="bstreet_2"
                              value={bstreet_2}
                              onChange={this.handleChange}
                              readOnly={editMode ? false : true}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="btn-abs mt2">
                        <button
                          className="btn btn-save"
                          onClick={this.updateProfileInfo}
                        >
                          save changes
                        </button>
                      </div>
                    </div>
                  </div>
                ) : view === "Payment Method" ? (
                  <AddPaymentCardDetails
                    onGetSavedCards={this.getPaymentCardDetails}
                  />
                ) : view === "Bookmark" ? (
                  <div className="account-right">
                    <h3>
                      Your bookmarks
                      {fav.length ? (
                        <button
                          onClick={() => this.setState({ editMode: true })}
                        >
                          Edit
                        </button>
                      ) : null}
                    </h3>

                    {!fav.length ? (
                      <div className="account-orders mt2">
                        {" "}
                        <div className="account-orders-inner">
                          No favourite items...
                        </div>
                        <Link href="/shop">Continue shopping</Link>
                      </div>
                    ) : (
                      <div className="account-table">
                        <table className="mt3 account-bookmark">
                          <tbody>
                            <tr>
                              <th> type</th>
                              <th> product</th>
                              <th> description</th>
                              <th> price</th>
                            </tr>
                            {fav?.map((item, key) => {
                              return (
                                <tr key={key}>
                                  <td>
                                    {item?.parent_category?.name
                                      ? item?.parent_category?.name
                                      : item?.category?.name}
                                  </td>
                                  <td>
                                    <img src={item.product?.photo}></img>
                                  </td>
                                  <td className="account-bookmark-text">
                                    {item?.items?.map((d, key) => {
                                      return (
                                        <span>{`x${d?.count} ${d?.item[0]?.name}`}</span>
                                      );
                                    })}
                                  </td>
                                  <td>
                                    £{item?.price}
                                    <div className="account-btns">
                                      <button
                                        className="btn account-bookmark-btn mr1"
                                        onClick={() =>
                                          this.removeBookmark(item.id)
                                        }
                                      >
                                        Remove
                                      </button>
                                      <button
                                        className="btn account-bookmark-btn"
                                        onClick={() => this.addCart(item)}
                                      >
                                        ADD to BASKET
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                ) : view === "Reset Password" ? (
                  <UpdatePasswordScr
                    handleLoadingState={() => {}}
                    handleEditView={() => {}}
                    logoutUser={this.props.context.logoutUser}
                    redirect={() => this.props.history.push("/login")}
                  />
                ) : view === "Logout" ? (
                  <div className="account-right">
                    <h3>Log out</h3>
                    <div className="account-logout my3">
                      Are you sure you want to log out?
                    </div>
                    <div className="account-logout-text flex align-center">
                      <span
                        className="pointer"
                        onClick={() => this.props.history.push("/shop")}
                      >
                        no, keep the session open
                      </span>
                      <button
                        onClick={() => {
                          this.props.context.logoutUser();
                          this.props.history.push("/");
                        }}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default withContext(withRouter(Account));

export const AddPaymentCardDetails = ({ onGetSavedCards }) => {
  const [loading, setLoading] = useState(true);
  const [savedCards, setSavedCards] = useState([]);
  const [showCardAddField, toggleCardAddField] = useState(true);

  const appearance = {
    theme: "stripe",

    variables: {
      colorPrimary: "#0570de",
      colorBackground: "#ffffff",
      colorText: "#30313d",
      colorDanger: "#df1b41",
      fontFamily: "Ideal Sans, system-ui, sans-serif",
      spacingUnit: "2px",
      borderRadius: "4px",
      // See all possible variables below
    },
  };

  const stripe = useStripe();
  const elements = useElements({ appearance });

  useEffect(() => {
    getSavedCards();
  }, []);

  const getSavedCards = () => {
    setLoading(true);
    handlePaymentAPI("get").then((res) => {
      if (res.status) {
        setLoading(false);
        if (res.success) {
          toggleCardAddField(false);
          setSavedCards(res.data);
        }
      }
    });
  };

  const handlePaymentAPI = (method = "post", payment_method) => {
    const sendParamWithDelete = method === "delete" ? true : false;
    return Fetch(
      "prod/paymentMethods",
      payment_method === undefined ? null : { payment_method },
      { method, sendToken: true, sendParamWithDelete }
    ).then((res) => {
      return res;
    });
  };

  const handlePaymentMethods = async (_case, data = {}) => {
    let payment_method = "";
    let err = "";
    if (_case !== "delete") {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
      });
      payment_method = paymentMethod;
      err = error;
    }
    if (_case === "add" && err) return;
    setLoading(true);
    const apiMethod = _case === "add" ? "post" : _case;
    payment_method = data.id ? data.id : payment_method.id;

    const successToastMessage =
      _case === "delete"
        ? "Card deleted successfully"
        : _case === "add"
        ? "Card added successfully"
        : "";
    handlePaymentAPI(apiMethod, payment_method).then((res) => {
      if (res && res.success) {
        window.handleToastMsg(true, res.message || successToastMessage);
        getSavedCards();
        return;
      }

      setLoading(false);
      window.handleToastMsg(true, res.message || "Something went wrong");
    });
  };

  const setDefaultPaymentMethod = (data) => {
    setLoading(true);
    Fetch(
      "prod/defaultPaymentMethods",
      { payment_method: data.id },
      { sendToken: true }
    ).then((res) => {
      if (res && res.success) {
        window.handleToastMsg(
          true,
          res.message || "Payment method set to default"
        );
        getSavedCards();
        return;
      }
      setLoading(false);
      window.handleToastMsg(
        true,
        res.message || "Can't process request, pleasw try after some time"
      );
    });
  };

  return (
    <div className="account-right">
      <h3>Payment Method</h3>

      {loading ? (
        <div className="mt3 f-14"> Please wait...</div>
      ) : (
        <div className="account-table">
          {savedCards?.length && !showCardAddField ? (
            <button
              className="btn-save mt2"
              onClick={() => toggleCardAddField(true)}
            >
              + Add new card
            </button>
          ) : null}

          {!savedCards?.length || showCardAddField ? (
            <React.Fragment>
              <div className="mt1 account-right-inner">
                <div className="account-payment-right">
                  <div className="flex mb1">
                    <label>
                      {" "}
                      card number
                      <div className="account-payment-input">
                        <CardNumberElement />
                      </div>
                    </label>
                  </div>

                  <div className="flex flex-wrap row account-payment-row">
                    <div className="col-xl-6 col-lg-6 col-sm-12">
                      <label>
                        {" "}
                        exp date
                        <div className="account-payment-input">
                          {" "}
                          <CardExpiryElement />
                        </div>
                      </label>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-sm-12">
                      <label>
                        cvc/cvv
                        <div className="account-payment-input">
                          {" "}
                          <CardCvcElement />
                        </div>
                      </label>
                    </div>
                  </div>

                  <div className="flex mt3 account-news align-center">
                    <input type="checkbox" id="news" />
                    <label htmlFor="news">
                      <span></span>
                      remember payment method
                    </label>
                  </div>
                </div>
              </div>

              <div className="mt2 btn-abs">
                <button
                  className="btn btn-save mr3"
                  style={{ marginRight: "3rem" }}
                  onClick={() => handlePaymentMethods("add")}
                >
                  save changes
                </button>

                {savedCards?.length ? (
                  <button
                    className="btn btn-save"
                    onClick={() => toggleCardAddField(false)}
                  >
                    Cancel
                  </button>
                ) : null}
              </div>
            </React.Fragment>
          ) : (
            <div>
              <ul>
                {savedCards.map((d, i) => (
                  <li>
                    <div className=" account-right-inner mt2">
                      <div className=" mb1 account-payment-right">
                        <label>
                          {" "}
                          <div className="flex justify-space align-center">
                            <div className="flex">
                              {d.card.brand}

                              <div className="" style={{ marginLeft: "1rem" }}>
                                {d.default ? (
                                  <button className="btn" type="button">
                                    {" "}
                                    Default{" "}
                                  </button>
                                ) : (
                                  <button
                                    className="btn"
                                    onClick={() => setDefaultPaymentMethod(d)}
                                  >
                                    Make default
                                  </button>
                                )}
                              </div>
                            </div>

                            {!d.default ? (
                              <button
                                className="btn btn-save"
                                onClick={() =>
                                  handlePaymentMethods("delete", d)
                                }
                              >
                                Delete
                              </button>
                            ) : null}
                          </div>
                          <div className="account-card">
                            {"**** **** **** " + d.card.last4}
                          </div>
                        </label>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export const UpdatePasswordScr = ({ redirect, logoutUser }) => {
  // const [email, setEmail] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [alert, setAlert] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);

  const handleChange = (_evt) => {
    const { name, value } = _evt.target;
    setError("");
    if (name === "oldPassword") {
      setOldPassword(value);
      setAlert({ ...alert, oldPassword: "" });
      return;
    }
    if (name === "confirmPassword") {
      setConfirmPassword(value);
      setAlert({ ...alert, confirmPassword: "" });
      return;
    }

    if (name === "password") {
      setPassword(value);
      setAlert({ ...alert, password: "" });
      return;
    }
  };

  const onChangePassword = () => {
    let copyAlert = { ...alert };
    for (let key in alert) {
      if (alert[key].trim().length) {
        return;
      }
    }

    if (
      !password.trim().length ||
      !oldPassword?.trim().length ||
      !confirmPassword?.trim().length
    ) {
      let e1 = !password.trim().length;
      let e2 = !oldPassword?.trim().length;
      let e3 = !confirmPassword?.trim().length;
      if (e1) {
        copyAlert["password"] = "Required";
      }
      if (e2) {
        copyAlert["oldPassword"] = "Required";
      }
      if (e3) {
        copyAlert["confirmPassword"] = "Required";
      }

      setAlert(copyAlert);
      return;
    }
    setLoading(true);

    Fetch(
      "auth/change-password",
      {
        old_password: oldPassword,
        new_password: password,
        confirm_password: confirmPassword,
      },
      { sendToken: true }
    ).then((res) => {
      setLoading(false);
      setPassword("");
      setConfirmPassword("");
      setOldPassword("");
      if (res?.success) {
        window.handleToastMsg(
          true,
          res.message ||
            "Password changed successfully, please login again to continue..."
        );

        setTimeout(() => {
          redirect();
          logoutUser();
        }, 3000);
        return;
      }

      window.handleToastMsg(
        true,
        res.message || "Something went wrong, please try after some time.."
      );
      return;
    });
  };

  return (
    <div className="account-right">
      <h3>
        Please Enter details
        {/* <button onClick={() => this.setState({ editMode: true })}>Edit</button> */}
      </h3>

      {loading ? (
        <div className="mt3 f-14"> Please wait...</div>
      ) : (
        <div className=" mb3 account-table">
          <div>
            <div className="mt3 account-right-inner">
              <div className="flex mb1">
                <label> Old Password </label>
                <input
                  type="password"
                  placeholder="Enter current password"
                  name="oldPassword"
                  value={oldPassword}
                  onChange={handleChange}
                />
              </div>

              <div className="flex mb1">
                <label> New Password </label>
                <input
                  type="password"
                  placeholder="Enter New password"
                  name="password"
                  value={password}
                  onChange={handleChange}
                />
              </div>

              <div className="flex mb1">
                <label> Confirm Password </label>
                <input
                  type="text"
                  placeholder="Confirm password"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div className=" btn-abs mt2">
            <button className="btn btn-save" onClick={onChangePassword}>
              Continue
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
