import Fetch from "./Fetch";

export function updateCartItemAPICount(id = "", option = "", prodID=null) {
  return Fetch(
    `prod/cart/${id}`,
    {option, product_id: prodID},
    { method: "put", sendToken: true }
  )
    .then((res) => {
      return res;
    })
    .catch((err) => console.error(err));
}

// export function deleteCartItemAPICount(id = "") {
//   return Fetch(`prod/cart/${id}`, {}, { method: "delete", sendToken: true })
//     .then((res) => {
//       return res;
//     })
//     .catch((err) => console.error(err));
// }

export function removeItemFromCartAPI(id = "", prodID) {
  return Fetch(`prod/cart/${id}`, null, { method: "delete", sendToken: true })
    .then((res) => {
      return res;
    })
    .catch((err) => console.error(err));
}

export function getAllDiscountCodes() {
  return Fetch(`prod/alldiscounts`, null, { method: "get", sendToken: false })
    .then((res) => {
      if (res?.success) {
        return { discountList: res.data, status: true };
      }
      return { discountList: [], status: false };
    })
    .catch((err) => {
      console.error(err);
      return { discountList: [], status: false };
    });
}
