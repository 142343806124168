function importAll(r) {
    let imgObj = {}
    let a = r.keys().map(r);

    a.forEach(d => {
        const key = d.split("/")[3]?.split(".")[0];
        imgObj[key] = d;
    })
    return imgObj
}

const images = importAll(require.context('../assets', true, /\.(png|jpe?g|svg)$/));


export default images;
